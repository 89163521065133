import React, { useEffect, useMemo, useRef, useState } from "react";
import { TimelineLeftHeaderProps } from "./components/TimelineLeftHeader";
import { TimelineBody } from "./components/TimelineBody";
import { COLUMN_WIDTH, HEADER_HEIGHT, ROW_HEIGHT } from "./utils/TimelineUtils";
import { isBefore, isSameDay } from "date-fns";
import { ViewMode } from "../Schedule";
import { GridProps } from "./components/Grid";
import { TimelineHeaderProps } from "./components/TimelineHeader";
import { TimelineDates, TimelineElement } from "../../../api/models/GeneralTypes";
import { TestIds } from "../../../TestIds";
import { LegendRow, TimelineLegend } from "./components/TimelineLegend";
import useWindowDimensions from "../../../utils/WindowUtils";
import { DowntimeItem } from "../../../api/models/Downtime";

export interface TimelineProps {
    elements: TimelineElement[];
    viewDate: Date;
    viewMode: ViewMode;
    setViewMode: Function;
    leftHeaderProps: TimelineLeftHeaderProps;
    legendRows: LegendRow[];
    timelineDates: TimelineDates;
    downtimeItems: DowntimeItem[];
}

export function Timeline({
    viewDate,
    leftHeaderProps,
    legendRows,
    elements,
    timelineDates,
    viewMode,
    setViewMode,
    downtimeItems,
}: TimelineProps): JSX.Element {
    const scrollRef = useRef<HTMLDivElement>(null);

    const [currentViewDate, setCurrentViewDate] = useState<Date>(viewDate);
    const [scrollToToday, setScrollToToday] = useState<boolean>(false);

    const headerHeight: number = leftHeaderProps.rows.length * ROW_HEIGHT;
    const timelineHeight = useWindowDimensions().height - 350;
    const timelineFullHeight: number = headerHeight > timelineHeight ? headerHeight : timelineHeight;

    const bodyWidth: number = useMemo(() => {
        return timelineDates.dates.length * COLUMN_WIDTH;
    }, [timelineDates.dates]);

    const gridProps: GridProps = useMemo(() => {
        return {
            bodyWidth,
            rowCount: leftHeaderProps.rows.length,
            timelineDates,
            timelineFullHeight,
            downtimeItems,
        };
    }, [bodyWidth, timelineDates, timelineFullHeight, leftHeaderProps.rows, downtimeItems]);

    const timelineHeaderProps: TimelineHeaderProps = {
        timelineDates,
        viewMode,
        setViewMode,
        setCurrentViewDate,
        downtimeItems,
    };

    useEffect(() => {
        setCurrentViewDate(viewDate);
    }, [viewDate]);

    const scrollPositionOfToday = useMemo(() => {
        if (!scrollRef.current) return;
        let index = findIndex(timelineDates.dates);

        if (viewMode === ViewMode.Day && !isSameDay(currentViewDate, new Date())) index = index + 13;

        return COLUMN_WIDTH * (index + 2.5) - scrollRef.current.offsetWidth / 2;
        // eslint-disable-next-line
    }, [viewMode, currentViewDate]);

    useEffect(() => {
        if (viewMode !== timelineDates.viewMode) return;
        if (!scrollRef.current) return;

        setScrollToToday(true);
    }, [timelineDates.viewMode, currentViewDate, viewMode, setScrollToToday]);

    useEffect(() => {
        if (scrollToToday) setScrollToToday(false);
    }, [scrollToToday, setScrollToToday]);

    function findIndex(dates: Date[]) {
        return dates.findIndex((date, i) => {
            const isDateAfterCurrent = currentViewDate.valueOf() >= date.valueOf();
            const isLastDate = i + 1 === dates.length;
            const isNextDateBeforeCurrent = isBefore(currentViewDate, dates[i + 2]);

            return isDateAfterCurrent && !isLastDate && isNextDateBeforeCurrent;
        });
    }

    return (
        <div className="relative flex select-none focus:outline-none" data-testid={TestIds.TIMELINE} ref={scrollRef}>
            <TimelineBody
                leftHeaderProps={leftHeaderProps}
                gridProps={gridProps}
                timelineHeaderProps={timelineHeaderProps}
                items={elements}
                scrollX={scrollPositionOfToday}
                scrollToToday={scrollToToday}
                timelineHeight={timelineHeight}
            />
            <div className="border-r-2" style={{ marginTop: HEADER_HEIGHT - 4, height: timelineHeight + 7 }} />
            <TimelineLegend rows={legendRows} timelineHeight={timelineHeight} />
        </div>
    );
}
