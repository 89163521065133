import axios from "axios";
import { BASE_URL, LOGIN_ENDPOINT } from "../ApiConfig";
import { LoginData } from "../../pages/login/Login";
import { trackPromise } from "react-promise-tracker";

const endpoint = BASE_URL + LOGIN_ENDPOINT;

export default async function loginPost(loginData: LoginData) {
    return trackPromise(axios.post(endpoint, loginData));
}
