import React, { useCallback, useEffect, useState } from "react";
import { useProduction } from "../../../context/ProductionContext";
import {
    createFlowForProductModeling,
    createNodesAndEdgesForProduct,
    getAllPreProducts,
} from "../../../utils/flow/ProductFlowUtils";
import Tabs from "../../../components/Tabs";
import ProductNode from "../../../components/flow/ProductNode";
import ExtMaterialNode from "../../../components/flow/ExtMaterialNode";
import { useProducts, useSelectedProduct, useUpdateProduct } from "../../../context/ProductContext";
import { createEmptyProduct, getPreProductsDeepSearch, Product } from "../../../api/models/Product";
import { ProductType } from "../../../api/models/ProductGroup";
import { createEmptyExtMaterial } from "../../../api/models/ExternalMaterial";
import ProductForm from "../../../components/forms/ProductForm";
import { useText } from "../../../context/LanguageContext";
import TextIds from "../../../language/TextIds";

export interface BillOfMaterialModelingProps {
    selectableProducts: Product[];
    tabColor: string;
}

export default function BillOfMaterialModeling({ selectableProducts, tabColor }: BillOfMaterialModelingProps) {
    const getText = useText();
    const production = useProduction();
    const preProducts = useProducts().preProducts;

    const [flow, setFlow] = useState<JSX.Element>(<></>);
    const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

    const selectedProduct = useSelectedProduct();
    const setSelectedProduct = useUpdateProduct();
    const [selectablePreProducts, setSelectablePreProducts] = useState<Product[]>([]);
    const [selectedPreProduct, setSelectedPreProduct] = useState<Product>(selectedProduct);

    const [formProduct, setFormProduct] = useState<Product>(createEmptyProduct());
    const [isProductFormOpen, setIsProductFormOpen] = useState<boolean>(false);

    const isSelectedProductInSelectableProducts = selectableProducts.some(
        (product) => product.id === selectedProduct?.id
    );

    const getFlowOption = useCallback(
        (flowElement: Product) => {
            return {
                production,
                element: flowElement,
                selectableElements: selectablePreProducts,
                defaultSelectedElement: selectedPreProduct,
                setSelectedElement: (product: Product) => setSelectedPreProduct(product),
                createNodesAndEdges: createNodesAndEdgesForProduct,
                getAll: getAllPreProducts,
                isFullscreen,
                setIsFullscreen,
            };
        },
        [production, isFullscreen, selectablePreProducts, selectedPreProduct]
    );
    useEffect(() => {
        if (selectableProducts.length === 0) {
            setFlow(<div className="p-4">{getText(TextIds.Product.NONE)}</div>);
            setSelectedProduct(createEmptyProduct());
            return;
        }
        if (!isSelectedProductInSelectableProducts) setSelectedProduct(selectableProducts[0]);
    }, [selectableProducts, isSelectedProductInSelectableProducts, setSelectedProduct, getText]);

    useEffect(() => {
        let product = getPreProductsDeepSearch(selectedProduct, preProducts).find(
            (product: Product) => product.id === selectedPreProduct.id
        );

        if (!product) {
            product = { ...selectedProduct, id: 0, name: getText(TextIds.Form.NO_SELECTION) };

            let products: Product[] = [product];
            products.push(...getPreProductsDeepSearch(product, preProducts));
            setSelectablePreProducts(products);
        }
        setSelectedPreProduct(product);
        // eslint-disable-next-line
    }, [selectedProduct, selectedPreProduct.id, preProducts]);

    useEffect(() => {
        if (selectedProduct.id === 0 && selectedPreProduct.id === 0) {
            setFlow(<div className="p-4">{getText(TextIds.Product.NONE)}</div>);
            return;
        }

        let flowElement = selectedPreProduct;
        if (selectedPreProduct.id === 0) flowElement = selectedProduct;
        setFlow(createFlowForProductModeling(getFlowOption(flowElement)));
    }, [selectedPreProduct, getFlowOption, selectedProduct, getText]);

    function getAddProductButton(name: string, type: ProductType): JSX.Element {
        return <ProductNode product={{ ...createEmptyProduct(), name: name }} productType={type} addButton={true} />;
    }

    function getAddExtMaterialButton(name: string): JSX.Element {
        return <ExtMaterialNode extMaterial={{ ...createEmptyExtMaterial(), name: name }} addButton={true} />;
    }

    function openEditProductForm(product: Product) {
        setFormProduct(product);
        setIsProductFormOpen(true);
    }

    return (
        <div className="flex w-full space-x-8">
            <div className="w-full grow">
                <Tabs
                    elements={selectableProducts}
                    setSelectedElement={setSelectedProduct}
                    defaultElement={selectedProduct}
                    selectedTabColor={tabColor}
                    onDoubleClick={(product: Product) => openEditProductForm(product)}
                >
                    {flow}
                </Tabs>
            </div>
            <div className="mt-10 flex-initial space-y-2">
                {getAddProductButton(getText(TextIds.Product.END), ProductType.END)}
                {getAddProductButton(getText(TextIds.Product.PRE), ProductType.PRE)}
                {getAddExtMaterialButton(getText(TextIds.ExternalMaterial.NAME))}
            </div>
            <ProductForm selectedProduct={formProduct} setOpen={setIsProductFormOpen} open={isProductFormOpen} />
        </div>
    );
}
