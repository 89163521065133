export class TestIds {
    static readonly BODY = "body";
    static readonly NAVIGATION = "navigation";
    static readonly NAVIGATION_ITEM = "navigation-item-";
    static readonly NAVIGATION_PRODUCTION = "navigation-item-1";
    static readonly NAVIGATION_PROCESS = "navigation-item-2";
    static readonly NAVIGATION_MATERIAL = "navigation-item-3";
    static readonly NAVIGATION_ORDER = "navigation-item-4";
    static readonly NAVIGATION_SCHEDULE = "navigation-item-5";
    static readonly PATH_PRODUCTION = "productions";
    static readonly PATH_PROCESS = "process";
    static readonly PATH_MATERIAL = "material";
    static readonly PATH_ORDER = "orders";
    static readonly PATH_SCHEDULE = "schedule";
    static readonly MODAL = "modal";

    static readonly LOGOUT_BUTTON = "logout-button";
    static readonly INPUT_USERNAME = "input-username";
    static readonly INPUT_PASSWORD = "input-password";

    static readonly ADD_PRODUCTION_BUTTON = "add-production-button";
    static readonly PRODUCTION = "production-";
    static readonly ADD_LINE_BUTTON = "add-line-button";
    static readonly ADD_STATION_BUTTON = "add-station-button";
    static readonly ADD_OPERATION_BUTTON = "add-operation-button";
    static readonly ADD_DOWNTIME_BUTTON = "add-downtime-button";
    static readonly ADD_CUSTOMER_BUTTON = "add-customer-button";
    static readonly ADD_CUSTOMER_ORDER_BUTTON = "add-customer-order-button";
    static readonly ADD_EXT_MATERIAL_GROUP_BUTTON = "add-ext-material-group-button";
    static readonly ADD_PRE_PRODUCT_GROUP_BUTTON = "add-pre-product-group-button";
    static readonly ADD_END_PRODUCT_GROUP_BUTTON = "add-end-product-group-button";
    static readonly ADD_EXT_MATERIAL_BUTTON = "add-ext-material-button";
    static readonly ADD_END_PRODUCT_BUTTON = "add-end-product-button";
    static readonly ADD_PRE_PRODUCT_BUTTON = "add-pre-product-button";

    static readonly SUBMIT_BUTTON = "submit-button";
    static readonly CANCEL_BUTTON = "cancel-button";
    static readonly CLOSE_BUTTON = "close-button";
    static readonly DIV_BUTTON = "div-button";
    static readonly DELETE_BUTTON = "delete-button";
    static readonly EDIT_BUTTON = "edit-button";
    static readonly MULTISELECT_BUTTON = "multiselect-button";
    static readonly SELECT_BUTTON = "select-button";

    static readonly TABS = "tabs";
    static readonly TAB = "tab-";

    static readonly FLOW = "flow";
    static readonly FORM_FOOTER = "form-footer";
    static readonly CONFIRM_FOOTER = "confirm-footer";

    static readonly INPUT_NAME = "input-name";
    static readonly INPUT_INTERNAL = "input-internal";
    static readonly INPUT_START = "input-daily-start";
    static readonly INPUT_END = "input-daily-end";
    static readonly INPUT_DELIVERY_DATE = "input-delivery-date";
    static readonly INPUT_START_DATE = "input-start-date";
    static readonly INPUT_INITIAL_DATE = "input-initial-date";
    static readonly INPUT_INITIAL_TIME = "input-initial-time";
    static readonly INPUT_DATE = "input-date";
    static readonly INPUT_END_DATE = "input-end-date";
    static readonly INPUT_DESCRIPTION = "input-description";
    static readonly INPUT_DURATION = "input-duration";
    static readonly INPUT_PLUS_BUTTON = "input-plus-button";
    static readonly INPUT_MINUS_BUTTON = "input-minus-button";
    static readonly INPUT_QUANTITY = "input-quantity";
    static readonly INPUT_WORKDAY = "input-workday-";
    static readonly CHECKBOX = "checkbox";
    static readonly ALL_DAY_CHECKBOX = "all-day-checkbox";
    static readonly CHECKBOX_CHECKED = "checkbox-checked";

    static readonly EXT_MATERIAL_COUNTER_BOX = "ext-material-counter-box";
    static readonly PRODUCT_COUNTER_BOX = "product-counter-box";
    static readonly LINE_COUNTER_BOX = "line-counter-box";
    static readonly FREEZE_TIME_COUNTER = "freeze-time-counter-box";
    static readonly HORIZON_COUNTER = "horizon-counter-box";
    static readonly STATION_SELECT = "station-select";
    static readonly LINE_SELECT = "line-select";
    static readonly PRODUCT_GROUP_SELECT = "product-group-select";
    static readonly EXT_MATERIAL_GROUP_SELECT = "ext-material-group-select";
    static readonly PRODUCT_SELECT = "product-select";
    static readonly EXT_MATERIAL_SELECT = "ext-material-select";
    static readonly CUSTOMER_SELECT = "customer-select";
    static readonly OPERATION_SELECT = "operation-select";
    static readonly OPTION = "option-";
    static readonly PRE_END_SWITCH = "pre-end-switch";
    static readonly PRODUCT_MODELING_SWITCH = "product-modeling-switch";
    static readonly RADIO_BUTTON_GROUP = "radio-button-group";
    static readonly MULTISELECT_OPTIONS = "multiselect-options";
    static readonly SELECT_OPTIONS = "select-options";

    static readonly STATION_NODE = "station-node-";
    static readonly PRODUCT_NODE = "product-node-";
    static readonly PRODUCT_GROUP_NODE = "product-group-node-";
    static readonly EXT_MATERIAL_NODE = "ext-material-node-";
    static readonly EXT_MATERIAL_GROUP_NODE = "ext-material-group-node-";
    static readonly CUSTOMER_ORDER_TABLE = "customer-order-table";
    static readonly CUSTOMER_ORDER_DETAIL_TABLE = "customer-detail-table";
    static readonly CUSTOMER_ORDER_ROW = "customer-order-row-";
    static readonly PRODUCTION_ORDER_ROW = "production-order-row-";
    static readonly PRODUCTION_ORDER_TABLE = "production-order-table";

    static readonly SCHEDULE = "schedule";
    static readonly SCHEDULE_HEADER = "schedule-header";
    static readonly SCHEDULE_CALCULATION_BUTTON = "schedule-calculation-button";
    static readonly TIMELINE = "timeline";
    static readonly TIMELINE_HEADER = "timeline-header";
    static readonly TIMELINE_CONTENT = "timeline-content";
    static readonly TIMELINE_LEFT_HEADER = "timeline-left-header";
    static readonly TIMELINE_VIEW_MODE_SWITCH = "timeline-view-mode-switch";
    static readonly TIMELINE_SCHEDULE_MODE_SWITCH = "timeline-schedule-mode-switch";
    static readonly TIMELINE_LEGEND = "timeline-legend";
    static readonly TIMELINE_ITEM = "timeline-item-";
    static readonly DOWNTIME_ITEM = "downtime-item-";

    static readonly POPUP = "popup";
    static readonly OPEN_POPUP_BUTTON = "open-popup-button";
    static readonly OPERATION_FORM = "operation-form";
    static readonly WORKDAY_FORM = "workday-form";
    static readonly DOWNTIME_FORM = "downtime-form";
    static readonly RESCHEDULE_FORM_WARNING = "workday-form-warning";
    static readonly OPERATION_ENTRY = "operation-entry-";
    static readonly DOWNTIME_ENTRY = "downtime-entry-";
    static readonly LOADER = "loader";
}
