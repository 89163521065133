import React, { useEffect, useState } from "react";
import Label from "../Label";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import Input from "./Input";
import { numberPattern } from "../../utils/FormUtils";
import { TestIds } from "../../TestIds";
import { useText } from "../../context/LanguageContext";
import TextIds from "../../language/TextIds";

interface MaterialCounterProps {
    labelText?: string;
    initialCounter: number;
    setAmount: Function;
    id?: number;
    required?: boolean;

    [optionalProperties: string]: any;
}

export default function CounterBox({
    labelText,
    initialCounter,
    setAmount,
    id,
    required = false,
    ...optionalProperties
}: MaterialCounterProps) {
    const getText = useText();
    let [counter, setCounter] = useState<number>(initialCounter);

    useEffect(() => {
        setCounter(initialCounter);
    }, [initialCounter]);

    function countUp() {
        let i = counter + 1;
        setInput(i);
    }

    function countDown() {
        const minAmount = required ? 1 : 0;
        let i = counter - 1;
        if (i >= minAmount) {
            setInput(i);
        }
    }

    function setInput(amount: number) {
        setCounter(amount);
        setAmount(amount);
    }

    const className = "flex items-center justify-center cursor-pointer h-5 w-5 text-gray-middle";
    return (
        <div className="w-20 select-none" data-testid={id} {...optionalProperties}>
            <Label className="text-xs">{labelText}</Label>
            <div className="flex items-center">
                <Input
                    name="counter"
                    value={counter}
                    type="text"
                    pattern={numberPattern.source}
                    title={getText(TextIds.Form.COUNTER_ERROR)}
                    maxLength={3}
                    onChange={(event: any) => setInput(+event.target.value)}
                    className="text-center"
                    data-testid={TestIds.INPUT_QUANTITY}
                />
                <div>
                    <div className={className} onClick={countUp} data-testid={TestIds.INPUT_PLUS_BUTTON}>
                        <PlusCircleIcon />
                    </div>
                    <div className={className} onClick={countDown} data-testid={TestIds.INPUT_MINUS_BUTTON}>
                        <MinusCircleIcon />
                    </div>
                </div>
            </div>
        </div>
    );
}
