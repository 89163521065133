import { toast } from "react-toastify";
import { AxiosResponse } from "axios";
import TextIds from "../language/TextIds";
import { trackPromise } from "react-promise-tracker";

export function handleApiCall(apiCall: Promise<AxiosResponse<any>>, messages: string[]): Promise<any> {
    return trackPromise(
        apiCall
            .then((response) => {
                if (messages[0] !== "") toast.success(messages[0]);

                return response.data;
            })
            .catch((error) => {
                if (error.response === undefined) {
                    toast.error(messages[2]);
                    return [];
                }

                toast.error(`${messages[1]}\n ${error.response.data.message}`);
            })
    );
}

export function handleApiGetCall(
    apiCall: Promise<AxiosResponse<any>>,
    elementType: string,
    id: number | string,
    getText: Function
): Promise<any> {
    return trackPromise(
        apiCall
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response === undefined) {
                    toast.error(getText(TextIds.NO_RESPONSE));
                    return;
                }
                if (error.response.status === 401) {
                    localStorage.removeItem("jwt_token");
                    window.location.reload();
                    return;
                }
                const textId = `fetch${elementType}Failed`;
                const message = `${getText(textId)} ${id}!`;
                toast.error(`${message}\n ${error.response.data.message}`);
            })
    );
}

export function generateToastText(componentName: string, method: string | undefined, getText: Function): string[] {
    let texts = ["", ""];
    switch (method?.toUpperCase()) {
        case "POST":
            texts = ["created", "creation"];
            break;
        case "PUT":
            texts = ["updated", "update"];
            break;
        case "DELETE":
            texts = ["deleted", "deletion"];
            break;
    }
    let success = getText(`${texts[0]}${componentName}`);
    let failed = getText(`${texts[1]}${componentName}Failed`);
    return [success, failed, getText(TextIds.NO_RESPONSE)];
}
