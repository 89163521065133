export const GRAY_VERY_LIGHT = "#ececec";
export const GRAY_LIGHT = "#a6a6a6";
export const GRAY_MIDDLE = "#595959";
export const GRAY_DARK = "#333333";

export const GREEN_DARK = "#003300";
export const GREEN_MIDDLE = "#008000";
export const GREEN_STRONG_MIDDLE = "#1bad00";
export const GREEN_LIGHT = "#62cc46";
export const GREEN_HOVER = "#004000";
export const GREEN_FOCUS = "#99cc99";

export const SCHEDULE_GRAY = "#333333";
export const SCHEDULE_GREEN = "#5d9c4c";
export const SCHEDULE_BLUE_DARK = "#5085a9";
export const SCHEDULE_BLUE_LIGHT = "#5bc8b0";
export const SCHEDULE_PURPLE = "#786176";
export const SCHEDULE_ORANGE = "#d49056";
export const SCHEDULE_COLORS = [
    SCHEDULE_GRAY,
    SCHEDULE_GREEN,
    SCHEDULE_BLUE_DARK,
    SCHEDULE_BLUE_LIGHT,
    SCHEDULE_PURPLE,
    SCHEDULE_ORANGE,
];
export const STATUS_RED = "#c53b3c";
export const STATUS_YELLOW = "#ebd563";
export const STATUS_GREEN = "#5d9c4c";
