import axios from "axios";
import { BASE_URL, PRODUCT_ENDPOINT } from "../ApiConfig";
import { getToken } from "../../hook/UseToken";
import { generateToastText, handleApiCall, handleApiGetCall } from "../../utils/ApiUtils";
import { Product } from "../models/Product";

const product = "Product";
const endpoint = BASE_URL + PRODUCT_ENDPOINT;

export async function getProduct(productId: number, getText: Function): Promise<Product> {
    const response = axios.get(endpoint + "/" + productId, getToken());
    return handleApiGetCall(response, product, productId, getText);
}

export async function putProduct(productJson: Product, productId: number, getText: Function): Promise<Product> {
    const messages = generateToastText(product, "PUT", getText);
    const response = axios.put(endpoint + "/" + productId, productJson, getToken());
    return handleApiCall(response, messages);
}

export async function postProduct(productJson: Product, getText: Function): Promise<Product> {
    const messages = generateToastText(product, "POST", getText);
    const response = axios.post(endpoint, productJson, getToken());
    return handleApiCall(response, messages);
}

export async function deleteProduct(productId: number, getText: Function): Promise<Product> {
    const messages = generateToastText(product, "DELETE", getText);
    const response = axios.delete(endpoint + "/" + productId, getToken());
    return handleApiCall(response, messages);
}
