import { ProductionOrder } from "./ProductionOrder";
import TextIds from "../../language/TextIds";

export interface Schedule {
    id: number;
    line_id: number;
    production_orders: ProductionOrder[];
}

export interface ScheduleOrder {
    id: number;
    product_id: number;
    product_name: string;
    quantity: number;
}

export interface ScheduleItem {
    id: number;
    items: [{ end: string; start: string }];
    production_order: ScheduleOrder;
    station_id: number;
    customer_name: string;
}

export function createEmptySchedule(): Schedule {
    return { id: 0, line_id: 0, production_orders: [] };
}

export enum SchedulingMethod {
    GA = TextIds.OPTIMISED as any,
    FIFO = TextIds.STANDARD as any,
}
