import axios from "axios";
import { BASE_URL, PRODUCT_GROUP_ENDPOINT } from "../ApiConfig";
import { getToken } from "../../hook/UseToken";
import { ProductGroup } from "../models/ProductGroup";
import { generateToastText, handleApiCall, handleApiGetCall } from "../../utils/ApiUtils";

const productGroup = "ProductGroup";
const endpoint = BASE_URL + PRODUCT_GROUP_ENDPOINT;

export async function getProductGroup(productGroupId: number, getText: Function): Promise<ProductGroup> {
    const response = axios.get(endpoint + "/" + productGroupId, getToken());
    return handleApiGetCall(response, productGroup, productGroupId, getText);
}

export async function putProductGroup(
    productGroupJson: ProductGroup,
    productGroupId: number,
    getText: Function
): Promise<ProductGroup> {
    const messages = generateToastText(productGroup, "PUT", getText);
    const response = axios.put(endpoint + "/" + productGroupId, productGroupJson, getToken());
    return handleApiCall(response, messages);
}

export async function postProductGroup(productGroupJson: ProductGroup, getText: Function): Promise<ProductGroup> {
    const messages = generateToastText(productGroup, "POST", getText);
    const response = axios.post(endpoint, productGroupJson, getToken());
    return handleApiCall(response, messages);
}

export async function deleteProductGroup(productGroupId: number, getText: Function): Promise<ProductGroup> {
    const messages = generateToastText(productGroup, "DELETE", getText);
    const response = axios.delete(endpoint + "/" + productGroupId, getToken());
    return handleApiCall(response, messages);
}
