import axios from "axios";
import { BASE_URL, EXTERNAL_MATERIAL_ENDPOINT } from "../ApiConfig";
import { getToken } from "../../hook/UseToken";
import { ExternalMaterial } from "../models/ExternalMaterial";
import { generateToastText, handleApiCall, handleApiGetCall } from "../../utils/ApiUtils";

const externalMaterial = "ExternalMaterial";
const endpoint = BASE_URL + EXTERNAL_MATERIAL_ENDPOINT;

export async function getExternalMaterial(externalMaterialId: number, getText: Function): Promise<ExternalMaterial> {
    const response = axios.get(endpoint + "/" + externalMaterialId, getToken());
    return handleApiGetCall(response, externalMaterial, externalMaterialId, getText);
}

export async function putExternalMaterial(
    externalMaterialJson: ExternalMaterial,
    externalMaterialId: number,
    getText: Function
): Promise<ExternalMaterial> {
    const messages = generateToastText(externalMaterial, "PUT", getText);
    const response = axios.put(endpoint + "/" + externalMaterialId, externalMaterialJson, getToken());
    return handleApiCall(response, messages);
}

export async function postExternalMaterial(
    externalMaterialJson: ExternalMaterial,
    getText: Function
): Promise<ExternalMaterial> {
    const messages = generateToastText(externalMaterial, "POST", getText);
    const response = axios.post(endpoint, externalMaterialJson, getToken());
    return handleApiCall(response, messages);
}

export async function deleteExternalMaterial(externalMaterialId: number, getText: Function): Promise<ExternalMaterial> {
    const messages = generateToastText(externalMaterial, "DELETE", getText);
    const response = axios.delete(endpoint + "/" + externalMaterialId, getToken());
    return handleApiCall(response, messages);
}
