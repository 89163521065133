import { TimelineElement } from "../../../../api/models/GeneralTypes";
import { useText } from "../../../../context/LanguageContext";
import Popup from "../../../../components/Popup";
import React from "react";

interface TimelineItemPopUpProps {
    item: TimelineElement;
    timelineItem: JSX.Element;
}

export default function TimelineItemPopUp({ item, timelineItem }: TimelineItemPopUpProps): JSX.Element {
    const getText = useText();

    return (
        <Popup onClickElement={timelineItem}>
            <div className="flex w-64 flex-col gap-2 p-2">
                {item.popUp.map((popUp, index) => {
                    if (index === 0)
                        return (
                            <div key={popUp.title} className="flex justify-between">
                                <p className="font-bold">{getText(popUp.title)}</p>
                                <div className="flex space-x-4">
                                    <div
                                        className="h-5 w-5 shrink-0 grow-0 rounded-full shadow-2xl"
                                        style={{
                                            backgroundColor: item.color,
                                        }}
                                    />
                                    <p className="font-bold">{popUp.info}</p>
                                </div>
                            </div>
                        );
                    return (
                        <div key={popUp.title} className="flex justify-between">
                            <p className="font-semibold">{getText(popUp.title)}:</p>
                            <p
                                className="text-right"
                                lang="de"
                                style={{
                                    wordWrap: "break-word",
                                    overflowWrap: "break-word",
                                    hyphens: "auto",
                                }}
                            >
                                {popUp.info}
                            </p>
                        </div>
                    );
                })}
            </div>
        </Popup>
    );
}
