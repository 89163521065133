import axios from "axios";
import { BASE_URL, EXTERNAL_MATERIAL_GROUP_ENDPOINT } from "../ApiConfig";
import { getToken } from "../../hook/UseToken";
import { ExternalMaterialGroup } from "../models/ExternalMaterialGroup";
import { generateToastText, handleApiCall, handleApiGetCall } from "../../utils/ApiUtils";

const externalMaterialGroup = "ExternalMaterialGroup";
const endpoint = BASE_URL + EXTERNAL_MATERIAL_GROUP_ENDPOINT;

export async function getExternalMaterialGroup(
    externalMaterialGroupId: number,
    getText: Function
): Promise<ExternalMaterialGroup> {
    const response = axios.get(endpoint + "/" + externalMaterialGroupId, getToken());
    return handleApiGetCall(response, externalMaterialGroup, externalMaterialGroupId, getText);
}

export async function putExternalMaterialGroup(
    externalMaterialGroupJson: ExternalMaterialGroup,
    externalMaterialGroupId: number,
    getText: Function
): Promise<ExternalMaterialGroup> {
    const messages = generateToastText(externalMaterialGroup, "PUT", getText);
    const response = axios.put(endpoint + "/" + externalMaterialGroupId, externalMaterialGroupJson, getToken());
    return handleApiCall(response, messages);
}

export async function postExternalMaterialGroup(
    externalMaterialGroupJson: ExternalMaterialGroup,
    getText: Function
): Promise<ExternalMaterialGroup> {
    const messages = generateToastText(externalMaterialGroup, "POST", getText);
    const response = axios.post(endpoint, externalMaterialGroupJson, getToken());
    return handleApiCall(response, messages);
}

export async function deleteExternalMaterialGroup(
    externalMaterialGroupId: number,
    getText: Function
): Promise<ExternalMaterialGroup> {
    const messages = generateToastText(externalMaterialGroup, "DELETE", getText);
    const response = axios.delete(endpoint + "/" + externalMaterialGroupId, getToken());
    return handleApiCall(response, messages);
}
