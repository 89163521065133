import { addDays, getHours, intervalToDuration, isSameDay, isThisHour, isToday, isWeekend } from "date-fns";
import React from "react";
import { COLUMN_WIDTH, getMarginForTime } from "./TimelineUtils";
import { TimelineDates } from "../../../../api/models/GeneralTypes";
import Downtime, { DowntimeItem } from "../../../../api/models/Downtime";
import { TestIds } from "../../../../TestIds";

export interface VerticalLines {
    lines: JSX.Element[];
    weekend: JSX.Element[];
    today: JSX.Element;
    downtimeItems: JSX.Element[];
}

export function createVerticalLines(
    timelineDates: TimelineDates,
    timelineFullHeight: number,
    downtimeItems: DowntimeItem[],
    columWidthIndex: number = 1
): VerticalLines {
    let verticalLines: VerticalLines = {
        lines: [],
        weekend: [],
        today: <></>,
        downtimeItems: [],
    };
    let marginLeft = 0;
    const now = new Date();

    timelineDates.dates.forEach((date: Date, index: number) => {
        if (index % columWidthIndex !== 0) {
            return;
        }
        if (isToday(date)) {
            let todayMarginLeft = getMarginForTime(columWidthIndex, 0, now.getHours(), now.getMinutes());
            verticalLines.today = createTodayLine(marginLeft + todayMarginLeft, timelineFullHeight);
        }
        if (isWeekend(date)) {
            const background = createWeekendBackground(marginLeft, COLUMN_WIDTH * columWidthIndex, timelineFullHeight);
            verticalLines.weekend.push(background);
        }
        const downtimes = createDowntimes(marginLeft, downtimeItems, date, columWidthIndex, timelineFullHeight);
        verticalLines.downtimeItems.push(...downtimes);

        const verticalLine = createVerticalLine(marginLeft, date, timelineFullHeight);
        verticalLines.lines.push(verticalLine);
        marginLeft += COLUMN_WIDTH * columWidthIndex;
    });

    return verticalLines;
}

export function createVerticalLinesForDay(
    timelineDates: TimelineDates,
    timelineFullHeight: number,
    downtimeItems: DowntimeItem[]
): VerticalLines {
    let verticalLines: VerticalLines = {
        lines: [],
        weekend: [],
        today: <></>,
        downtimeItems: [],
    };

    const now = new Date();
    let todayMarginLeft = COLUMN_WIDTH * (now.getMinutes() / 0.6 / 100);
    let marginLeft = 0;

    for (let date of timelineDates.dates) {
        if (isThisHour(date)) {
            verticalLines.today = createTodayLine(marginLeft + todayMarginLeft, timelineFullHeight);
        }

        marginLeft += COLUMN_WIDTH;
        const linedHours = [23, 20, 17, 14, 11, 8, 5, 2];
        if (linedHours.includes(getHours(date))) {
            const verticalLine = createVerticalLine(marginLeft, date, timelineFullHeight);
            verticalLines.lines.push(verticalLine);
        }

        if (getHours(date) === 23 && isWeekend(addDays(date, 1))) {
            const background = createWeekendBackground(marginLeft, COLUMN_WIDTH * 24, timelineFullHeight);
            verticalLines.weekend.push(background);
        }

        if (getHours(date) === 0) {
            const downtimes = createDowntimes(marginLeft - COLUMN_WIDTH, downtimeItems, date, 24, timelineFullHeight);
            verticalLines.downtimeItems.push(...downtimes);
        }
    }
    return verticalLines;
}

export function createDowntimes(
    margin: number,
    downtimeItems: DowntimeItem[],
    date: Date,
    columnWidthIndex: number,
    timelineFullHeight: number
): JSX.Element[] {
    let downtimes: JSX.Element[] = [];
    downtimeItems.forEach((item: DowntimeItem, index: number) => {
        const startDate = new Date(item.start);

        if (!isSameDay(startDate, date)) return;
        const marginStart = getMarginForTime(columnWidthIndex, 0, startDate.getHours(), startDate.getMinutes());

        const endDate = new Date(item.end);
        let daysBetween = intervalToDuration({ start: startDate, end: endDate }).days;
        const marginEnd = getMarginForTime(columnWidthIndex, daysBetween, endDate.getHours(), endDate.getMinutes());

        const downtimeDiv = createDowntimeDiv(
            margin + marginStart,
            marginEnd - marginStart,
            timelineFullHeight,
            item.downtimes,
            index
        );
        downtimes.push(downtimeDiv);
    });
    return downtimes;
}

function createDowntimeDiv(
    margin: number,
    width: number,
    timelineFullHeight: number,
    downtimeItems: Downtime[],
    index: number
): JSX.Element {
    return (
        <div
            className="absolute z-10 my-0.5 flex items-center rounded-md bg-gray-light opacity-50"
            data-testid={TestIds.DOWNTIME_ITEM + index}
            key={margin}
            style={{
                height: timelineFullHeight,
                left: margin,
                width: width,
            }}
        />
    );
}

function createTodayLine(margin: number, timelineFullHeight: number): JSX.Element {
    return <div style={{ height: timelineFullHeight, left: margin }} className="absolute z-20 w-0.5 bg-status-red" />;
}

function createWeekendBackground(margin: number, width: number, timelineFullHeight: number): JSX.Element {
    return (
        <div
            key={"end" + margin}
            style={{ height: timelineFullHeight, left: margin, width: width }}
            className="absolute z-0 bg-gray-very-light"
        />
    );
}

function createVerticalLine(margin: number, date: Date, timelineFullHeight: number): JSX.Element {
    return (
        <div
            style={{ left: margin, height: timelineFullHeight }}
            key={"Row" + date}
            className="absolute z-10 border-l border-dashed border-schedule-stroke"
        />
    );
}
