import { GeneralObjectType } from "../api/models/GeneralTypes";

export function getById(id: number, list: GeneralObjectType[], createEmptyMaterial: Function): any {
    let material = list.find((value) => value.id === id);
    if (material) return material;
    return createEmptyMaterial();
}

export function getByProp(
    value: any,
    prop: string,
    list: { [propName: string]: any }[],
    createEmptyMaterial: Function
): any {
    let material = list.find((entry) => entry[prop] === value);
    return material ? material : createEmptyMaterial();
}

export function findObjectByFieldInList(value: any, prop: string, list: { [propName: string]: any }[]): any {
    let material = list.find((entry) => entry[prop] === value);
    return material ? material : list[0];
}

export function getIndex(id: number, list: any[], propName: string): number {
    return list.findIndex((value) => value[propName] === id);
}

export function getValuesFilteredByList(
    listIsIncluded: any[],
    listToFilter: GeneralObjectType[],
    propName: string
): any[] {
    return listToFilter.filter((value) => listIsIncluded.map((value) => value[propName]).includes(value.id));
}

export function getInListIncludedValues(listOfIds: number[], listToFilter: GeneralObjectType[]): any[] {
    return listToFilter.filter((value) => listOfIds.includes(value.id));
}

export function getInListIncludedValue(listToFind: GeneralObjectType[], listIsIncluded: number[]): any {
    return listToFind.find((value) => listIsIncluded.includes(value.id));
}

export function isElementInListById(list: GeneralObjectType[], id: number): boolean {
    return list.some((value) => value.id === id);
}

export function isObjectInList(list: any[], propName: string, id: number): boolean {
    return list.map((value) => value[propName]).includes(id);
}

export function getUniqueVersionOfArray(array: any[]): any[] {
    return array.filter((value, index, self) => self.map((x) => x.id).indexOf(value.id) === index);
}
