import axios from "axios";
import { BASE_URL, LOGOUT_ENDPOINT } from "../ApiConfig";
import { getToken } from "../../hook/UseToken";
import { trackPromise } from "react-promise-tracker";

const endpoint = BASE_URL + LOGOUT_ENDPOINT;

export default async function logout() {
    return trackPromise(axios.delete(endpoint, getToken()));
}
