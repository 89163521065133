import React, { useEffect, useState } from "react";
import Pagination from "../../components/Pagination";
import { filterProductionOrderList, ProductionOrder } from "../../api/models/ProductionOrder";
import { TestIds } from "../../TestIds";
import Table from "../../components/Table";
import { getOrderStatusMarble } from "../../utils/StatusMarbleUtils";
import { tableBodyClass, tableRowClass } from "../../utils/TableUtils";
import { useText } from "../../context/LanguageContext";
import TextIds from "../../language/TextIds";

interface ProductionOrderProps {
    productionOrders: ProductionOrder[];
    filterInput: string;
}

export default function ProductionOrderTable({ productionOrders, filterInput }: ProductionOrderProps): JSX.Element {
    const getText = useText();
    const [shownProductionOrders, setShownProductionOrders] = useState<ProductionOrder[]>([]);
    const [sortedProductionOrders, setSortedProductionOrders] = useState<ProductionOrder[]>([]);
    const [filteredProductionOrders, setFilteredProductionOrders] = useState<ProductionOrder[]>([]);
    useEffect(
        () => setFilteredProductionOrders(filterProductionOrderList(filterInput, productionOrders, getText)),
        [setFilteredProductionOrders, productionOrders, filterInput, getText]
    );

    const tableHeader = {
        className: "bg-green-middle text-white",
        columns: [
            {
                headerTitle: getText(TextIds.ProductionOrder.NAME),
                sortingValue: ["id"],
                className: "lg:min-w-32",
            },
            {
                headerTitle: `${getText(TextIds.ProductionOrder.PRODUCT_ID)}`,
                sortingValue: ["product", "internal_id"],
                className: "lg:min-w-32",
            },
            {
                headerTitle: `${getText(TextIds.ProductionOrder.PRODUCT_NAME)}`,
                sortingValue: ["product", "name"],
                className: "md:min-w-40",
            },
            { headerTitle: getText(TextIds.Form.QUANTITY), sortingValue: ["quantity"], className: "md:min-w-28" },
            { headerTitle: getText(TextIds.Form.START_DATE), sortingValue: ["start_date"], className: "md:min-w-44" },
            { headerTitle: getText(TextIds.Form.END_DATE), sortingValue: ["end_date"], className: "md:min-w-44" },
            { headerTitle: getText(TextIds.Status.STATUS), sortingValue: ["status"], className: "md:min-w-8" },
        ],
    };
    return (
        <div className="grow">
            <div className="h-[70vh] overflow-y-auto sm:overflow-x-hidden">
                <Table
                    setSortedElements={setSortedProductionOrders}
                    elements={filteredProductionOrders}
                    header={tableHeader}
                    id={TestIds.PRODUCTION_ORDER_TABLE}
                >
                    <tbody className={tableBodyClass}>
                        {shownProductionOrders.map((order) => {
                            return (
                                <tr
                                    className={tableRowClass}
                                    data-testid={TestIds.PRODUCTION_ORDER_ROW + order.id}
                                    key={order.id}
                                >
                                    <td>{order.id}</td>
                                    <td>{order.product.id}</td>
                                    <td>{order.product.name}</td>
                                    <td>{order.quantity}</td>
                                    <td>{order.start_date}</td>
                                    <td>{order.end_date}</td>
                                    <td className="pl-3">{getOrderStatusMarble(order)}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
            <Pagination items={sortedProductionOrders} setShownItems={setShownProductionOrders} />
        </div>
    );
}
