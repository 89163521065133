import React from "react";
import { Dialog } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/24/outline";

interface FeedbackFormProps {
    open: boolean;
    setOpen: Function;
}

export default function FeedbackForm({ open, setOpen }: FeedbackFormProps) {
    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <div className="fixed inset-0 z-100 bg-black bg-opacity-90" />
            <div className="fixed inset-0 z-100 flex  items-center justify-center">
                <Dialog.Panel className="h-3/4 w-full max-w-2xl transform divide-y rounded-lg bg-white p-6 text-right align-middle">
                    <Dialog.Title className="grid">
                        <XCircleIcon
                            className="mr-[-1rem] mt-[-1rem] h-8 w-8 cursor-pointer justify-self-end text-gray-middle"
                            onClick={() => setOpen(false)}
                        />
                    </Dialog.Title>
                    <iframe
                        className="h-full w-full"
                        src="https://forms.office.com/Pages/ResponsePage.aspx?id=HjbjTpesZ0u0G1xX5XflvCFJ8K5YqLNHvSlYjy4IKRhUQ0JNWEZYSDJPTDhXUFVWMEFEUk01UzNYRS4u&embed=true"
                    ></iframe>
                </Dialog.Panel>
            </div>
        </Dialog>
    );
}
