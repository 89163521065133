import { Station } from "./Station";
import { Workday } from "./Workday";
import Downtime from "./Downtime";

export interface Line {
    id: number;
    name: string;
    schedule_id: number;
    freeze_time: number;
    horizon: number;
    production_id: number;
    downtimes: Downtime[];
    stations: Station[];
    workdays: Workday[];
    initial_date: string;
}

export function createEmptyLine(): Line {
    return {
        id: 0,
        name: "",
        freeze_time: 0,
        horizon: 0,
        schedule_id: 0,
        production_id: 0,
        downtimes: [],
        stations: [],
        workdays: [],
        initial_date: "",
    };
}
