import React, { useCallback, useState } from "react";
import ExtMaterialGroupForm from "../forms/ExtMaterialGroupForm";
import ExternalMaterialIcon from "../icons/ExternalMaterialIcon.svg";
import InnerNode from "./InnerNode";
import { ExternalMaterialGroup } from "../../api/models/ExternalMaterialGroup";
import { TestIds } from "../../TestIds";

export interface ExtMaterialGroupNodeProps {
    extMaterialGroup: ExternalMaterialGroup;
    quantity?: number;
    addButton?: boolean;
}

export default function ExtMaterialGroupNode({
    extMaterialGroup,
    quantity = 0,
    addButton = false,
}: ExtMaterialGroupNodeProps): JSX.Element {
    const [isExtMaterialGroupOpen, setIsExtMaterialGroupOpen] = useState<boolean>(false);
    const openForm = useCallback(() => setIsExtMaterialGroupOpen(true), []);
    const testId =
        extMaterialGroup.id !== 0
            ? TestIds.EXT_MATERIAL_GROUP_NODE + extMaterialGroup.id
            : TestIds.ADD_EXT_MATERIAL_GROUP_BUTTON;

    return (
        <>
            <InnerNode
                className="bg-gray-dark"
                name={extMaterialGroup.name}
                icon={ExternalMaterialIcon}
                openForm={openForm}
                addButton={addButton}
                quantity={quantity}
                id={testId}
            />
            <ExtMaterialGroupForm
                selectedExtMaterialGroup={extMaterialGroup}
                setOpen={setIsExtMaterialGroupOpen}
                open={isExtMaterialGroupOpen}
            />
        </>
    );
}
