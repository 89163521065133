import { TestIds } from "../../../TestIds";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import TextIds from "../../../language/TextIds";
import Checkbox from "../../form-inputs/Checkbox";
import React from "react";
import { useText } from "../../../context/LanguageContext";

interface RescheduleWarningProps {
    showWarning: boolean;
    isSaveEnabled: boolean;
    setIsSaveEnabled: Function;
}

export default function RescheduleWarning({
    showWarning,
    isSaveEnabled,
    setIsSaveEnabled,
}: RescheduleWarningProps): JSX.Element {
    const getText = useText();

    if (!showWarning) {
        return <div className="h-16" />;
    }

    return (
        <div
            className="m-4 flex h-16 items-center justify-center space-x-4 text-lg font-bold text-red-700"
            data-testid={TestIds.RESCHEDULE_FORM_WARNING}
        >
            <InformationCircleIcon className="h-12" />
            {getText(TextIds.Form.GETS_RESCHEDULED)}
            <Checkbox label={getText(TextIds.Form.CONFIRM)} checked={isSaveEnabled} setChecked={setIsSaveEnabled} />
        </div>
    );
}
