import { Product } from "./Product";
import { getUniqueVersionOfArray } from "../../utils/FilterUtils";
import TextIds from "../../language/TextIds";

export enum ProductType {
    PRE = TextIds.Status.PRE as any,
    END = TextIds.Status.END as any,
}

export interface ProductGroup {
    id: number;
    name: string;
    product_type: ProductType;
    station_id: number;
    external_material_groups: { external_material_group_id: number; quantity: number }[];
    products: Product[];
    pre_product_groups: { pre_product_group_id: number; quantity: number }[];
    production_id: number;
}

export function createEmptyProductGroup(): ProductGroup {
    return {
        id: 0,
        name: "",
        product_type: ProductType.PRE,
        station_id: 0,
        external_material_groups: [],
        products: [],
        pre_product_groups: [],
        production_id: 0,
    };
}

export function getPreProductGroupsDeepSearch(
    productGroup: ProductGroup,
    productionProductGroups: ProductGroup[]
): ProductGroup[] {
    let productGroups: ProductGroup[] = [];

    for (const preProductGroup of productGroup.pre_product_groups) {
        const productGroup = productionProductGroups.filter(
            (group) => group.id === preProductGroup.pre_product_group_id
        )[0];
        productGroups.push(productGroup);

        if (productGroup.pre_product_groups.length > 0)
            productGroups.push(...getPreProductGroupsDeepSearch(productGroup, productionProductGroups));
    }

    return getUniqueVersionOfArray(productGroups);
}
