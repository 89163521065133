import { Listbox, Transition } from "@headlessui/react";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import Label from "../Label";
import { sortByIdAsc } from "../../utils/SortUtils";
import { SelectBoxOption } from "../../api/models/GeneralTypes";
import { TestIds } from "../../TestIds";
import { useText } from "../../context/LanguageContext";
import TextIds from "../../language/TextIds";

interface SelectBoxProps {
    options: SelectBoxOption[];
    defaultValues: SelectBoxOption[];
    className?: string;
    onChange: Function;
    label?: string;
    required?: boolean;
    id?: string;
}

export default function MultiSelect({
    options,
    onChange,
    defaultValues,
    className,
    label,
    required = false,
    id,
}: SelectBoxProps) {
    const getText = useText();
    const [selectedValues, setSelectedValues] = useState(defaultValues);
    const [shownText, setShownText] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const sortedOptions = [...options].sort(sortByIdAsc);

    const createShownText = useCallback(
        (values: SelectBoxOption[]) => {
            if (options.length === 0) {
                setShownText(getText(TextIds.Form.EMPTY_SELECT));
                return;
            }

            if (values.length === 0) {
                setShownText(getText(TextIds.Form.NOTHING_SELECTED));
                return;
            }
            values.sort(sortByIdAsc);
            const text = values.map((e) => e.name).join(", ");
            setShownText(text);
        },
        [options.length, getText]
    );

    useEffect(() => {
        setSelectedValues(defaultValues);
        createShownText(defaultValues);
    }, [options, defaultValues, createShownText]);

    function changeSelection(item: any) {
        let list = selectedValues;
        let index = list.findIndex((value) => value.id === item.id);
        if (index >= 0) list.splice(index, 1);
        else list.push(item);
        list.sort(sortByIdAsc);
        setSelectedValues(list);
        createShownText(selectedValues);
        onChange(list);
    }

    return (
        <div className={className} data-testid={id}>
            <Label>
                {label}
                {required && <span className="text-red-600">*</span>}
            </Label>
            <Listbox value={selectedValues} onChange={(item) => changeSelection(item)}>
                <div className="relative">
                    <Listbox.Button
                        className="relative z-auto w-full cursor-pointer rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md hover:bg-gray-very-light focus:outline-none"
                        onClick={() => setIsOpen(!isOpen)}
                        data-testid={TestIds.MULTISELECT_BUTTON}
                    >
                        <span className="block truncate">{shownText}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon className="h-5 w-5 text-gray-light" aria-hidden="true" />
                        </span>
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        show={isOpen}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options
                            className="absolute z-100 mt-1 max-h-32 w-full overflow-auto rounded-md bg-white py-1 shadow-lg focus:outline-none"
                            onMouseLeave={() => setIsOpen(false)}
                            data-testid={TestIds.MULTISELECT_OPTIONS}
                        >
                            {sortedOptions.map((option, _) => (
                                <Listbox.Option
                                    data-testid={TestIds.OPTION + option.id}
                                    key={option.id}
                                    className="relative cursor-default select-none bg-white py-2 pl-10 pr-4 text-black hover:bg-gray-very-light"
                                    value={option}
                                >
                                    <>
                                        <span className="block truncate font-normal">{option.name}</span>
                                        {selectedValues.findIndex((value) => value.id === option.id) >= 0 && (
                                            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                                                <CheckIcon className="h-5 w-5 text-green-middle" />
                                            </div>
                                        )}
                                    </>
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        </div>
    );
}
