import { TestIds } from "../../../TestIds";
import React, { useCallback, useEffect, useState } from "react";
import { createEmptyWorkday, Weekday, Workday } from "../../../api/models/Workday";
import WorkdayInput from "./WorkdayInput";
import RescheduleWarning from "./RescheduleWarning";
import { getJsonFromList } from "../../../utils/FormUtils";

interface WorkdayFormProps {
    selectedWorkdays: Workday[];
    savedWorkdays: Workday[];
    setSelectedWorkdays: Function;
    isEditMode?: boolean;
    setSaveEnabled: Function;
}

export default function WorkdayForm({
    setSelectedWorkdays,
    selectedWorkdays,
    savedWorkdays,
    isEditMode = true,
    setSaveEnabled,
}: WorkdayFormProps): JSX.Element {
    const [workdays, setWorkdays] = useState<Workday[]>([]);
    const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);

    const setWorkday = useCallback(
        (workday: Workday) => {
            workdays[+Weekday[workday.day]] = workday;
            setWarning(workdays);
            setSelectedWorkdays([...workdays]);
        },
        [workdays, setSelectedWorkdays]
    );

    const setWarning = useCallback(
        (workdays: Workday[]) => {
            setShowWarning(isEditMode && getJsonFromList(selectedWorkdays) !== getJsonFromList(workdays));
        },
        [selectedWorkdays]
    );

    useEffect(() => {
        if (isEditMode || savedWorkdays.length !== 0) {
            setWorkdays([...savedWorkdays]);
            return;
        }

        let days = [];
        for (let item of Object.keys(Weekday)) {
            if (isNaN(Number(item))) continue;

            days.push({
                ...createEmptyWorkday(),
                day: Weekday[item as keyof typeof Weekday],
                enabled: +item < 5,
                start: "08:00",
                end: "16:00",
            });
        }
        setWorkdays([...days]);
    }, [isEditMode, setWorkdays]);

    useEffect(() => {
        if (!showWarning) {
            return;
        }
        setSaveEnabled(isSaveEnabled);
    }, [isSaveEnabled, showWarning]);

    return (
        <div className="space-y-4 rounded-md p-2 pt-4" data-testid={TestIds.WORKDAY_FORM}>
            {workdays.map((workday) => (
                <WorkdayInput key={workday.day} selectedWorkday={workday} setSelectedWorkday={setWorkday} />
            ))}
            <RescheduleWarning
                setIsSaveEnabled={setIsSaveEnabled}
                isSaveEnabled={isSaveEnabled}
                showWarning={showWarning}
            />
        </div>
    );
}
