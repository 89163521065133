import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactFlow, {
    addEdge,
    ConnectionLineType,
    ControlButton,
    Controls,
    Edge,
    FitViewOptions,
    Node,
    Position,
    useEdgesState,
    useNodesState,
} from "reactflow";
import dagre from "dagre";
import HandleNode from "./HandleNode";
import StationNode from "./StationNode";
import { ArrowsPointingInIcon, ArrowsPointingOutIcon } from "@heroicons/react/24/outline";
import { Dialog } from "@headlessui/react";
import { TestIds } from "../../TestIds";
import "reactflow/dist/style.css";

interface FlowTreeProps {
    nodes: Node[];
    edges: Edge[];
    isFlowFullscreen?: boolean;
    setIsFlowFullscreen?: Function;
    className?: string;
    children?: JSX.Element;
}

export default function FlowTree({
    nodes,
    edges,
    isFlowFullscreen = false,
    setIsFlowFullscreen = () => {},
    className,
    children,
}: FlowTreeProps): JSX.Element {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    const [positionedNodes, setNodes, onNodesChange] = useNodesState([]);
    const [positionedEdges, setEdges, onEdgesChange] = useEdgesState([]);
    const [isFullScreen, setIsFullScreen] = useState<boolean>(isFlowFullscreen);

    const nodeWidth = 192;
    const nodeHeight = 40;

    const nodeTypes = useMemo(
        () => ({
            handleNode: HandleNode,
            stationNode: StationNode,
        }),
        []
    );

    useEffect(() => {
        getLayoutedElements();
    }, [nodes, edges]);

    useEffect(() => {
        setIsFlowFullscreen(isFullScreen);
    }, [isFullScreen]);

    function getLayoutedElements(): void {
        dagreGraph.setGraph({ rankdir: "LR" });
        const positionedNodes = [];
        for (let node of nodes) dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
        for (let edge of edges) dagreGraph.setEdge(edge.source, edge.target);
        dagre.layout(dagreGraph);

        for (let node of nodes) {
            const nodeWithPosition = dagreGraph.node(node.id);
            node.targetPosition = Position.Left;
            node.sourcePosition = Position.Right;

            node.position = {
                x: nodeWithPosition.x - nodeWidth / 2,
                y: nodeWithPosition.y - nodeHeight / 2,
            };
            positionedNodes.push(node);
        }
        setNodes(positionedNodes);
        setEdges(edges);
    }

    const onConnect = useCallback(
        (params: any) =>
            setEdges((eds) => addEdge({ ...params, type: ConnectionLineType.SmoothStep, animated: true }, eds)),
        []
    );

    const fitViewOptions: FitViewOptions = {
        padding: getPadding(),
    };

    function getPadding(): number {
        const countedNodesX = countNodesOnAxis("x");
        const countedNodesY = countNodesOnAxis("y");

        if (countedNodesX === 0 || countedNodesX > 6) return 0.1;
        if (countedNodesY === 0 || countedNodesY > 6) return 0.1;

        const paddingForNodes = [
            [6, 2.5, 1.25, 0.75, 0.25],
            [2.5, 1.25, 0.75, 0.25, 0.1],
            [0.1, 1.75, 1.25, 0.75, 0.25],
            [1, 1, 0.75, 0.25, 0.1],
            [0.1, 0.5, 0.5, 0.5, 0.25],
            [0.25, 0.25, 0.25, 0.25, 0.1],
        ];
        return paddingForNodes[countedNodesX - 1][countedNodesY - 1];
    }

    function countNodesOnAxis(position: "x" | "y"): number {
        let axisNodesCounter: { position: number; quantity: number }[] = [];
        let nodesPosition: number[] = Array.from(nodes, (value) => value.position[position]);

        for (let nodePosition of nodesPosition) {
            if (!axisNodesCounter.some((value) => value.position === nodePosition))
                axisNodesCounter.push({
                    position: nodePosition,
                    quantity: nodesPosition.filter((value) => value === nodePosition).length,
                });
        }
        if (axisNodesCounter.length <= 0) {
            return 0;
        }

        axisNodesCounter.sort((a, b) => b.quantity - a.quantity);
        return axisNodesCounter[0].quantity;
    }

    function getFlowTree(): JSX.Element {
        return (
            <div className="static h-full" data-testid={TestIds.FLOW}>
                <div className="absolute z-20 w-full">{children}</div>
                <ReactFlow
                    className={className}
                    nodeTypes={nodeTypes}
                    nodes={positionedNodes}
                    edges={positionedEdges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    connectionLineType={ConnectionLineType.SmoothStep}
                    fitView
                    fitViewOptions={fitViewOptions}
                >
                    <Controls>
                        <ControlButton onClick={() => setIsFullScreen(!isFullScreen)}>
                            {isFullScreen ? <ArrowsPointingInIcon /> : <ArrowsPointingOutIcon />}
                        </ControlButton>
                    </Controls>
                </ReactFlow>
            </div>
        );
    }

    if (isFullScreen)
        return (
            <Dialog open={isFullScreen} onClose={() => setIsFullScreen(false)}>
                <div className="fixed inset-0 z-100 bg-black bg-opacity-90" />
                <div className="fixed inset-0 z-100 flex items-center justify-center">
                    <Dialog.Panel className="h-screen w-screen transform bg-gray-bright">{getFlowTree()}</Dialog.Panel>
                </div>
            </Dialog>
        );
    return getFlowTree();
}
