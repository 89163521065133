import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from "react";
import { datePattern, namePattern } from "../../utils/FormUtils";
import { toast } from "react-toastify";
import { useProduction, useProductionUpdate } from "../../context/ProductionContext";
import Modal from "../Modal";
import Input from "../form-inputs/Input";
import FormFooter from "../FormFooter";
import Select from "../form-inputs/Select";
import { getById } from "../../utils/FilterUtils";
import CounterBox from "../form-inputs/CounterBox";
import MultiSelect from "../form-inputs/MultiSelect";
import { createEmptyCustomerOrder, CustomerOrder } from "../../api/models/CustomerOrder";
import { Product } from "../../api/models/Product";
import { deleteCustomerOrder, postCustomerOrder, putCustomerOrder } from "../../api/calls/CustomerOrder";
import { createEmptyCustomer, Customer } from "../../api/models/Customer";
import { createEmptyCustomerOrderDetail } from "../../api/models/CustomerOrderDetails";
import { TestIds } from "../../TestIds";
import { useText } from "../../context/LanguageContext";
import TextIds from "../../language/TextIds";
import { getValidEndProducts } from "../../api/calls/Products";
import { NAME_LENGTH } from "../../constants";

export interface CustomerOrderProps {
    selectedCustomerOrder: CustomerOrder;
    setOpen: Function;
    open: boolean;
}

export default function CustomerOrderForm({ selectedCustomerOrder, setOpen, open }: CustomerOrderProps): JSX.Element {
    const getText = useText();
    const production = useProduction();
    const updateProduction = useProductionUpdate();

    const [customerOrder, setCustomerOrder] = useState<CustomerOrder>(createEmptyCustomerOrder());
    const [modalTitle, setModalTitle] = useState("");
    const [endProducts, setEndProducts] = useState<Product[]>([]);
    const [productCounter, setProductCounter] = useState<JSX.Element[]>([]);
    const [productMultiSelect, setProductMultiSelect] = useState<JSX.Element>();
    const [selectedProductWithAmount, setSelectedProductWithAmount] = useState<Map<number, number>>(new Map());

    const close = () => setOpen(false);
    const isEditMode = selectedCustomerOrder.id > 0;
    const deleteFunction = () =>
        deleteCustomerOrder(customerOrder.id, getText).then(() => {
            updateProduction(production.id);
            close();
        });

    const setInternalId = useCallback(
        (event: ChangeEvent<HTMLInputElement>) =>
            setCustomerOrder((customerOrder) => ({
                ...customerOrder,
                internal_id: event.target.value,
            })),
        []
    );

    const setCustomerId = useCallback(
        (customer: Customer) =>
            setCustomerOrder((customerOrder) => ({
                ...customerOrder,
                customer_id: customer.id,
            })),
        []
    );

    const setDate = useCallback(
        (date: ChangeEvent<HTMLInputElement>) =>
            setCustomerOrder((customerOrder) => ({
                ...customerOrder,
                delivery_date: date.target.value,
            })),
        []
    );

    useEffect(() => {
        if (isEditMode) {
            setModalTitle(getText(TextIds.CustomerOrder.EDIT));
        } else {
            setModalTitle(getText(TextIds.CustomerOrder.ADD));
            getCustomerOrderDetailFormDiv();
        }

        setCustomerOrder({
            ...selectedCustomerOrder,
            internal_id: selectedCustomerOrder.internal_id,
            type: selectedCustomerOrder.type,
            delivery_date: selectedCustomerOrder.delivery_date,
            customer_id: selectedCustomerOrder.customer_id,
        });
        // eslint-disable-next-line
    }, [selectedCustomerOrder, isEditMode]);

    useEffect(() => {
        if (production.id === 0) return;

        getValidEndProducts(production.id, getText).then((value) => {
            setEndProducts(value.products);
        });
        // eslint-disable-next-line
    }, [production.id]);

    useEffect(() => {
        getProductCounter(selectedProductWithAmount);
        // eslint-disable-next-line
    }, [selectedProductWithAmount]);

    function getCustomerOrderDetailFormDiv() {
        const productAmountMap = new Map(
            selectedCustomerOrder.order_details.map((detail) => [detail.product_id, detail.quantity])
        );
        setSelectedProductWithAmount(productAmountMap);
        setProductMultiSelect(
            <MultiSelect
                label={getText(TextIds.Product.PLURAL_NAME)}
                required={true}
                options={endProducts}
                defaultValues={endProducts.filter((product) => productAmountMap.has(product.id))}
                onChange={(selected: Product[]) => addProductToCustomerOrder(selected, productAmountMap)}
                className="col-span-2"
                id={TestIds.PRODUCT_SELECT}
            />
        );
        getProductCounter(productAmountMap);
    }

    function addProductToCustomerOrder(selectedProducts: Product[], map: Map<number, number>): void {
        let newProductMap: Map<number, number> = new Map();

        selectedProducts.forEach((product) => {
            if (map.has(product.id)) {
                //@ts-ignore
                newProductMap.set(product.id, map.get(product.id));
            } else {
                newProductMap.set(product.id, 1);
            }
        });
        setSelectedProductWithAmount(newProductMap);
    }

    function getProductCounter(productAmountMap: Map<number, number>) {
        const productCounterList: JSX.Element[] = [];
        productAmountMap.forEach((amount, productId) => {
            productCounterList.push(
                <CounterBox
                    required={true}
                    key={productId}
                    id={productId}
                    initialCounter={amount}
                    labelText={endProducts.find((product) => product.id === productId)?.name}
                    setAmount={(amount: number) => {
                        setProductAmount(amount, productId);
                    }}
                />
            );
        });
        setProductCounter(productCounterList);
    }

    function setProductAmount(amount: number, productId: number): void {
        let newAmount = selectedProductWithAmount;
        newAmount.set(productId, amount);
        setSelectedProductWithAmount(newAmount);
    }

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        if (event === undefined) return;
        event.preventDefault();
        if (!isDataValid()) return;

        let customerOrderWithDetails = customerOrder;
        if (!isEditMode)
            selectedProductWithAmount.forEach((amount, productId) => {
                customerOrderWithDetails.order_details.push({
                    ...createEmptyCustomerOrderDetail(),
                    product_id: productId,
                    quantity: amount,
                });
            });

        const promise = () =>
            isEditMode
                ? putCustomerOrder(customerOrderWithDetails, customerOrder.id, getText)
                : postCustomerOrder(customerOrderWithDetails, getText);
        promise().then(() => {
            updateProduction(production.id);
            close();
        });
    }

    function isDataValid(): boolean {
        if (!namePattern.test(customerOrder.internal_id)) {
            toast.error(getText(TextIds.Form.NAME_ALLOWED_CHARACTER));
            return false;
        }
        if (isEditMode) return true;

        if (!datePattern.test(customerOrder.delivery_date)) {
            toast.error(getText(TextIds.Form.NOT_VALID_DATE));
            return false;
        }
        if (customerOrder.customer_id === 0) {
            toast.error(getText(TextIds.Customer.NO_SELECTION));
            return false;
        }
        if (selectedProductWithAmount.size === 0) {
            toast.error(getText(TextIds.Product.NO_SELECTION));
            return false;
        }

        return true;
    }

    function createFormInputs(): JSX.Element {
        if (isEditMode) return getInternalInput();

        return (
            <div className="grid grid-cols-2 gap-8">
                {getInternalInput()}
                <Input
                    labelText={getText(TextIds.Form.DELIVERY_DATE)}
                    name="deliveryDate"
                    value={customerOrder.delivery_date}
                    type="date"
                    placeholder={getText(TextIds.Form.DELIVERY_DATE)}
                    pattern={datePattern.source}
                    title={getText(TextIds.Form.DATE_INPUT_ALLOWED)}
                    maxLength={10}
                    onChange={setDate}
                    data-testid={TestIds.INPUT_DELIVERY_DATE}
                    required
                />
                <Select
                    label={getText(TextIds.Customer.NAME)}
                    options={production.customers}
                    required={true}
                    defaultValue={getById(customerOrder.customer_id, production.customers, createEmptyCustomer)}
                    onChange={setCustomerId}
                    id={TestIds.CUSTOMER_SELECT}
                />
                <hr className="col-span-2" />
                {productMultiSelect}
                <div className="col-span-2 mb-4 flex space-x-4" data-testid={TestIds.PRODUCT_COUNTER_BOX}>
                    {productCounter}
                </div>
            </div>
        );
    }

    function getInternalInput() {
        return (
            <Input
                labelText={getText(TextIds.Form.ID)}
                name="internal_id"
                value={customerOrder.internal_id}
                type="text"
                placeholder={getText(TextIds.Form.ID)}
                pattern={namePattern.source}
                title={getText(TextIds.Form.NAME_ALLOWED_CHARACTER)}
                maxLength={NAME_LENGTH}
                onChange={setInternalId}
                data-testid={TestIds.INPUT_INTERNAL}
                required
            />
        );
    }

    return (
        <Modal
            open={open}
            setOpen={(ding: boolean) => setOpen(ding)}
            title={modalTitle}
            description={getText(TextIds.CustomerOrder.FORM_SUBTITLE)}
        >
            <form onSubmit={(event) => handleSubmit(event)}>
                {createFormInputs()}
                <FormFooter
                    handleSubmitFunction={handleSubmit}
                    close={close}
                    deleteFunction={deleteFunction}
                    objectToDelete={selectedCustomerOrder}
                    isEditMode={selectedCustomerOrder.id > 0}
                    shownName={customerOrder.internal_id}
                />
            </form>
        </Modal>
    );
}
