import Button, { ButtonSize, ButtonTypes } from "./buttons/Button";
import React, { useState } from "react";
import ConfirmationDialog from "./ConfirmationDialog";
import { CheckCircleIcon, TrashIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { TestIds } from "../TestIds";
import { useText } from "../context/LanguageContext";
import TextIds from "../language/TextIds";

export interface FormFooterProps {
    handleSubmitFunction: Function;
    close: Function;
    deleteFunction: Function;
    objectToDelete?: any;
    shownName?: string;
    isEditMode: boolean;
}

export default function FormFooter({
    handleSubmitFunction,
    close,
    deleteFunction,
    objectToDelete = { internal_id: "", name: "", id: "" },
    shownName,
    isEditMode,
}: FormFooterProps) {
    const getText = useText();
    const [isOpenConfirmationDialog, setIsOpenConfirmationDialog] = useState<boolean>(false);

    return (
        <div className="grid grid-cols-3 pt-4" data-testid={TestIds.FORM_FOOTER}>
            <div className="col-span-1" data-testid={TestIds.DELETE_BUTTON}>
                {isEditMode && (
                    <Button
                        title={getText(TextIds.Form.DELETE)}
                        icon={<TrashIcon />}
                        buttonType={ButtonTypes.Cancel}
                        buttonSize={ButtonSize.small}
                        onClick={() => setIsOpenConfirmationDialog(true)}
                    />
                )}
            </div>
            <div className="col-span-2 grid grid-cols-2">
                <Button
                    title={getText(TextIds.Form.CANCEL)}
                    icon={<XCircleIcon />}
                    buttonType={ButtonTypes.Cancel}
                    buttonSize={ButtonSize.middle}
                    onClick={() => close()}
                />
                <Button
                    title={getText(TextIds.Form.SAVE)}
                    classNames="justify-self-end"
                    icon={<CheckCircleIcon />}
                    buttonType={ButtonTypes.Submit}
                    buttonSize={ButtonSize.middle}
                    onClick={(e: any) => handleSubmitFunction(e)}
                />
            </div>
            <ConfirmationDialog
                title={`${getText(TextIds.Form.DELETE)} ${shownName ? shownName : objectToDelete.name}`}
                submitFunction={deleteFunction}
                open={isOpenConfirmationDialog}
                setOpen={setIsOpenConfirmationDialog}
            />
        </div>
    );
}
