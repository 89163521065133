import { Node } from "reactflow";
import { createDottedEdge, createEdge, createFlowTree, createNode } from "./FlowUtils";
import ProductGroupNode from "../../components/flow/ProductGroupNode";
import { getById } from "../FilterUtils";
import React from "react";
import { Production } from "../../api/models/Production";
import { Line } from "../../api/models/Line";
import { NodesAndEdges } from "../../api/models/GeneralTypes";
import { Station } from "../../api/models/Station";
import { createEmptyProductGroup, ProductGroup } from "../../api/models/ProductGroup";

export function createFlowForLineModeling(
    production: Production,
    selectedLine: Line,
    isFullscreen?: boolean,
    setIsFullscreen?: Function,
    children?: JSX.Element
): JSX.Element {
    const nodesAndEdges = createNodesAndEdgesForLine(production, selectedLine);
    return createFlowTree(nodesAndEdges, selectedLine, isFullscreen, setIsFullscreen, children);
}

export function createNodesAndEdgesForLine(production: Production, selectedLine: Line): NodesAndEdges {
    let nodesAndEdges: NodesAndEdges = { nodes: [], edges: [] };
    for (let station of selectedLine.stations) {
        nodesAndEdges = createStationNodesAndEdges(station, production, selectedLine, nodesAndEdges);

        if (!station.product_group) {
            continue;
        }

        nodesAndEdges = createStationsInputNodesAndEdges(
            station,
            station.product_group,
            production,
            selectedLine,
            nodesAndEdges
        );
    }
    return nodesAndEdges;
}

function createStationNodesAndEdges(
    station: Station,
    production: Production,
    selectedLine: Line,
    nodesAndEdges: NodesAndEdges
): NodesAndEdges {
    nodesAndEdges.nodes.push(createStationNode(station, selectedLine));
    for (let child of station.successors) {
        nodesAndEdges.edges.push(createEdge(child.toString(), station.id.toString()));
    }

    if (station.successors.length !== 0 || !station.product_group) {
        return nodesAndEdges;
    }

    const productGroup = station.product_group;
    const edge = createDottedEdge("group" + productGroup.id, station.id.toString());
    const node = createNode(
        "group" + productGroup.id,
        <ProductGroupNode
            key={"input" + selectedLine.id + productGroup.name}
            productGroup={productGroup}
            quantity={1}
        />
    );

    nodesAndEdges.nodes.push(node);
    nodesAndEdges.edges.push(edge);

    return nodesAndEdges;
}

function createStationsInputNodesAndEdges(
    station: Station,
    productGroup: ProductGroup,
    production: Production,
    selectedLine: Line,
    nodesAndEdges: NodesAndEdges
): NodesAndEdges {
    for (let preProductGroupIdQuantity of productGroup.pre_product_groups) {
        let preProductGroup: ProductGroup = getById(
            preProductGroupIdQuantity.pre_product_group_id,
            production.product_groups,
            createEmptyProductGroup
        );

        const edge = createDottedEdge(station.id.toString(), "group" + preProductGroup.id);
        const node = createNode(
            "group" + preProductGroup.id,
            <ProductGroupNode
                key={"input" + selectedLine.id + preProductGroup.name}
                productGroup={preProductGroup}
                quantity={preProductGroupIdQuantity.quantity}
            />
        );

        nodesAndEdges.nodes.push(node);
        nodesAndEdges.edges.push(edge);
    }

    return nodesAndEdges;
}

export function createStationNode(station: Station, line: Line): Node {
    return {
        id: station.id.toString(),
        data: {
            station: station,
            line: line,
        },
        type: "stationNode",
        position: { x: 0, y: 0 },
    };
}
