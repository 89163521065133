import React, { useEffect, useState } from "react";
import Pagination from "../../components/Pagination";
import CustomerOrderDetailTable from "./CustomerOrderDetailTable";
import { useProduction } from "../../context/ProductionContext";
import { createEmptyCustomerOrder, CustomerOrder, filterCustomerOrderList } from "../../api/models/CustomerOrder";
import { createEmptyCustomer } from "../../api/models/Customer";
import { getById } from "../../utils/FilterUtils";
import { TestIds } from "../../TestIds";
import CustomerOrderForm from "../../components/forms/CustomerOrderForm";
import EditButton from "../../components/buttons/EditButton";
import { getDeliveryMarble } from "../../utils/StatusMarbleUtils";
import Table from "../../components/Table";
import { createExpanderChevron, tableBodyClass, tableRowClass } from "../../utils/TableUtils";
import { useText } from "../../context/LanguageContext";
import TextIds from "../../language/TextIds";

interface CustomerOrderProps {
    customerOrders: CustomerOrder[];
    filterInput: string;
}

export default function CustomerOrderTable({ customerOrders, filterInput }: CustomerOrderProps): JSX.Element {
    const getText = useText();
    const customers = useProduction()?.customers;

    const [expandedCustomerOrderId, setExpandedCustomerOrderId] = useState(0);
    const [shownCustomerOrders, setShownCustomerOrders] = useState<CustomerOrder[]>([]);
    const [sortedCustomerOrders, setSortedCustomerOrders] = useState<CustomerOrder[]>([]);
    const [filteredCustomerOrders, setFilteredCustomerOrders] = useState<CustomerOrder[]>([]);

    const [isCustomerOrderFormOpen, setIsCustomerOrderFormOpen] = useState(false);
    const [selectedOrderToEdit, setSelectedOrderToEdit] = useState<CustomerOrder>(createEmptyCustomerOrder());

    useEffect(() => {
        const filteredList = filterCustomerOrderList(filterInput, customerOrders, customers, getText);
        setFilteredCustomerOrders(filteredList);
    }, [setFilteredCustomerOrders, customerOrders, customers, filterInput, getText]);

    function setOrderToExpand(order: CustomerOrder): void {
        if (order.order_details.length > 0)
            if (expandedCustomerOrderId !== order.id) setExpandedCustomerOrderId(order.id);
            else setExpandedCustomerOrderId(0);
    }

    const tableHeader = {
        className: "bg-green-middle text-white",
        columns: [
            {
                headerTitle: getText(TextIds.CustomerOrder.NAME),
                sortingValue: ["internal_id"],
                className: "lg:min-w-64",
            },
            { headerTitle: getText(TextIds.Customer.NAME), sortingValue: ["customer_id"], className: "lg:min-w-64" },
            { headerTitle: getText(TextIds.Status.STATUS), sortingValue: ["status"], className: "md:min-w-32" },
            {
                headerTitle: getText(TextIds.Form.DELIVERY_DATE),
                sortingValue: ["delivery_date"],
                className: "md:min-w-32",
            },
            {
                headerTitle: getText(TextIds.Status.DELIVERY_STATUS),
                sortingValue: ["delivery_status"],
                className: "md:min-w-32",
            },
        ],
    };

    function createTableBody(order: CustomerOrder) {
        const expandOrder = () => setOrderToExpand(order);

        return (
            <tr
                className={tableRowClass + " cursor-pointer"}
                onClick={expandOrder}
                data-testid={TestIds.CUSTOMER_ORDER_ROW + order.id}
            >
                <td>{createExpanderChevron(order, expandedCustomerOrderId, order.order_details, expandOrder)}</td>
                <td>{order.internal_id}</td>
                <td>{getById(order.customer_id, customers, createEmptyCustomer).name}</td>
                <td>{getText(order.status)}</td>
                <td>{order.delivery_date.substring(0, 10)}</td>
                <td className="pl-4">{getDeliveryMarble(order.delivery_status)}</td>
                <td>
                    <EditButton
                        handleClick={setSelectedOrderToEdit}
                        openForm={setIsCustomerOrderFormOpen}
                        item={order}
                    />
                </td>
            </tr>
        );
    }

    function createExpandedTable(order: CustomerOrder) {
        if (order.id === expandedCustomerOrderId)
            return (
                <tr key={"details" + order.id}>
                    <td className="px-4" colSpan={7}>
                        <CustomerOrderDetailTable orderDetails={order.order_details} />
                    </td>
                </tr>
            );
    }

    return (
        <div className="grow">
            <div className="h-[70vh] overflow-y-auto sm:overflow-x-hidden">
                <Table
                    setSortedElements={setSortedCustomerOrders}
                    elements={filteredCustomerOrders}
                    header={tableHeader}
                    isEditable={true}
                    isExpanding={true}
                    id={TestIds.CUSTOMER_ORDER_TABLE}
                >
                    {shownCustomerOrders.map((order) => {
                        return (
                            <tbody className={tableBodyClass} key={order.id}>
                                {createTableBody(order)}
                                {createExpandedTable(order)}
                            </tbody>
                        );
                    })}
                </Table>
            </div>
            <Pagination items={sortedCustomerOrders} setShownItems={setShownCustomerOrders} />

            <CustomerOrderForm
                selectedCustomerOrder={selectedOrderToEdit}
                setOpen={setIsCustomerOrderFormOpen}
                open={isCustomerOrderFormOpen}
            />
        </div>
    );
}
