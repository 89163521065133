import React, { useCallback, useEffect, useState } from "react";
import Label from "../Label";
import { TestIds } from "../../TestIds";
import { SelectBoxOption } from "../../api/models/GeneralTypes";
import { useText } from "../../context/LanguageContext";

interface RadioButtonProps {
    options: SelectBoxOption[];
    defaultRadio: any;
    label?: string;
    required?: boolean;
    className?: string;
    setChecked: Function;
}

export default function RadioButtonGroup({
    options,
    setChecked,
    label,
    defaultRadio,
    required = false,
    className,
}: RadioButtonProps) {
    let [checkedRadio, setCheckedRadio] = useState<SelectBoxOption>(defaultRadio);

    useEffect(() => {
        setChecked(checkedRadio);
    }, [checkedRadio]);

    return (
        <div className={className}>
            {label && (
                <Label>
                    {label}
                    {required && <span className="text-red-600">*</span>}
                </Label>
            )}
            <div className="flex" data-testid={TestIds.RADIO_BUTTON_GROUP}>
                {options.map((option) => (
                    <RadioButtonOption
                        key={option.id}
                        option={option}
                        handleClick={setCheckedRadio}
                        checked={checkedRadio.id === option.id}
                    />
                ))}
            </div>
        </div>
    );
}

interface RadioButtonOptionProps {
    handleClick: Function;
    option: SelectBoxOption;
    checked: boolean;
}

function RadioButtonOption({ handleClick, option, checked }: RadioButtonOptionProps): JSX.Element {
    const getText = useText();
    const onClick = useCallback(() => handleClick(option), [option, handleClick]);

    return (
        <div
            key={option.id}
            onClick={onClick}
            data-testid={option.id}
            className={`font-xs min-w-fit mr-4 flex h-9 cursor-pointer select-none items-center justify-center rounded-lg border border-gray-light px-2 text-gray-middle
                        ${checked && "border-none bg-green-middle text-white"}`}
        >
            {getText(option.name)}
        </div>
    );
}
