import { useState } from "react";
import jwtDecode from "jwt-decode";
import { NavigateFunction } from "react-router-dom";
import logout from "../api/calls/Logout";

function checkExp(exp: number): boolean {
    if (!Date.now) {
        Date.now = function () {
            return new Date().getTime();
        };
    }
    return Date.now() / 1000 > exp;
}

export function getToken() {
    return {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_token"),
        },
    };
}

function useToken() {
    function loadToken() {
        const userToken = localStorage.getItem("jwt_token");
        if (userToken) {
            const decoded: any = jwtDecode(userToken);
            if (checkExp(decoded.exp)) {
                localStorage.removeItem("jwt_token");
                return;
            }
            return userToken;
        }
    }

    const [token, setToken] = useState<string | undefined>(loadToken());

    function saveToken(userToken: string) {
        localStorage.setItem("jwt_token", userToken);
        setToken(userToken);
    }

    function removeToken(navigate: NavigateFunction) {
        logout().finally(() => {
            localStorage.removeItem("jwt_token");
            setToken(undefined);
            navigate("/");
        });
    }

    return {
        setToken: saveToken,
        token,
        removeToken,
    };
}

export default useToken;
