import { Workday } from "../../../api/models/Workday";
import { useText } from "../../../context/LanguageContext";
import React, { useCallback, useEffect, useState } from "react";
import { TestIds } from "../../../TestIds";
import Checkbox from "../../form-inputs/Checkbox";
import TextIds from "../../../language/TextIds";
import TimeInput from "../../form-inputs/TimeInput";

interface WorkdayInputProps {
    selectedWorkday: Workday;
    setSelectedWorkday: Function;
}

export default function WorkdayInput({ selectedWorkday, setSelectedWorkday }: WorkdayInputProps): JSX.Element {
    const getText = useText();
    const [workday, setWorkday] = useState(selectedWorkday);

    const setStart = useCallback(
        (start: string) =>
            setWorkday((workday: Workday) => ({
                ...workday,
                start: start,
            })),
        []
    );

    const setEnd = useCallback((end: string) => {
        setWorkday((workday: Workday) => ({
            ...workday,
            end: end,
        }));
    }, []);

    const setEnabled = useCallback((enabled: boolean) => {
        setWorkday((workday: Workday) => ({
            ...workday,
            enabled: enabled,
        }));
    }, []);

    useEffect(() => {
        setSelectedWorkday(workday);
    }, [workday, setSelectedWorkday]);

    return (
        <div className="flex h-10 items-center justify-between" data-testid={TestIds.INPUT_WORKDAY + workday.day}>
            <Checkbox
                checked={workday.enabled}
                setChecked={setEnabled}
                label={getText(TextIds.Days[workday.day as keyof TextIds])}
            />
            <div className="flex items-center space-x-4">
                <TimeInput
                    value={workday.start}
                    disabled={!workday.enabled}
                    setTime={setStart}
                    name="startHour"
                    placeholder={getText(TextIds.Form.START_HOUR)}
                    title={getText(TextIds.Form.TIME_FORMAT_ALLOWED)}
                    required
                    data-testid={TestIds.INPUT_START}
                />
                <p>{getText(TextIds.TO)}</p>
                <TimeInput
                    value={workday.end}
                    disabled={!workday.enabled}
                    setTime={setEnd}
                    name="endHour"
                    placeholder={getText(TextIds.Form.END_HOUR)}
                    title={getText(TextIds.Form.TIME_FORMAT_ALLOWED)}
                    required
                    data-testid={TestIds.INPUT_END}
                />
            </div>
        </div>
    );
}
