import React, { useCallback, useEffect, useState } from "react";
import ProductGroupModeling from "./ProductGroupModeling";
import BillOfMaterialModeling from "./BillOfMaterialModeling";
import { Product } from "../../../api/models/Product";
import { SelectBoxOption } from "../../../api/models/GeneralTypes";
import { GRAY_DARK, SCHEDULE_BLUE_LIGHT, SCHEDULE_GREEN } from "../../../colors";
import { ProductGroup } from "../../../api/models/ProductGroup";
import { useProductGroups } from "../../../context/ProductGroupContext";
import { useProducts } from "../../../context/ProductContext";
import SwitchButton from "../../../components/SwitchButton";
import { TestIds } from "../../../TestIds";
import { useProduction } from "../../../context/ProductionContext";
import { useText } from "../../../context/LanguageContext";
import TextIds from "../../../language/TextIds";

type SelectedProductType = {
    id: number;
    name: string;
    tabColor: string;
    switchColor: string;
    productGroupList: ProductGroup[];
    productList: Product[];
};

export default function ProductModeling(): JSX.Element {
    const getText = useText();
    let production = useProduction();
    const { preProductGroups, endProductGroups } = useProductGroups();
    const { preProducts, endProducts } = useProducts();
    const options = [
        {
            id: 1,
            name: TextIds.Status.PRE,
            tabColor: SCHEDULE_BLUE_LIGHT,
            switchColor: GRAY_DARK,
            productGroupList: preProductGroups,
            productList: preProducts,
        },
        {
            id: 2,
            name: TextIds.Status.END,
            tabColor: SCHEDULE_GREEN,
            switchColor: GRAY_DARK,
            productGroupList: endProductGroups,
            productList: endProducts,
        },
    ];

    const [selectedProductGroupOptions, setSelectedProductGroupOptions] = useState<SelectedProductType[]>(options);
    const [selectedProductType, setSelectedProductType] = useState<SelectedProductType>(selectedProductGroupOptions[0]);

    const viewSelectOptions: SelectBoxOption[] = [
        {
            id: 1,
            name: TextIds.GROUP,
            switchColor: GRAY_DARK,
        },
        {
            id: 2,
            name: TextIds.Navigation.BOM,
            switchColor: GRAY_DARK,
        },
    ];

    const [selectedView, setSelectedView] = useState<SelectBoxOption>(viewSelectOptions[0]);

    useEffect(() => {
        setSelectedProductGroupOptions([...options]);
        setSelectedProductType(options[selectedProductType.id - 1]);
        // eslint-disable-next-line
    }, [production, selectedView, selectedProductType.id]);

    const viewSelect = (
        <div>
            <div className="flex justify-center font-semibold">{getText(TextIds.Navigation.VIEW_MODE)}</div>
            <SwitchButton
                key="switch-view"
                options={viewSelectOptions}
                selectedElement={selectedView}
                setSelectedElement={setSelectedView}
                id={TestIds.PRODUCT_MODELING_SWITCH}
            />
        </div>
    );

    const getSelectedView = useCallback(() => {
        if (selectedView.id === 1)
            return (
                <ProductGroupModeling
                    selectableProductGroups={selectedProductType.productGroupList}
                    tabColor={selectedProductType.tabColor}
                />
            );

        return (
            <BillOfMaterialModeling
                selectableProducts={selectedProductType.productList}
                tabColor={selectedProductType.tabColor}
            />
        );
    }, [selectedView.id, selectedProductType]);

    return (
        <>
            <div className="mb-4 flex flex-row justify-between bg-white">
                <div className="flex space-x-2">
                    <div>
                        <div className="flex justify-center font-semibold">{getText(TextIds.Form.TYPE)}</div>
                        <SwitchButton
                            key="switch-type"
                            options={selectedProductGroupOptions}
                            selectedElement={selectedProductType}
                            setSelectedElement={setSelectedProductType}
                            id={TestIds.PRE_END_SWITCH}
                        />
                    </div>
                </div>
                {viewSelect}
            </div>
            {getSelectedView()}
        </>
    );
}
