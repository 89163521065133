import React from "react";

interface LabelProps {
    className?: string;
    children?: any;
}

export default function Label({ className, children }: LabelProps) {
    return <label className={"block font-semibold text-gray-middle" + " " + className}>{children}</label>;
}
