import axios from "axios";
import { BASE_URL, PRODUCTION_ORDERS_ENDPOINT } from "../ApiConfig";
import { getToken } from "../../hook/UseToken";
import { handleApiGetCall } from "../../utils/ApiUtils";
import { Product } from "../models/Product";
import { ProductionOrder } from "../models/ProductionOrder";

const productionOrders = "ProductionOrders";
const endpoint = BASE_URL + PRODUCTION_ORDERS_ENDPOINT;

type ProductionOrders = {
    productionOrders: Product[];
};

export async function getProductionOrders(production_id: number, getText: Function): Promise<ProductionOrder[]> {
    const response = axios.get(endpoint + "/" + production_id, getToken());
    return handleApiGetCall(response, productionOrders, production_id, getText);
}
