import React, { useState } from "react";
import ProductGroupForm from "../forms/ProductGroupForm";
import { ProductGroup, ProductType } from "../../api/models/ProductGroup";
import PreProductIcon from "../icons/PreProductIcon.svg";
import EndProductIcon from "../icons/EndProductIcon.svg";
import InnerNode from "./InnerNode";
import { TestIds } from "../../TestIds";

export interface ProductGroupNodeProps {
    productGroup: ProductGroup;
    addButton?: boolean;
    quantity?: number;
}

export default function ProductGroupNode({
    productGroup,
    quantity = 0,
    addButton = false,
}: ProductGroupNodeProps): JSX.Element {
    const [isProductGroupFormOpen, setIsProductGroupFormOpen] = useState<boolean>(false);
    const isPreProduct = productGroup.product_type === ProductType.PRE;
    const testIdButton = isPreProduct ? TestIds.ADD_PRE_PRODUCT_GROUP_BUTTON : TestIds.ADD_END_PRODUCT_GROUP_BUTTON;
    const testId = productGroup.id !== 0 ? TestIds.PRODUCT_GROUP_NODE + productGroup.id : testIdButton;
    const className = isPreProduct ? "bg-schedule-blue-light" : "bg-status-green";
    const icon = isPreProduct ? PreProductIcon : EndProductIcon;
    return (
        <>
            <InnerNode
                className={className}
                name={productGroup.name}
                icon={icon}
                openForm={() => setIsProductGroupFormOpen(true)}
                addButton={addButton}
                quantity={quantity}
                id={testId}
            />
            <ProductGroupForm
                selectedProductGroup={productGroup}
                setOpen={setIsProductGroupFormOpen}
                open={isProductGroupFormOpen}
            />
        </>
    );
}
