import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { ProductionListElement } from "../api/models/Production";
import { getUserProductions } from "../api/calls/UserProductions";
import { useText } from "./LanguageContext";

const ProductionsContext = createContext<ProductionListElement[]>([]);
const ProductionsUpdateContext = createContext<Function>(() => "");

export function useProductions() {
    return useContext(ProductionsContext);
}

export function useProductionsUpdate() {
    return useContext(ProductionsUpdateContext);
}

export default function ProductionsProvider({ children }: any) {
    const getText = useText();
    const [productions, setProductions] = useState<ProductionListElement[]>([]);

    const updateProductions = useCallback(() => {
        getUserProductions(getText).then((productions) => {
            setProductions(productions);
        });
    }, [setProductions, getText]);

    useEffect(() => updateProductions(), [updateProductions]);

    return (
        <ProductionsContext.Provider value={productions}>
            <ProductionsUpdateContext.Provider value={updateProductions}>{children}</ProductionsUpdateContext.Provider>
        </ProductionsContext.Provider>
    );
}
