import { ExternalMaterial } from "./ExternalMaterial";

export const ExtMaterialType = { RAW_MATERIAL: "RAW_MATERIAL", EXTERNAL_PRODUCT: "EXTERNAL_PRODUCT" } as const;

export interface ExternalMaterialGroup {
    id: number;
    name: string;
    external_materials: ExternalMaterial[];
    contained_id: number[];
    production_id: number;
}

export function createEmptyExtMaterialGroup(): ExternalMaterialGroup {
    return {
        id: 0,
        name: "",
        external_materials: [],
        contained_id: [],
        production_id: 0,
    };
}

export function getExtMaterialsOfGroups(extMaterialGroups: ExternalMaterialGroup[]) {
    let extMaterials: ExternalMaterial[] = [];
    extMaterialGroups.forEach((extMaterialGroup) => extMaterials.push(...extMaterialGroup.external_materials));
    return extMaterials;
}
