import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import Select from "./form-inputs/Select";
import { isWindowHeightLarge } from "../utils/WindowUtils";
import { useText } from "../context/LanguageContext";
import TextIds from "../language/TextIds";

export interface PaginationProps {
    items: any[];
    setShownItems: Function;
    className?: string;
}

type TableRows = { id: number; name: string; quantity: number };

export default function Pagination({ items, setShownItems, className }: PaginationProps): JSX.Element {
    const getText = useText();
    const [pages, setPages] = useState([1]);
    const [selectedPage, setSelectedPage] = useState(1);

    const selectableRows: TableRows[] = [
        { id: 1, name: "15", quantity: 15 },
        { id: 2, name: "30", quantity: 30 },
        { id: 3, name: "60", quantity: 60 },
        { id: 4, name: "120", quantity: 120 },
    ];
    const [shownRows, setShownRows] = useState(isWindowHeightLarge() ? selectableRows[1] : selectableRows[0]);

    useEffect(() => {
        let pageArray = [];
        for (let i = 0; i < items.length; i = i + shownRows.quantity) pageArray.push(pageArray.length + 1);
        setPages([...pageArray]);
        if (pageArray.length !== 0 && selectedPage > pageArray.length) {
            setSelectedPage(pageArray.length);
            setShownItems([
                ...items.slice(
                    (pageArray.length - 1) * shownRows.quantity,
                    (pageArray.length - 1) * shownRows.quantity + shownRows.quantity
                ),
            ]);
        } else
            setShownItems([
                ...items.slice(
                    (selectedPage - 1) * shownRows.quantity,
                    (selectedPage - 1) * shownRows.quantity + shownRows.quantity
                ),
            ]);
    }, [items, shownRows]);

    function setSelectedPageNumber(pageNumber: number): void {
        setSelectedPage(pageNumber);
        setShownItems([
            ...items.slice(
                (pageNumber - 1) * shownRows.quantity,
                (pageNumber - 1) * shownRows.quantity + shownRows.quantity
            ),
        ]);
    }

    function getPaginationDiv(pageNumber: number): JSX.Element {
        const pageClassName =
            "flex h-6 w-6 cursor-pointer items-center justify-center rounded-full hover:bg-gray-very-light mx-2" +
            (pageNumber === selectedPage ? " bg-green-middle text-white" : "");

        if (pages.length > 7) {
            if (selectedPage > pages.length - 4) return getEndPagination(pageNumber, pageClassName);
            else if (selectedPage <= 4) return getStartPagination(pageNumber, pageClassName);
            else return getMiddleOfPagination(pageNumber, pageClassName);
        }
        return getPageNumberDiv(pageNumber, pageClassName);
    }

    function getStartPagination(pageNumber: number, pageClassName: string): JSX.Element {
        if (
            pageNumber <= 3 ||
            (selectedPage === 3 && pageNumber === 4) ||
            (selectedPage === 4 && (pageNumber === 4 || pageNumber === 5)) ||
            pageNumber === pages.length
        ) {
            return getPageNumberDiv(pageNumber, pageClassName);
        } else if (pageNumber === 6) return getBetweenPageNumberDiv(pageNumber);
        return <></>;
    }

    function getMiddleOfPagination(pageNumber: number, pageClassName: string): JSX.Element {
        if (
            pageNumber === 1 ||
            pageNumber === pages.length ||
            (pageNumber >= selectedPage - 1 && pageNumber <= selectedPage + 1)
        ) {
            return getPageNumberDiv(pageNumber, pageClassName);
        } else if (pageNumber === selectedPage - 2 || pageNumber === selectedPage + 2)
            return getBetweenPageNumberDiv(pageNumber);
        return <></>;
    }

    function getEndPagination(pageNumber: number, pageClassName: string): JSX.Element {
        if (
            pageNumber >= pages.length - 2 ||
            (selectedPage === pages.length - 2 && pageNumber === pages.length - 3) ||
            (selectedPage === pages.length - 3 &&
                (pageNumber === pages.length - 4 || pageNumber === pages.length - 5)) ||
            pageNumber === 1
        ) {
            return getPageNumberDiv(pageNumber, pageClassName);
        } else if (pageNumber === pages.length - 5) return getBetweenPageNumberDiv(pageNumber);
        return <></>;
    }

    function getPageNumberDiv(pageNumber: number, pageClassName: string): JSX.Element {
        return (
            <div key={pageNumber} className={pageClassName} onClick={() => setSelectedPageNumber(pageNumber)}>
                {pageNumber}
            </div>
        );
    }

    function getBetweenPageNumberDiv(pageNumber: number) {
        return (
            <div
                key={pageNumber}
                className="flex h-5 w-5 items-center justify-center rounded-full"
                onClick={() => setSelectedPageNumber(pageNumber)}
            >
                ...
            </div>
        );
    }

    return (
        <div className={`flex select-none items-center justify-between text-xs ${className}`}>
            <div className="flex basis-1/3 items-center gap-2">
                <div
                    className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border border-black hover:bg-gray-very-light"
                    onClick={() => setSelectedPageNumber(selectedPage === 1 ? 1 : selectedPage - 1)}
                >
                    <ChevronLeftIcon className="w-3" />
                </div>
                <div className="min-w-fit flex">{pages.map((pageNumber) => getPaginationDiv(pageNumber))}</div>
                <div
                    className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border border-black hover:bg-gray-very-light"
                    onClick={() =>
                        setSelectedPageNumber(selectedPage === pages.length ? pages.length : selectedPage + 1)
                    }
                >
                    <ChevronRightIcon className="w-3" />
                </div>
            </div>
            <div className="flex basis-1/3 items-center justify-center gap-4">
                {`${getText(TextIds.TOTAL)} ${items.length} ${getText(TextIds.ENTRIES)}`}
            </div>
            <div className="flex basis-1/3 items-center justify-end gap-4">
                {getText(TextIds.SHOW)}
                <Select
                    className="w-24"
                    options={selectableRows}
                    defaultValue={shownRows}
                    onChange={(rows: any) => setShownRows(rows)}
                />
                {getText(TextIds.ENTRIES)}
            </div>
        </div>
    );
}
