import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { namePattern } from "../../utils/FormUtils";
import { toast } from "react-toastify";
import { useProduction, useProductionUpdate } from "../../context/ProductionContext";
import Modal from "../Modal";
import Input from "../form-inputs/Input";
import FormFooter from "../FormFooter";
import RadioButtonGroup from "../form-inputs/RadioButtonGroup";
import Select from "../form-inputs/Select";
import MultiSelect from "../form-inputs/MultiSelect";
import CounterBox from "../form-inputs/CounterBox";
import { findObjectByFieldInList, getById, getIndex, getValuesFilteredByList } from "../../utils/FilterUtils";
import { useProductGroups } from "../../context/ProductGroupContext";
import { createEmptyProductGroup, ProductGroup, ProductType } from "../../api/models/ProductGroup";
import { createEmptyStation, Station } from "../../api/models/Station";
import { deleteProductGroup, postProductGroup, putProductGroup } from "../../api/calls/ProductGroup";
import { createEmptyExtMaterialGroup, ExternalMaterialGroup } from "../../api/models/ExternalMaterialGroup";
import { TestIds } from "../../TestIds";
import { useText } from "../../context/LanguageContext";
import TextIds from "../../language/TextIds";
import { SelectBoxOption } from "../../api/models/GeneralTypes";
import { NAME_LENGTH } from "../../constants";

export interface ProductGroupFormProps {
    selectedProductGroup: ProductGroup;
    setOpen: Function;
    open: boolean;
}

export default function ProductGroupForm({ selectedProductGroup, setOpen, open }: ProductGroupFormProps) {
    const getText = useText();
    const production = useProduction();
    const updateProduction = useProductionUpdate();

    const [productGroup, setProductGroup] = useState<ProductGroup>(createEmptyProductGroup());
    const [label, setLabel] = useState("");

    const preProductGroups = useProductGroups().preProductGroups;
    const [stations, setStations] = useState<Station[]>([]);

    const close = () => setOpen(false);
    const deleteFunction = () =>
        deleteProductGroup(productGroup.id, getText).then(() => {
            updateProduction(production.id);
            close();
        });

    const productTypeOptions: SelectBoxOption[] = [
        { id: 1, name: ProductType.PRE },
        { id: 2, name: ProductType.END },
    ];

    const setName = useCallback(
        (event: ChangeEvent<HTMLInputElement>) =>
            setProductGroup((productGroup) => ({
                ...productGroup,
                name: event.target.value,
            })),
        []
    );

    const setProductType = useCallback(
        (typeOption: SelectBoxOption) =>
            setProductGroup((productGroup) => ({
                ...productGroup,
                product_type: typeOption.name,
            })),
        []
    );
    const setStation = useCallback(
        (station: Station) =>
            setProductGroup((productGroup) => ({
                ...productGroup,
                station_id: station.id,
            })),
        []
    );

    function isDataValid() {
        if (!namePattern.test(productGroup.name)) {
            toast.error(getText(TextIds.Form.NAME_ALLOWED_CHARACTER));
            return;
        }
        return true;
    }

    function handleSubmit(event: any) {
        if (event !== undefined) {
            event.preventDefault();
            if (!isDataValid()) return;

            const promise = () =>
                selectedProductGroup.id > 0
                    ? putProductGroup(productGroup, productGroup.id, getText)
                    : postProductGroup(productGroup, getText);
            promise().then(() => {
                close();
                updateProduction(production.id);
            });
        }
    }

    useEffect(() => {
        if (selectedProductGroup.id > 0) {
            setLabel(getText(TextIds.ProductGroup.EDIT));
            setProductGroup({
                ...selectedProductGroup,
            });
        } else {
            setLabel(getText(TextIds.ProductGroup.ADD));
            setProductGroup({
                ...selectedProductGroup,
                name: "",
                production_id: production.id,
                product_type: selectedProductGroup.product_type,
            });
        }
        filterStations();
        // eslint-disable-next-line
    }, [selectedProductGroup, production.id, getText]);

    function setPreProductGroupWithQuantity(productGroups: ProductGroup[]) {
        let groupList = [];
        for (let proGroup of productGroups) {
            let index = getIndex(proGroup.id, productGroup.pre_product_groups, "pre_product_group_id");
            if (index < 0) groupList.push({ pre_product_group_id: proGroup.id, quantity: 1 });
            else
                groupList.push({
                    pre_product_group_id: proGroup.id,
                    quantity: productGroup.pre_product_groups[index].quantity,
                });
        }
        setProductGroup({ ...productGroup, pre_product_groups: groupList });
    }

    function setExtMaterialGroupWithQuantity(extMaterialGroups: ExternalMaterialGroup[]) {
        let extMaterialList = [];
        for (let extMatGroup of extMaterialGroups) {
            let index = getIndex(extMatGroup.id, productGroup.external_material_groups, "external_material_group_id");

            if (index < 0) extMaterialList.push({ external_material_group_id: extMatGroup.id, quantity: 1 });
            else
                extMaterialList.push({
                    external_material_group_id: extMatGroup.id,
                    quantity: productGroup.external_material_groups[index].quantity,
                });
        }
        setProductGroup({ ...productGroup, external_material_groups: extMaterialList });
    }

    function createProductCounter() {
        const productCounterList = [];
        for (let preProduct of productGroup.pre_product_groups) {
            let index = getIndex(
                preProduct.pre_product_group_id,
                productGroup.pre_product_groups,
                "pre_product_group_id"
            );
            productCounterList.push(
                <CounterBox
                    required={true}
                    key={preProduct.pre_product_group_id}
                    id={preProduct.pre_product_group_id}
                    initialCounter={productGroup.pre_product_groups[index].quantity}
                    labelText={
                        getById(preProduct.pre_product_group_id, production.product_groups, createEmptyProductGroup)
                            .name
                    }
                    setAmount={(amount: number) => {
                        productGroup.pre_product_groups[index].quantity = amount;
                        setProductGroup({ ...productGroup, pre_product_groups: productGroup.pre_product_groups });
                    }}
                />
            );
        }
        return productCounterList;
    }

    function createMaterialCounter() {
        const materialCounterList = [];
        for (let extMaterial of productGroup.external_material_groups) {
            let index = getIndex(
                extMaterial.external_material_group_id,
                productGroup.external_material_groups,
                "external_material_group_id"
            );
            materialCounterList.push(
                <CounterBox
                    required={true}
                    key={extMaterial.external_material_group_id}
                    id={extMaterial.external_material_group_id}
                    initialCounter={productGroup.external_material_groups[index].quantity}
                    labelText={
                        getById(
                            extMaterial.external_material_group_id,
                            production.external_material_groups,
                            createEmptyExtMaterialGroup
                        ).name
                    }
                    setAmount={(amount: number) => {
                        productGroup.external_material_groups[index].quantity = amount;
                        setProductGroup({
                            ...productGroup,
                            external_material_groups: productGroup.external_material_groups,
                        });
                    }}
                />
            );
        }
        return materialCounterList;
    }

    function getStations(): Station[] {
        let stations: Station[] = [];
        production.lines.forEach((line) => stations.push(...line.stations));
        return stations;
    }

    function filterStations(): void {
        const filteredStationsOfGroup = getStations().filter(
            (station) => !station.product_group || station.product_group.id === 0
        );
        const selectedStation = getById(selectedProductGroup.station_id, getStations(), createEmptyStation);

        if (selectedStation.id !== 0) {
            setStations([selectedStation, ...filteredStationsOfGroup]);
            return;
        }
        setStations([...filteredStationsOfGroup]);
    }

    return (
        <Modal open={open} setOpen={setOpen} title={label} description={getText(TextIds.ProductGroup.FORM_SUBTITLE)}>
            <form className="flex flex-col space-y-4 divide-y divide-solid" onSubmit={(event) => handleSubmit(event)}>
                <div className="space-y-4">
                    <Input
                        labelText={getText(TextIds.Form.NAME)}
                        name="name"
                        value={productGroup.name}
                        type="text"
                        placeholder={getText(TextIds.Form.NAME)}
                        pattern={namePattern.source}
                        title={getText(TextIds.Form.NAME_ALLOWED_CHARACTER)}
                        maxLength={NAME_LENGTH}
                        onChange={setName}
                        required
                        data-testid={TestIds.INPUT_NAME}
                    />
                    <RadioButtonGroup
                        options={productTypeOptions}
                        defaultRadio={findObjectByFieldInList(productGroup.product_type, "name", productTypeOptions)}
                        setChecked={setProductType}
                        required={true}
                        label={getText(TextIds.Form.TYPE)}
                    />
                    <Select
                        label={getText(TextIds.Station.NAME)}
                        options={stations}
                        defaultValue={getById(productGroup.station_id, getStations(), createEmptyStation)}
                        onChange={setStation}
                        id={TestIds.STATION_SELECT}
                    />
                    <div className="grid grid-cols-2 gap-4">
                        <MultiSelect
                            label={getText(TextIds.ProductGroup.PRE)}
                            options={preProductGroups.filter(
                                (value) =>
                                    value.id !== productGroup.id &&
                                    getIndex(productGroup.id, value.pre_product_groups, "pre_product_group_id") < 0
                            )}
                            defaultValues={getValuesFilteredByList(
                                productGroup.pre_product_groups,
                                production.product_groups,
                                "pre_product_group_id"
                            )}
                            onChange={setPreProductGroupWithQuantity}
                            id={TestIds.PRODUCT_GROUP_SELECT}
                        />
                        <div
                            className="grid grid-cols-2 place-items-center gap-2"
                            data-testid={TestIds.PRODUCT_COUNTER_BOX}
                        >
                            {createProductCounter()}
                        </div>
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                        <MultiSelect
                            label={getText(TextIds.ExternalMaterialGroup.PLURAL_NAME)}
                            options={production.external_material_groups}
                            defaultValues={getValuesFilteredByList(
                                productGroup.external_material_groups,
                                production.external_material_groups,
                                "external_material_group_id"
                            )}
                            onChange={setExtMaterialGroupWithQuantity}
                            id={TestIds.EXT_MATERIAL_GROUP_SELECT}
                        />
                        <div
                            className="grid grid-cols-2 place-items-center gap-2"
                            data-testid={TestIds.EXT_MATERIAL_COUNTER_BOX}
                        >
                            {createMaterialCounter()}
                        </div>
                    </div>
                </div>
                <FormFooter
                    handleSubmitFunction={handleSubmit}
                    close={close}
                    deleteFunction={deleteFunction}
                    objectToDelete={selectedProductGroup}
                    isEditMode={selectedProductGroup.id > 0}
                />
            </form>
        </Modal>
    );
}
