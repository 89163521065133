import { GRAY_LIGHT } from "../../../../colors";
import { GeneralObjectType, PopUpInfo, TimelineElement } from "../../../../api/models/GeneralTypes";
import { ScheduleMode } from "../../Schedule";
import { LegendRow } from "../components/TimelineLegend";
import { ScheduleItem } from "../../../../api/models/Schedule";
import { intervalToDuration, isAfter } from "date-fns";
import TextIds from "../../../../language/TextIds";
import { Station } from "../../../../api/models/Station";

interface TimelineTaskProps {
    dates: Date[];
    scheduleItems: ScheduleItem[];
}

export const HEADER_HEIGHT = 116;
export const COLUMN_WIDTH = 56;
export const ROW_HEIGHT = 56;
export const TASK_HEIGHT = 28;

export function buildTimeLineElements(
    timelineTaskProps: TimelineTaskProps,
    leftHeaderRows: GeneralObjectType[],
    stations: Station[],
    legendRows: LegendRow[],
    scheduleMode: ScheduleMode
): TimelineElement[] {
    let timelineElements: TimelineElement[] = [];

    timelineTaskProps.scheduleItems.forEach((scheduleItem) => {
        let groupId = scheduleMode === ScheduleMode.Order ? scheduleItem.station_id : scheduleItem.production_order.id;
        let popUpInfo = createPopUpInfo(scheduleMode, scheduleItem, stations);
        let elementColor = getElementColor(legendRows, scheduleItem, groupId);
        let yPosition = getYPosition(scheduleItem, leftHeaderRows, scheduleMode);

        let taskTimeLineElements = createTaskElements(
            scheduleItem,
            popUpInfo,
            timelineTaskProps.dates,
            elementColor,
            yPosition,
            groupId
        );
        timelineElements.push(...taskTimeLineElements);
    });
    return timelineElements;
}

function getElementColor(legendRows: LegendRow[], scheduleItem: ScheduleItem, groupId: number): string {
    let color = legendRows.find((row) => row.groupId === groupId)?.color;
    return color ? color : GRAY_LIGHT;
}

function createTaskElements(
    scheduleItem: ScheduleItem,
    popUpInfo: PopUpInfo[],
    dates: Date[],
    elementColor: string,
    yPosition: number,
    groupId: number
): TimelineElement[] {
    let timelineTasks: TimelineElement[] = [];

    for (let item of scheduleItem.items) {
        let start = new Date(item.start);
        let end = new Date(item.end);
        timelineTasks.push(
            createTimeLineElement(scheduleItem.id, start, end, dates, popUpInfo, elementColor, yPosition, groupId)
        );
    }
    return timelineTasks;
}

function createTimeLineElement(
    id: number,
    startDate: Date,
    endDate: Date,
    dates: Date[],
    popUpInfo: PopUpInfo[],
    elementColor: string,
    yPosition: number,
    groupId: number
): TimelineElement {
    const startPosition = calculateXCoordinateOfTask(startDate, dates);
    const endPosition = calculateXCoordinateOfTask(endDate, dates);

    return {
        id,
        startPosition,
        endPosition,
        yPosition,
        color: elementColor,
        popUp: popUpInfo,
        groupId: groupId,
    };
}

function createPopUpInfo(scheduleMode: ScheduleMode, scheduleItem: ScheduleItem, stations: Station[]): PopUpInfo[] {
    if (scheduleMode === ScheduleMode.Order) {
        let station = stations.find((station) => station.id === scheduleItem.station_id);
        if (station)
            return [
                {
                    title: TextIds.Station.NAME,
                    info: station.id,
                },
                {
                    title: TextIds.Form.NAME,
                    info: station.name,
                },
                {
                    title: TextIds.Form.DESCRIPTION,
                    info: station.description,
                },
                {
                    title: TextIds.ProductGroup.NAME,
                    info: station.product_group.name,
                },
                {
                    title: TextIds.ProductionOrder.PRODUCT_NAME,
                    info: scheduleItem.production_order.product_name,
                },
                {
                    title: TextIds.Form.QUANTITY,
                    info: scheduleItem.production_order.quantity,
                },
                {
                    title: TextIds.Customer.NAME,
                    info: scheduleItem.customer_name,
                },
            ];
    }
    const scheduleItemEndDate = scheduleItem.items[scheduleItem.items.length - 1].end;
    return [
        {
            title: TextIds.PO_INFOS,
            info: scheduleItem.production_order.id,
        },
        {
            title: TextIds.ProductionOrder.PRODUCT_ID,
            info: scheduleItem.production_order.product_id,
        },
        {
            title: TextIds.ProductionOrder.PRODUCT_NAME,
            info: scheduleItem.production_order.product_name,
        },
        {
            title: TextIds.Form.QUANTITY,
            info: scheduleItem.production_order.quantity,
        },
        {
            title: TextIds.Customer.NAME,
            info: scheduleItem.customer_name,
        },
        {
            title: TextIds.Form.END_DATE,
            info: scheduleItemEndDate,
        },
    ];
}

function calculateXCoordinateOfTask(dateToPosition: Date, dates: Date[]) {
    const index = dates.findIndex((date) => isAfter(date, dateToPosition)) - 1;
    const between = intervalToDuration({ start: dates[index], end: dateToPosition });

    const columnIntervall = intervalToDuration({ start: dates[index], end: dates[index + 1] });
    if (!columnIntervall) return 0;

    const days = columnIntervall.days ? columnIntervall.days : 0;
    const hours = columnIntervall.hours ? columnIntervall.hours : 0;
    const minutes = columnIntervall.minutes ? columnIntervall.minutes : 0;
    const columnDuration = days * 1440 + hours * 60 + minutes;
    const marginForTime = getMarginForTime(1, 0, between.hours, between.minutes, columnDuration);

    return index * COLUMN_WIDTH + marginForTime;
}

function getYPosition(scheduleItem: ScheduleItem, leftHeaderRow: GeneralObjectType[], scheduleMode: ScheduleMode) {
    let index = leftHeaderRow.findIndex((row) => row.id === scheduleItem.station_id);
    if (scheduleMode === ScheduleMode.Order)
        index = leftHeaderRow.findIndex((row) => row.id === scheduleItem.production_order.id);
    return index * ROW_HEIGHT + (ROW_HEIGHT - TASK_HEIGHT) / 2;
}

export function getMarginForTime(
    columWidthIndex: number,
    days = 0,
    hours = 0,
    minutes = 0,
    columDuration = 1440
): number {
    const calculatedColumnWidth = COLUMN_WIDTH * columWidthIndex;
    const calculationHours = hours * 60;
    const calculationMinutes = minutes + calculationHours;

    return (calculatedColumnWidth / columDuration) * calculationMinutes + days * calculatedColumnWidth;
}
