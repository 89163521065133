import { Line } from "../../../api/models/Line";
import Downtime, { createEmptyDowntime } from "../../../api/models/Downtime";
import { useText } from "../../../context/LanguageContext";
import { useProduction, useProductionUpdate } from "../../../context/ProductionContext";
import TextIds from "../../../language/TextIds";
import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from "react";
import { deleteDowntime, postDowntime, putDowntime } from "../../../api/calls/Downtime";
import { datePattern, textPattern, timePattern } from "../../../utils/FormUtils";
import Modal from "../../Modal";
import { TestIds } from "../../../TestIds";
import Input from "../../form-inputs/Input";
import FormFooter from "../../FormFooter";
import TimeInput from "../../form-inputs/TimeInput";
import Checkbox from "../../form-inputs/Checkbox";
import { toast } from "react-toastify";
import RescheduleWarning from "./RescheduleWarning";
import { isBefore, isEqual } from "date-fns";

export interface DowntimeFormProps {
    selectedDowntime: Downtime;
    selectedLine: Line;
    setOpen: Function;
    reloadDowntimes: Function;
    open: boolean;
}

export default function DowntimeForm({
    selectedDowntime,
    selectedLine,
    reloadDowntimes,
    setOpen,
    open,
}: DowntimeFormProps): JSX.Element {
    const getText = useText();

    const production = useProduction();
    const updateProduction = useProductionUpdate();

    const [downtime, setDowntime] = useState<Downtime>(createEmptyDowntime);

    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [startTime, setStartTime] = useState<string>("");
    const [endTime, setEndTime] = useState<string>("");

    const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(false);

    const lable = selectedDowntime.id === 0 ? getText(TextIds.Downtime.ADD) : getText(TextIds.Downtime.EDIT);

    const close = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const deleteFunction = useCallback(() => {
        deleteDowntime(downtime.id, getText)
            .then(() => {
                updateProduction(production.id);
                reloadDowntimes(selectedLine);
                close();
            })
            .catch((error) => console.error(error));
    }, [downtime, getText, close, production.id, reloadDowntimes, selectedLine, updateProduction]);

    const setStartDateInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        let dateInput = event.target.value;
        setDowntime((downtime) => ({
            ...downtime,
            start: dateInput + downtime.start.substring(10, downtime.start.length),
        }));
        setStartDate(dateInput);
    }, []);

    const setEndDateInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        let dateInput = event.target.value;
        setDowntime((downtime) => ({
            ...downtime,
            end: dateInput + downtime.end.substring(10, downtime.end.length),
        }));
        setEndDate(dateInput);
    }, []);

    const setDescription = useCallback(
        (event: ChangeEvent<HTMLTextAreaElement>) =>
            setDowntime((downtime) => ({
                ...downtime,
                description: event.target.value,
            })),
        []
    );

    const setStartTimeInput = useCallback(
        (start: string) => {
            setStartTime(start);
            setDowntime((downtime) => ({ ...downtime, start: startDate + " " + start + ":00" }));
        },
        [startDate]
    );

    const setEnd = useCallback(
        (end: string) => {
            setEndTime(end);
            setDowntime((downtime) => ({ ...downtime, end: endDate + " " + end + ":00" }));
        },
        [endDate]
    );

    const isDataValid = useCallback(() => {
        if (!downtime.is_all_day) {
            if (!timePattern.test(startTime) && timePattern.test(endTime)) {
                toast.error(getText(TextIds.Form.TIME_FORMAT_MISMATCH));
                return;
            }
        }

        if (!datePattern.test(startDate)) {
            toast.error(getText(TextIds.Form.DATE_NEEDED));
            return;
        }

        if (!datePattern.test(endDate)) {
            toast.error(getText(TextIds.Form.DATE_NEEDED));
            return;
        }
        if (!textPattern.test(downtime.description)) {
            toast.error(getText(TextIds.Form.DESCRIPTION_NEEDED));
            return;
        }

        const start = new Date(downtime.start);
        const end = new Date(downtime.end);
        if (isBefore(end, start) || isEqual(start, end)) {
            toast.error(getText(TextIds.Form.DAILY_START_END_INVALID));
            return;
        }

        return true;
    }, [downtime, getText, endTime, startTime, endDate, startDate]);

    const handleSubmit = useCallback(
        (e: FormEvent<HTMLFormElement>) => {
            if (e === undefined) return;
            e.preventDefault();

            if (!(JSON.stringify(selectedDowntime) === JSON.stringify(downtime) || isSaveEnabled)) {
                toast.error(getText(TextIds.Form.CHECK_SAVE));
                return;
            }
            if (!isDataValid()) return;

            if (JSON.stringify(selectedDowntime) === JSON.stringify(downtime)) {
                close();
                return;
            }

            const promise =
                downtime.id !== 0 ? putDowntime(downtime, downtime.id, getText) : postDowntime(downtime, getText);

            promise
                .then(() => {
                    updateProduction(production.id);
                    reloadDowntimes(selectedLine);
                    close();
                })
                .catch((error) => console.error(error));
        },
        [
            close,
            downtime,
            isDataValid,
            getText,
            production,
            updateProduction,
            reloadDowntimes,
            selectedLine,
            isSaveEnabled,
            selectedDowntime,
        ]
    );

    useEffect(() => {
        selectedDowntime.line_id = selectedLine.id;
        setStartTime(selectedDowntime.start.substring(11, 16));
        setStartDate(selectedDowntime.start.substring(0, 10));
        setEndTime(selectedDowntime.end.substring(11, 16));
        setEndDate(selectedDowntime.end.substring(0, 10));
        setDowntime(selectedDowntime);
        setIsSaveEnabled(false);
    }, [selectedDowntime, selectedLine.id, open]);

    return (
        <Modal title={lable} description={getText(TextIds.Downtime.FORM_SUBTITLE)} open={open} setOpen={setOpen}>
            <form
                className="flex flex-col space-y-4 divide-y divide-solid"
                onSubmit={(e) => handleSubmit(e)}
                data-testid={TestIds.DOWNTIME_FORM}
            >
                <div className="space-y-4">
                    <Input
                        labelText={getText(TextIds.Form.DESCRIPTION)}
                        name="description"
                        type="text"
                        value={downtime.description}
                        placeholder={getText(TextIds.Form.DESCRIPTION)}
                        pattern={textPattern.source}
                        maxLength={100}
                        onChange={setDescription}
                        data-testid={TestIds.INPUT_DESCRIPTION}
                        required
                    />
                    <div className="flex space-x-2">
                        <Input
                            labelText={getText(TextIds.Form.START)}
                            name="startDate"
                            value={startDate}
                            type="date"
                            placeholder={getText(TextIds.Form.DATE)}
                            pattern={datePattern.source}
                            title={getText(TextIds.Form.DATE_INPUT_ALLOWED)}
                            maxLength={10}
                            onChange={setStartDateInput}
                            data-testid={TestIds.INPUT_START_DATE}
                            required
                        />
                        <TimeInput
                            value={startTime}
                            disabled={downtime.is_all_day}
                            setTime={setStartTimeInput}
                            name="startTime"
                            placeholder={getText(TextIds.Form.START_HOUR)}
                            title={getText(TextIds.Form.TIME_FORMAT_ALLOWED)}
                            required
                            data-testid={TestIds.INPUT_START}
                        />

                        <Input
                            labelText={getText(TextIds.Form.END)}
                            name="endDate"
                            value={endDate}
                            type="date"
                            placeholder={getText(TextIds.Form.DATE)}
                            pattern={datePattern.source}
                            title={getText(TextIds.Form.DATE_INPUT_ALLOWED)}
                            maxLength={10}
                            onChange={setEndDateInput}
                            data-testid={TestIds.INPUT_END_DATE}
                            required
                        />
                        <TimeInput
                            value={endTime}
                            disabled={downtime.is_all_day}
                            setTime={setEnd}
                            name="endTime"
                            placeholder={getText(TextIds.Form.END_HOUR)}
                            title={getText(TextIds.Form.TIME_FORMAT_ALLOWED)}
                            required
                            data-testid={TestIds.INPUT_END}
                        />
                    </div>
                    <div className="flex justify-end space-x-2" data-testid={TestIds.ALL_DAY_CHECKBOX}>
                        <Checkbox
                            label={getText(TextIds.Form.IS_ALL_DAY)}
                            checked={downtime.is_all_day}
                            setChecked={(isDa: boolean) => setDowntime({ ...downtime, is_all_day: isDa })}
                        />
                    </div>
                    <RescheduleWarning
                        showWarning={JSON.stringify(selectedDowntime) !== JSON.stringify(downtime)}
                        isSaveEnabled={isSaveEnabled}
                        setIsSaveEnabled={setIsSaveEnabled}
                    />
                </div>
                <FormFooter
                    handleSubmitFunction={handleSubmit}
                    close={close}
                    isEditMode={downtime.id !== 0}
                    deleteFunction={deleteFunction}
                    objectToDelete={downtime}
                    shownName={downtime.description}
                />
            </form>
        </Modal>
    );
}
