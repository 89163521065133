import { CustomerOrderDetails } from "./CustomerOrderDetails";
import { DeliveryStatus, Status } from "./GeneralTypes";
import { Customer } from "./Customer";
import TextIds from "../../language/TextIds";

export enum CustomerOrderType {
    SINGLE = TextIds.Status.SINGLE as any,
    SUBSCRIPTION = TextIds.Status.SUBSCRIPTION as any,
}

export interface CustomerOrder {
    id: number;
    type: CustomerOrderType;
    delivery_date: string;
    order_details: CustomerOrderDetails[];
    internal_id: string;
    customer_id: number;
    status: Status;
    delivery_status: DeliveryStatus;
}

export function createEmptyCustomerOrder(): CustomerOrder {
    return {
        id: 0,
        internal_id: "",
        delivery_date: "",
        type: CustomerOrderType.SINGLE,
        order_details: [],
        customer_id: 0,
        status: Status.RUNNING,
        delivery_status: DeliveryStatus.IN_TIME,
    };
}

export function filterCustomerOrderList(
    filterInput: string,
    customerOrders: CustomerOrder[],
    customers: Customer[],
    getText: Function
) {
    return customerOrders.filter(
        (order) =>
            order.internal_id.toLowerCase().includes(filterInput) ||
            getText(order.status).toLowerCase().includes(filterInput) ||
            order.delivery_date.toLowerCase().includes(filterInput) ||
            getText(order.delivery_status).toLowerCase().includes(filterInput) ||
            customers.some(
                (customer) => customer.name.toLowerCase().includes(filterInput) && customer.id === order.customer_id
            )
    );
}
