import { ViewMode } from "../../Schedule";
import { addDays, addHours, differenceInCalendarDays, isBefore, parseISO, startOfDay } from "date-fns";
import { ScheduleItem } from "../../../../api/models/Schedule";
import { DowntimeItem } from "../../../../api/models/Downtime";

export function getTimelineDateRange(scheduleItems: ScheduleItem[], viewMode: ViewMode, downtimes: DowntimeItem[]) {
    let startDate: Date = new Date();
    let endDate: Date = new Date();
    if (downtimes.length > 0) {
        startDate = new Date(downtimes[0].start);
        endDate = new Date(downtimes[downtimes.length - 1].end);
    }
    for (const scheduleItem of scheduleItems) {
        for (const item of scheduleItem.items) {
            const itemStart = parseISO(item.start);
            const itemEnd = parseISO(item.end);
            if (isBefore(itemStart, startDate)) {
                startDate = itemStart;
            }
            if (isBefore(endDate, itemEnd)) {
                endDate = itemEnd;
            }
        }
    }

    startDate = startOfDay(startDate);
    endDate = startOfDay(endDate);

    switch (viewMode) {
        case ViewMode.Month:
            startDate = addDays(startDate, -1);
            if (differenceInCalendarDays(endDate, startDate) < 30) {
                endDate = addDays(endDate, 15);
                startDate = addDays(startDate, -15);
            }
            endDate = addDays(endDate, 1);
            break;
        case ViewMode.Week:
            if (differenceInCalendarDays(endDate, startDate) < 10) {
                endDate = addDays(endDate, 5);
                startDate = addDays(startDate, -5);
            }
            startDate = addDays(startDate, -3);
            endDate = addDays(endDate, 3);
            break;
        case ViewMode.Day:
            startDate = addHours(startDate, -1);
            endDate = addDays(endDate, 2);
            break;
    }

    return [startDate, endDate];
}

export function getTimelineDates(startDate: Date, endDate: Date, viewMode: ViewMode) {
    let currentDate: Date = new Date(startDate);
    const dates: Date[] = [currentDate];
    while (currentDate < endDate) {
        switch (viewMode) {
            case ViewMode.Month:
                currentDate = addDays(currentDate, 1);
                break;
            case ViewMode.Week:
                currentDate = addHours(currentDate, 8);
                break;
            case ViewMode.Day:
                currentDate = addHours(currentDate, 1);
                break;
        }
        dates.push(currentDate);
    }

    return dates;
}
