import { IDictionary } from "../context/LanguageContext";

export const de: IDictionary = {
    //Production
    production: "Produktion",
    productions: "Produktionen",
    noProduction: "Keine Produktion vorhanden!",
    addProduction: "Produktion hinzufügen",
    editProduction: "Produktion bearbeiten",
    enterProduction: "Produktionsdetails eingeben",
    deleteProduction: "Produktion löschen",
    productionIdentical: "Produktion ist identisch!",
    deletionProductionFailed: "Produktion konnte nicht gelöscht werden!",
    creationProductionFailed: "Produktion konnte nicht erstellt werden!",
    updateProductionFailed: "Produktion konnte nicht gespeichert werden!",
    deletedProduction: "Produktion gelöscht!",
    createdProduction: "Produktion erstellt!",
    updatedProduction: "Produktion gespeichert!",
    fetchProductionFailed: "Produktion konnte nicht gefunden werden. Gesuchte Id:",
    fetchProductionsFailed: "Produktionen konnten nicht geladen werden!",
    //Line
    line: "Linie",
    lines: "Linien",
    noLine: "Keine Linie vorhanden!",
    addLine: "Linie hinzufügen",
    editLine: "Linie bearbeiten",
    enterLine: "Liniendetails eingeben",
    deleteLine: "Linie löschen",
    selectLine: "Linie auswählen",
    lineIdentical: "Linie ist identisch!",
    deletionLineFailed: "Linie konnte nicht gelöscht werden!",
    creationLineFailed: "Linie konnte nicht erstellt werden!",
    updateLineFailed: "Linie konnte nicht gespeichert werden!",
    deletedLine: "Linie gelöscht!",
    createdLine: "Linie erstellt!",
    updatedLine: "Linie gespeichert!",
    fetchLineFailed: "Linie konnte nicht gefunden werden. Gesuchte Id:",
    //Station
    station: "Station",
    stations: "Stationen",
    noStation: "Keine Station vorhanden!",
    addStation: "Station hinzufügen",
    editStation: "Station bearbeiten",
    enterStation: "Stationsdetails eingeben",
    deleteStation: "Station löschen",
    predecessors: "Vorgänger",
    stationIdentical: "Station ist identisch!",
    deletionStationFailed: "Station konnte nicht gelöscht werden!",
    creationStationFailed: "Station konnte nicht erstellt werden!",
    updateStationFailed: "Station konnte nicht gespeichert werden!",
    deletedStation: "Station gelöscht!",
    createdStation: "Station erstellt!",
    updatedStation: "Station gespeichert!",
    fetchStationFailed: "Station konnte nicht gefunden werden. Gesuchte Id:",
    //Operation
    operation: "Operation",
    operations: "Operationen",
    noOperation: "Keine Operation vorhanden!",
    addOperation: "Operation hinzufügen",
    editOperation: "Operation bearbeiten",
    enterOperation: "Operationsdetails eingeben",
    deleteOperation: "Operation löschen",
    operationIdentical: "Operation ist identisch!",
    deletionOperationFailed: "Operation konnte nicht gelöscht werden!",
    creationOperationFailed: "Operation konnte nicht erstellt werden!",
    updateOperationFailed: "Operation konnte nicht gespeichert werden!",
    deletedOperation: "Operation gelöscht!",
    createdOperation: "Operation erstellt!",
    updatedOperation: "Operation gespeichert!",
    fetchOperationFailed: "Operation konnte nicht gefunden werden. Gesuchte Id:",
    //Product Group
    productGroup: "Produktgruppe",
    productGroups: "Produktgruppen",
    preProductGroups: "Vorproduktgruppen",
    endProductGroups: "Endproduktgruppen",
    noProductGroup: "Keine Produktgruppe vorhanden!",
    addProductGroup: "Produktgruppe hinzufügen",
    editProductGroup: "Produktgruppe bearbeiten",
    enterProductGroup: "Produktgruppendetails eingeben",
    deleteProductGroup: "Produktgruppe löschen",
    productGroupIdentical: "Produktgruppe ist identisch!",
    noProductGroupSelected: "Keine Produktgruppe ausgewählt!",
    deletionProductGroupFailed: "Produktgruppe konnte nicht gelöscht werden!",
    creationProductGroupFailed: "Produktgruppe konnte nicht erstellt werden!",
    updateProductGroupFailed: "Produktgruppe konnte nicht gespeichert werden!",
    deletedProductGroup: "Produktgruppe gelöscht!",
    createdProductGroup: "Produktgruppe erstellt!",
    updatedProductGroup: "Produktgruppe gespeichert!",
    fetchProductGroupFailed: "Produktgruppe konnte nicht gefunden werden. Gesuchte Id:",
    //Product
    product: "Produkt",
    products: "Produkte",
    preProducts: "Vorprodukte",
    endProducts: "Endprodukte",
    preProduct: "Vorprodukt",
    endProduct: "Endprodukt",
    noProduct: "Kein Produkt vorhanden!",
    addProduct: "Produkt hinzufügen",
    editProduct: "Produkt bearbeiten",
    enterProduct: "Produktdetails eingeben",
    deleteProduct: "Produkt löschen",
    productIdentical: "Produkt ist identisch!",
    noProductSelected: "Kein Produkt ausgewählt!",
    deletionProductFailed: "Produkt konnte nicht gelöscht werden!",
    creationProductFailed: "Produkt konnte nicht erstellt werden!",
    updateProductFailed: "Produkt konnte nicht gespeichert werden!",
    deletedProduct: "Produkt gelöscht!",
    createdProduct: "Produkt erstellt!",
    updatedProduct: "Produkt gespeichert!",
    fetchProductFailed: "Produkt konnte nicht gefunden werden. Gesuchte Id:",
    //External Material Group
    externalMaterialGroup: "Externe Materialgruppe",
    externalMaterialGroups: "Externe Materialgruppen",
    noExternalMaterialGroup: "Keine externe Materialgruppe vorhanden!",
    addExternalMaterialGroup: "Externe Materialgruppe hinzufügen",
    editExternalMaterialGroup: "Externe Materialgruppe bearbeiten",
    enterExternalMaterialGroup: "Externe Materialgruppendetails eingeben",
    deleteExternalMaterialGroup: "Externe Materialgruppe löschen",
    externalMaterialGroupIdentical: "Externe Materialgruppe ist identisch!",
    noExternalMaterialGroupSelected: "Keine Externe Materialgruppe ausgewählt!",
    deletionExternalMaterialGroupFailed: "Externe Materialgruppe konnte nicht gelöscht werden!",
    creationExternalMaterialGroupFailed: "Externe Materialgruppe konnte nicht erstellt werden!",
    updateExternalMaterialGroupFailed: "Externe Materialgruppe konnte nicht gespeichert werden!",
    deletedExternalMaterialGroup: "Externe Materialgruppe gelöscht!",
    createdExternalMaterialGroup: "Externe Materialgruppe erstellt!",
    updatedExternalMaterialGroup: "Externe Materialgruppe gespeichert!",
    fetchExternalMaterialGroupFailed: "Externe Materialgruppe konnte nicht gefunden werden. Gesuchte Id:",
    //External Material
    externalMaterial: "Externes Material",
    externalMaterials: "Externe Materialien",
    noExternalMaterial: "Kein externes Material vorhanden!",
    addExternalMaterial: "Externes Material hinzufügen",
    editExternalMaterial: "Externes Material bearbeiten",
    enterExternalMaterial: "Externe Materialdetails eingeben",
    deleteExternalMaterial: "Externes Material löschen",
    selectExternalMaterial: "Externes Material auswählen",
    externalMaterialIdentical: "Externes Material ist identisch!",
    deletionExternalMaterialFailed: "Externes Material konnte nicht gelöscht werden!",
    creationExternalMaterialFailed: "Externes Material konnte nicht erstellt werden!",
    updateExternalMaterialFailed: "Externes Material konnte nicht gespeichert werden!",
    deletedExternalMaterial: "Externes Material gelöscht!",
    createdExternalMaterial: "Externes Material erstellt!",
    updatedExternalMaterial: "Externes Material gespeichert!",
    fetchExternalMaterialFailed: "Externes Material konnte nicht gefunden werden. Gesuchte Id:",
    //Customer
    customer: "Kunde",
    customers: "Kunden",
    noCustomer: "Kein Kunde vorhanden!",
    addCustomer: "Kunde hinzufügen",
    editCustomer: "Kunde bearbeiten",
    enterCustomer: "Kundendetails eingeben",
    deleteCustomer: "Kunde löschen",
    selectCustomer: "Kunde auswählen",
    customerIdentical: "Kunde ist identisch!",
    noCustomerSelected: "Kein Kunde ausgewählt!",
    deletionCustomerFailed: "Kunde konnte nicht gelöscht werden!",
    creationCustomerFailed: "Kunde konnte nicht erstellt werden!",
    updateCustomerFailed: "Kunde konnte nicht gespeichert werden!",
    deletedCustomer: "Kunde gelöscht!",
    createdCustomer: "Kunde erstellt!",
    updatedCustomer: "Kunde gespeichert!",
    fetchCustomerFailed: "Kunde konnte nicht gefunden werden. Gesuchte Id:",
    //Customer Order
    customerOrder: "Kundenauftrag",
    customerOrders: "Kundenaufträge",
    noCustomerOrder: "Kein Kundenauftrag vorhanden!",
    addCustomerOrder: "Kundenauftrag hinzufügen",
    editCustomerOrder: "Kundenauftrag bearbeiten",
    enterCustomerOrder: "Kundenauftragdetails eingeben",
    deleteCustomerOrder: "Kundenauftrag löschen",
    customerOrderIdentical: "Kundenauftrag ist identisch!",
    deletionCustomerOrderFailed: "Kundenauftrag konnte nicht gelöscht werden!",
    creationCustomerOrderFailed: "Kundenauftrag konnte nicht erstellt werden!",
    updateCustomerOrderFailed: "Kundenauftrag konnte nicht gespeichert werden!",
    deletedCustomerOrder: "Kundenauftrag gelöscht!",
    createdCustomerOrder: "Kundenauftrag erstellt!",
    updatedCustomerOrder: "Kundenauftrag gespeichert!",
    fetchCustomerOrderFailed: "Kundenauftrag konnte nicht gefunden werden. Gesuchte Id:",
    //Production Order
    productionOrder: "Fertigungsauftrag",
    productionOrders: "Fertigungsaufträge",
    noProductionOrder: "Kein Fertigungsauftrag vorhanden!",
    deleteProductionOrder: "Fertigungsauftrag löschen",
    deletionProductionOrderFailed: "Fertigungsauftrag konnte nicht gelöscht werden!",
    creationProductionOrderFailed: "Fertigungsauftrag konnte nicht erstellt werden!",
    updateProductionOrderFailed: "Fertigungsauftrag konnte nicht gespeichert werden!",
    deletedProductionOrder: "Fertigungsauftrag gelöscht!",
    createdProductionOrder: "Fertigungsauftrag erstellt!",
    updatedProductionOrder: "Fertigungsauftrag gespeichert!",
    productId: "Produkt ID",
    productName: "Produkt Name",
    //Downtime
    downtime: "Stillstandzeit",
    downtimes: "Stillstandzeiten",
    noDowntimes: "Keine Stillstandzeiten vorhanden!",
    enterDowntime: "Stillstanddetails eingeben",
    deleteDowntime: "Stillstandzeit löschen",
    addDowntime: "Stillstandzeit hinzufügen",
    editDowntime: "Stillstandzeit bearbeiten",
    deletionDowntimeFailed: "Stillstandzeit konnte nicht gelöscht werden!",
    creationDowntimeFailed: "Stillstandzeit konnte nicht erstellt werden!",
    updateDowntimeFailed: "Stillstandzeit konnte nicht gespeichert werden!",
    deletedDowntime: "Stillstandzeit gelöscht!",
    createdDowntime: "Stillstandzeit erstellt!",
    updatedDowntime: "Stillstandzeit gespeichert!",
    fetchDowntimesFailed: "Stillstandzeiten konnten nicht geladen werden!",
    showDowntime: "Stillstandzeiten anzeigen",
    //Form
    information: "Information",
    name: "Name",
    id: "ID",
    email: "E-Mail",
    description: "Beschreibung",
    type: "Typ",
    quantity: "Menge",
    duration: "Dauer in Min",
    endHour: "Endzeit",
    startHour: "Startzeit",
    deliveryDate: "Wunschtermin",
    date: "Datum",
    endDate: "Endtermin",
    end: "Ende",
    startDate: "Starttermin",
    start: "Start",
    initialDate: "Initiales Datum",
    shiftSchedule: "Schichtplan",
    workHours: "Arbeitszeiten",
    isAllDay: "Ganztägig",
    horizon: "Planungshorizont (in Tagen)",
    freeze: "Planungsfreeze (in Minuten)",
    timeFormat: "(HH:MM, 24-Stunden)",
    emptySelect: "Keine Auswahl möglich!",
    noSelection: "Ohne",
    nothingSelected: "Nichts ausgewählt!",
    cancel: "Abbrechen",
    confirm: "Bestätigen",
    reset: "Zurücksetzten",
    save: "Speichern",
    delete: "Löschen von",
    edit: "Bearbeiten von",
    //Failure
    stringInputAllowed: "Erlaubte Zeichen: Buchstaben, Zahlen, Leerzeichen und ( ) - , / & .",
    numberInputAllowed: "Muss größer 0 sein!",
    timeFormatValid: "Zeitformat falsch, erwartetes Format: HH:MM!",
    dateInputAllowed: "Erlaubte Zeichen:  Zahlen und .!",
    dateNeeded: "Bitte geben Sie ein Datum ein!",
    initialDateBeforeNow: "Das initiale Datum darf nicht vor dem aktuellen Zeitpunkt liegen!",
    descriptionNeeded: "Bitte geben Sie eine Beschreibung ein!",
    dailyStartEndValid: "Die Startzeit muss vor der Endzeit liegen!",
    timeFormatAllowed: "Zeitformat: HH:MM, 24-Stunden",
    notValidDate: "Kein gültiges Datum!",
    getsRescheduled: "Wenn Sie jetzt speichern, wird die Schedule dieser Linie neu berechnet!",
    checkSave: "Bitte bestätigen Sie die Checkbox zum speichern",
    counterError: "Es sind nur Zahlen erlaubt!",
    //Navigation
    home: "Start",
    orders: "Aufträge",
    operator: "Werker",
    operators: "Werker",
    reporting: "Berichte",
    schedule: "Feinplanung",
    feedback: "Rückmeldung",
    bom: "Stückliste",
    viewMode: "Ansichtsmodus",
    material: "Material",
    process: "Prozess",
    materialProduct: "Materialien & Produkte",
    //Time
    now: "Jetzt",
    week: "Woche",
    today: "Heute",
    cw: "KW",
    day: "Tag",
    month: "Monat",
    //Days
    monday: "Montag",
    tuesday: "Dienstag",
    wednesday: "Mittwoch",
    thursday: "Donnerstag",
    friday: "Freitag",
    saturday: "Samstag",
    sunday: "Sonntag",
    //Status
    READY: "Bereit",
    SCHEDULED: "Berechnet",
    RUNNING: "Laufend",
    DONE: "Fertig",
    BLOCKED: "Blockiert",
    STOPPED: "Gestoppt",
    DELAY: "Verzögerung",
    DELAYED: "Verzögert",
    TIGHT: "In Risiko",
    DELIVERY_STATUS: "Lieferstatus",
    FINISHED: "Fertig",
    ON_RISK: "Risiko",
    IN_TIME: "Pünktlich",
    STATUS: "Status",
    SINGLE: "Einzeln",
    SUBSCRIPTION: "Abonnement",
    PRE: "Vor",
    END: "End",
    //Login
    username: "Benutzername",
    password: "Passwort",
    signIn: "Anmelden",
    loginSuccessful: "Anmeldung erfolgreich!",
    loginFailed: "Anmeldung fehlgeschlagen",
    loginCredentials: "Nutzername oder Passwort falsch.",
    logout: "Ausloggen",
    forgotPassword: "Passwort vergessen?",
    enterEmail: "E-Mail-Adresse eingeben, um Passwort zurückzusetzen",
    enterNewPassword: "Neues Passwort eingeben",
    resetPassword: "Passwort zurücksetzten",
    createAccount: "Benutzerkonto erstellen",
    changePassword: "Passwort  ändern",
    confirmPassword: "Passwort  bestätigen",
    //Feedback
    feedbackHeader: "Feedback geben",
    support: "Support E-Mail",
    subject: "Thema",
    //Schedule
    calculate: "Planen",
    capacity: "Kapazität",
    createSchedule: "Feinplanung erstellen",
    scheduleMethod: "Planungs-Methode",
    makespan: "Durchlaufzeit",
    //Others
    to: "bis",
    order: "Auftrag",
    notOptimised: "nicht optimiert",
    optimised: "Optimiert",
    standard: "Standard",
    perOrder: "pro Auftrag",
    po: "Produktionsauftrag",
    group: "Gruppe",
    poInfos: "Infos Produktionsauftrag",
    deleted: "gelöscht!",
    rows: "Zeilen",
    show: "Zeigen",
    totalSavings: "Gesamtersparnis",
    dateFormatDe: "TT.MM.JJJJ",
    total: "Insgesamt",
    entries: "Einträge",
    loading: "Lädt",
    sureConfirm: "Sind Sie sicher, dass Sie fortfahren wollen?",
    ops: "Ups!",
    notFound: "Seite nicht gefunden",
    notExist: "Die Seite die Sie versuchen aufzurufen, existiert nicht!",
    goHome: "Zurück zum Start",
    noResponse: "Keine Antwort vom Server!",
};
