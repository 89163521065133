export default interface Downtime {
    id: number;
    is_all_day: boolean;
    description: string;
    start: string;
    end: string;
    line_id: number;
}

export interface DowntimeItem {
    id: number;
    end: string;
    start: string;
    downtimes: Downtime[];
}

export function createEmptyDowntime(): Downtime {
    return {
        id: 0,
        is_all_day: false,
        description: "",
        start: "",
        end: "",
        line_id: 0,
    };
}
