import React from "react";
import { Link } from "react-router-dom";
import Button, { ButtonSize, ButtonTypes } from "../../components/buttons/Button";
import { LinkIcon } from "@heroicons/react/24/outline";
import TextIds from "../../language/TextIds";
import { useText } from "../../context/LanguageContext";

export default function NotFound() {
    const getText = useText();
    return (
        <div className="flex justify-center">
            <div className="absolute top-1/4 h-96 w-1/3 rounded-md bg-white py-10 shadow-xl">
                <div className="flex flex-col items-center text-gray-dark">
                    <h1 className="text-9xl font-bold">404</h1>
                    <h6 className="mb-2 text-center text-2xl font-bold md:text-3xl">
                        <span className="text-red-600">{getText(TextIds.OPS)}</span> {getText(TextIds.PAGE_NOT_FOUND)}
                    </h6>
                    <p className="mb-8 text-center md:text-lg">{getText(TextIds.PAGE_NOT_EXIST)}</p>
                    <Link to="/">
                        <Button
                            onClick={() => undefined}
                            icon={<LinkIcon />}
                            buttonType={ButtonTypes.Cancel}
                            buttonSize={ButtonSize.middle}
                            title={getText(TextIds.GO_HOME)}
                        ></Button>
                    </Link>
                </div>
            </div>
        </div>
    );
}
