import React from "react";
import NavigationBar from "./navigation/NavigationBar";
import { BrowserRouter, Navigate, useRoutes } from "react-router-dom";
import NotFound from "./not-found/NotFound";
import { useProductions } from "../context/ProductionsContext";
import LoadScreen from "../components/LoadScreen";
import ProductGroupContext from "../context/ProductGroupContext";
import ProductContext from "../context/ProductContext";
import { Schedule } from "./schedule/Schedule";
import { TestIds } from "../TestIds";
import Orders from "./order/Orders";
import ProcessLineModeling from "./modeling/process/ProcessLineModeling";
import ProductModeling from "./modeling/product/ProductModeling";
import Productions from "./production/Productions";
import { ProductionListElement } from "../api/models/Production";

export interface PageProps {
    logout: Function;
}

export default function Page({ logout }: PageProps): JSX.Element {
    const productions: ProductionListElement[] = useProductions();

    const Routes = () =>
        useRoutes([
            ...productionsRoutes(),
            { path: "/", element: <Navigate to="/productions" /> },
            { path: "*", element: <NotFound /> },
        ]);

    const productionsRoutes = () => {
        if (productions.length === 0) {
            return [{ path: "/*", element: <Productions /> }];
        }

        return [
            {
                path: "/productions",
                element: <Productions />,
            },
            {
                path: "/material",
                element: (
                    <ProductGroupContext>
                        <ProductContext>
                            <ProductModeling />
                        </ProductContext>
                    </ProductGroupContext>
                ),
            },
            {
                path: "/process",
                element: (
                    <ProductGroupContext>
                        <ProductContext>
                            <ProcessLineModeling />
                        </ProductContext>
                    </ProductGroupContext>
                ),
            },
            {
                path: "/orders",
                element: <Orders />,
            },
            {
                path: "/schedule",
                element: <Schedule />,
            },
        ];
    };

    return (
        <div className="h-screen">
            <BrowserRouter>
                <LoadScreen />
                <NavigationBar logout={logout} />
                <div className="mt-12 flex justify-center" data-testid={TestIds.BODY}>
                    <div className="relative w-5/6">
                        <Routes />
                    </div>
                </div>
            </BrowserRouter>
        </div>
    );
}
