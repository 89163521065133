import React, { useEffect, useState } from "react";
import { namePattern } from "../../utils/FormUtils";
import { toast } from "react-toastify";
import { useProduction, useProductionUpdate } from "../../context/ProductionContext";
import Modal from "../Modal";
import Input from "../form-inputs/Input";
import FormFooter from "../FormFooter";
import Select from "../form-inputs/Select";
import MultiSelect from "../form-inputs/MultiSelect";
import { getById, getInListIncludedValue, getInListIncludedValues, isObjectInList } from "../../utils/FilterUtils";
import { Operation } from "../../api/models/Operation";
import { createEmptyProduct, Product } from "../../api/models/Product";
import { createEmptyProductGroup, ProductGroup } from "../../api/models/ProductGroup";
import { ExternalMaterial } from "../../api/models/ExternalMaterial";
import { deleteProduct, postProduct, putProduct } from "../../api/calls/Product";
import { createEmptyExtMaterialGroup, ExternalMaterialGroup } from "../../api/models/ExternalMaterialGroup";
import { createEmptyStation, getAllStations } from "../../api/models/Station";
import { TestIds } from "../../TestIds";
import { useText } from "../../context/LanguageContext";
import TextIds from "../../language/TextIds";
import { NAME_LENGTH } from "../../constants";

export interface ProductFormProps {
    selectedProduct: Product;
    setOpen: Function;
    open: boolean;
}

export default function ProductForm({ selectedProduct, setOpen, open }: ProductFormProps) {
    const getText = useText();
    const production = useProduction();
    const updateProduction = useProductionUpdate();

    const [product, setProduct] = useState<Product>(createEmptyProduct());

    const [defaultProductGroupSelectOption, setDefaultProductGroupSelectOption] = useState<ProductGroup>(
        createEmptyProductGroup()
    );
    const [selectablePreProducts, setSelectablePreProducts] = useState<Product[]>([]);
    const [selectableExtMaterials, setSelectableExtMaterials] = useState<ExternalMaterial[]>([]);

    const [modalTitle, setModalTitle] = useState("");

    const close = () => setOpen(false);
    const deleteFunction = () =>
        deleteProduct(product.id, getText).then(() => {
            updateProduction(production.id);
            close();
        });

    function isDataValid() {
        if (!namePattern.test(product.name)) {
            toast.error(getText(TextIds.Form.NAME_ALLOWED_CHARACTER));
            return;
        }
        if (product.product_group_id === 0) {
            toast.error(getText(TextIds.ProductGroup.NO_SELECTION));
            return;
        }
        return true;
    }

    function editProduct() {
        const updatedProduct = {
            ...product,
            name: product.name,
            pre_products: product.pre_products,
            product_group_id: product.product_group_id,
            external_materials: product.external_materials,
        };
        return putProduct(updatedProduct, product.id, getText);
    }

    function handleSubmit(event: any) {
        if (event !== undefined) {
            event.preventDefault();
            if (!isDataValid()) return;
            const promise = () => (selectedProduct.id > 0 ? editProduct() : postProduct(product, getText));
            promise().then(() => {
                updateProduction(production.id);
                close();
            });
        }
    }

    async function updateProductData() {
        await setProduct({
            ...selectedProduct,
            name: selectedProduct.name,
            internal_id: selectedProduct.internal_id,
            pre_products: selectedProduct.pre_products,
            product_group_id: selectedProduct.product_group_id,
            external_materials: selectedProduct.external_materials,
        });
    }

    useEffect(() => {
        if (selectedProduct.id > 0) {
            setModalTitle(getText(TextIds.Product.EDIT));
            const defaultOption = getById(
                selectedProduct.product_group_id,
                production.product_groups,
                createEmptyProduct
            );
            setDefaultProductGroupSelectOption(defaultOption);
            if (defaultOption.id !== 0) {
                getProductList(defaultOption, selectedProduct);
                getExtMaterialList(defaultOption, selectedProduct);
            }
            updateProductData();
        } else {
            setDefaultProductGroupSelectOption(createEmptyProductGroup());
            setModalTitle(getText(TextIds.Product.ADD));
            setProduct({
                ...selectedProduct,
                name: "",
            });
        }
        // eslint-disable-next-line
    }, [selectedProduct]);

    async function updateSelectedProductGroup(selectedProductGroup: ProductGroup) {
        await setProduct({
            ...product,
            pre_products: [],
            external_materials: [],
            product_group_id: selectedProductGroup.id,
        });
        getProductList(selectedProductGroup, product);
        getExtMaterialList(selectedProductGroup, product);
    }

    function getProductList(selectedProductGroup: ProductGroup, editProduct: Product) {
        const productList = [];
        for (let preProductGroupId of selectedProductGroup.pre_product_groups) {
            const preProductGroup = getById(
                preProductGroupId.pre_product_group_id,
                production.product_groups,
                createEmptyProductGroup
            );
            if (editProduct.pre_products.length === 0) {
                productList.push(...preProductGroup.products);
            } else {
                const preProduct = getInListIncludedValue(preProductGroup.products, editProduct.pre_products);
                if (preProduct) {
                    productList.push(preProduct);
                } else {
                    productList.push(...preProductGroup.products);
                }
            }
        }
        setSelectablePreProducts([...productList]);
    }

    function getExtMaterialList(selectedProductGroup: ProductGroup, editProduct: Product) {
        let materialList = [];
        for (let materialGroupId of selectedProductGroup.external_material_groups) {
            const materialGroup: ExternalMaterialGroup = getById(
                materialGroupId.external_material_group_id,
                production.external_material_groups,
                createEmptyExtMaterialGroup
            );
            if (editProduct.external_materials.length === 0) materialList.push(...materialGroup.external_materials);
            else {
                const material = getInListIncludedValue(
                    materialGroup.external_materials,
                    editProduct.external_materials
                );
                if (material) materialList.push(material);
                else materialList.push(...materialGroup.external_materials);
            }
        }
        setSelectableExtMaterials(materialList);
    }

    function updateSelectedExtMaterial(selectedExtMaterial: ExternalMaterial[]) {
        setProduct({
            ...product,
            external_materials: selectedExtMaterial.map((value) => value.id),
        });
        const materialList = [];
        const productGroup: ProductGroup = getById(
            product.product_group_id,
            production.product_groups,
            createEmptyProductGroup
        );
        for (let materialGroup of productGroup.external_material_groups) {
            if (
                !isObjectInList(
                    selectedExtMaterial,
                    "external_material_group_id",
                    materialGroup.external_material_group_id
                )
            )
                materialList.push(
                    ...getById(
                        materialGroup.external_material_group_id,
                        production.external_material_groups,
                        createEmptyExtMaterialGroup
                    ).external_materials
                );
        }
        materialList.push(...selectedExtMaterial);
        setSelectableExtMaterials(materialList);
    }

    function updateSelectedPreProduct(selectedPreProducts: Product[]) {
        setProduct({ ...product, pre_products: selectedPreProducts.map((value) => value.id) });

        const productList = [];
        const productGroup: ProductGroup = getById(
            product.product_group_id,
            production.product_groups,
            createEmptyProductGroup
        );
        for (let preProductGroupId of productGroup.pre_product_groups) {
            const preProductGroup = getById(
                preProductGroupId.pre_product_group_id,
                production.product_groups,
                createEmptyProductGroup
            );

            if (!isObjectInList(selectedPreProducts, "product_group_id", preProductGroup.id))
                productList.push(...preProductGroup.products);
        }
        productList.push(...selectedPreProducts);
        setSelectablePreProducts(productList);
    }

    function getSelectableOperations(): Operation[] {
        const productGroup = getById(product.product_group_id, production.product_groups, createEmptyProductGroup);
        const station = getById(productGroup.station_id, getAllStations(production), createEmptyStation);

        return station.operations;
    }

    return (
        <Modal open={open} setOpen={setOpen} title={modalTitle} description={getText(TextIds.Product.FORM_SUBTITLE)}>
            <form className="flex flex-col space-y-4 divide-y divide-solid" onSubmit={(event) => handleSubmit(event)}>
                <div className="space-y-4">
                    <Input
                        labelText={getText(TextIds.Form.NAME)}
                        name="name"
                        value={product.name}
                        type="text"
                        placeholder={getText(TextIds.Form.NAME)}
                        pattern={namePattern.source}
                        title={getText(TextIds.Form.NAME_ALLOWED_CHARACTER)}
                        maxLength={NAME_LENGTH}
                        onChange={(event: any) => setProduct({ ...product, name: event.target.value })}
                        required
                        data-testid={TestIds.INPUT_NAME}
                    />
                    <Input
                        labelText={getText(TextIds.Form.ID)}
                        name="internal_id"
                        value={product.internal_id}
                        type="text"
                        placeholder={getText(TextIds.Form.ID)}
                        pattern={namePattern.source}
                        title={getText(TextIds.Form.NAME_ALLOWED_CHARACTER)}
                        maxLength={NAME_LENGTH}
                        onChange={(event: any) => setProduct({ ...product, internal_id: event.target.value })}
                        data-testid={TestIds.INPUT_INTERNAL}
                    />
                    <Select
                        label={getText(TextIds.ProductGroup.NAME)}
                        options={[...production.product_groups]}
                        defaultValue={defaultProductGroupSelectOption}
                        required={true}
                        onChange={(event: ProductGroup) => updateSelectedProductGroup(event)}
                        id={TestIds.PRODUCT_GROUP_SELECT}
                    />
                    <MultiSelect
                        label={getText(TextIds.Product.PRE_PLURAL_NAME)}
                        options={selectablePreProducts}
                        defaultValues={[...getInListIncludedValues(product.pre_products, selectablePreProducts)]}
                        onChange={(selectedProducts: Product[]) => updateSelectedPreProduct(selectedProducts)}
                        id={TestIds.PRODUCT_SELECT}
                    />
                    <MultiSelect
                        label={getText(TextIds.ExternalMaterial.PLURAL_NAME)}
                        options={selectableExtMaterials}
                        defaultValues={[...getInListIncludedValues(product.external_materials, selectableExtMaterials)]}
                        onChange={(selectedMaterial: ExternalMaterial[]) => updateSelectedExtMaterial(selectedMaterial)}
                        id={TestIds.EXT_MATERIAL_SELECT}
                    />
                    <MultiSelect
                        label={getText(TextIds.Operation.PLURAL_NAME)}
                        options={getSelectableOperations()}
                        defaultValues={[...getInListIncludedValues(product.operations, getSelectableOperations())]}
                        onChange={(selectedOperations: Operation[]) =>
                            setProduct({
                                ...product,
                                operations: selectedOperations.map((operation) => operation.id),
                            })
                        }
                        id={TestIds.OPERATION_SELECT}
                    />
                    <FormFooter
                        handleSubmitFunction={handleSubmit}
                        close={close}
                        deleteFunction={deleteFunction}
                        objectToDelete={selectedProduct}
                        isEditMode={selectedProduct.id > 0}
                    />
                </div>
            </form>
        </Modal>
    );
}
