import axios from "axios";
import { BASE_URL, DOWNTIME_ENDPOINT } from "../ApiConfig";
import { generateToastText, handleApiCall, handleApiGetCall } from "../../utils/ApiUtils";
import { getToken } from "../../hook/UseToken";
import Downtime from "../models/Downtime";

const downtime = "Downtime";
const endpoint = BASE_URL + DOWNTIME_ENDPOINT;

export async function getDowntime(downtimeId: number, getText: Function): Promise<Downtime> {
    const response = axios.get(endpoint + "/" + downtimeId, getToken());
    return handleApiGetCall(response, downtime, downtimeId, getText);
}

export async function putDowntime(downtimeJson: Downtime, downtimeId: number, getText: Function): Promise<Downtime> {
    const messages = generateToastText(downtime, "PUT", getText);
    const response = axios.put(endpoint + "/" + downtimeId, downtimeJson, getToken());
    return handleApiCall(response, messages);
}

export async function postDowntime(downtimeJson: Downtime, getText: Function): Promise<Downtime> {
    const messages = generateToastText(downtime, "POST", getText);
    const response = axios.post(endpoint, downtimeJson, getToken());
    return handleApiCall(response, messages);
}

export async function deleteDowntime(downtimeId: number, getText: Function): Promise<Downtime> {
    const messages = generateToastText(downtime, "DELETE", getText);
    const response = axios.delete(endpoint + "/" + downtimeId, getToken());
    return handleApiCall(response, messages);
}
