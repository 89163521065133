import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { NavigationItem } from "../../api/models/GeneralTypes";
import { TestIds } from "../../TestIds";

interface NavigationLinkProps {
    handleClick: Function;
    item: NavigationItem;
    linkClassNames: string;
    activeItem: NavigationItem | undefined;
}

export default function NavigationLink({
    handleClick,
    item,
    linkClassNames,
    activeItem,
}: NavigationLinkProps): JSX.Element {
    const onClick = useCallback(() => handleClick(item), [item, handleClick]);
    const className = `flex items-center justify-center space-x-2 rounded-full p-2 hover:bg-green-dark
        ${activeItem?.id === item.id ? "bg-green-middle" : " hover:bg-green-dark"}`;

    return (
        <Link
            onClick={onClick}
            key={item.name}
            to={item.href}
            className={linkClassNames}
            aria-current={activeItem?.id === item.id ? "page" : undefined}
        >
            <div className={className} data-testid={TestIds.NAVIGATION_ITEM + item.id}>
                <div> {item.icon}</div>
                {item.name !== "" && <div className="md:max-lg:hidden"> {item.name}</div>}
            </div>
        </Link>
    );
}
