import React, { useState } from "react";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import CounterBubble from "../CounterBubble";

export interface InnerNodeProps {
    className: string;
    name: string;
    icon: string;
    openForm: Function;
    addButton: boolean;
    quantity: number;
    id?: string;
}

export default function InnerNode({ className, name, icon, openForm, addButton, quantity, id }: InnerNodeProps) {
    const [isActive, setIsActive] = useState<boolean>(false);
    const hasMarble = addButton || quantity > 1;
    return (
        <div
            style={{ boxShadow: isActive ? "0 0 0 100vmax rgba(0, 0, 0, .3)" : "" }}
            className={
                "mb-1 flex min-h-[2.5rem] w-36 cursor-pointer items-center justify-between rounded-xl border border-white px-2 text-white lg:w-40" +
                " " +
                className
            }
            onDoubleClick={() => openForm()}
            onClick={() => (addButton ? openForm() : setIsActive(!isActive))}
            onMouseLeave={() => setIsActive(false)}
            data-testid={id}
        >
            <div className="flex items-center space-x-1 font-semibold">
                <img alt="OI" className="h-6" src={icon} />
                <div
                    lang="de"
                    className={hasMarble ? "w-16 lg:w-20" : "w-24 lg:w-28"}
                    style={{ wordWrap: "break-word", overflowWrap: "break-word", hyphens: "auto" }}
                >
                    {name}
                </div>
                {hasMarble && (
                    <div className="h-6 w-6">
                        {addButton ? (
                            <PlusCircleIcon className="cursor-pointer text-white" onClick={() => openForm(true)} />
                        ) : (
                            <CounterBubble counter={quantity} />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}
