import { BASE_URL, DOWNTIMES_ENDPOINT } from "../ApiConfig";
import { getToken } from "../../hook/UseToken";
import axios from "axios";
import { handleApiGetCall } from "../../utils/ApiUtils";

const downtimes = "Downtimes";
const endpoint = BASE_URL + DOWNTIMES_ENDPOINT;

export async function getDowntimes(lineId: number, start: string, getText: Function): Promise<any> {
    const response = axios.get(endpoint + "?line_id=" + lineId + "&until=" + start, getToken());
    return handleApiGetCall(response, downtimes, "with LineID:" + lineId, getText);
}

export async function getDowntimeItems(lineId: number, start: string, getText: Function): Promise<any> {
    const response = axios.get(endpoint + "?line_id=" + lineId + "&until=" + start + "&merged=True", getToken());
    return handleApiGetCall(response, downtimes, "with LineID:" + lineId, getText);
}
