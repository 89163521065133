import { ProductGroup } from "./ProductGroup";
import { ExternalMaterialGroup } from "./ExternalMaterialGroup";
import { Line } from "./Line";
import { Customer } from "./Customer";

export interface Production {
    id: number;
    name: string;
    user_id: number;
    product_groups: ProductGroup[];
    external_material_groups: ExternalMaterialGroup[];
    lines: Line[];
    customers: Customer[];
}

export interface ProductionListElement {
    id: number;
    name: string;
    product_groups_count: number;
    lines_count: number;
    customer_orders_count: number;
    production_orders_count: number;
}

export function createEmptyProduction(): Production {
    return {
        id: 0,
        name: "",
        user_id: 0,
        product_groups: [],
        external_material_groups: [],
        lines: [],
        customers: [],
    };
}
