import React from "react";

export interface CounterBubbleProps {
    counter: number;
}

export default function CounterBubble({ counter }: CounterBubbleProps): JSX.Element {
    return (
        <>
            {counter > 1 && (
                <div className="flex h-full w-full items-center justify-center rounded-full border-2 border-white text-xs text-white">
                    {counter}
                </div>
            )}
        </>
    );
}
