import { Popover, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { TestIds } from "../TestIds";

export interface PopoverProps {
    onClickElement: JSX.Element;
    children?: JSX.Element;
}

export default function Popup({ onClickElement, children }: PopoverProps) {
    return (
        <div data-testid={TestIds.POPUP}>
            <Popover className="relative focus:outline-none">
                {({ open }) => (
                    <>
                        <Popover.Button
                            className={`focus:outline-none ${open && " [&>*]:font-bold"}`}
                            data-testid={TestIds.OPEN_POPUP_BUTTON}
                        >
                            {onClickElement}
                        </Popover.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel className="absolute left-1/2 z-30 mt-3 w-fit max-w-sm -translate-x-1/2 transform rounded-lg border bg-white p-4 drop-shadow-lg lg:max-w-3xl">
                                <Popover.Button className="relative w-full text-gray-middle">
                                    <XCircleIcon className="absolute right-0 mr-[-0.5rem] mt-[-1.5rem] h-5 w-5 cursor-pointer" />
                                </Popover.Button>

                                <div className="mt-[-1.5rem] overflow-hidden">{children}</div>
                            </Popover.Panel>
                        </Transition>
                    </>
                )}
            </Popover>
        </div>
    );
}
