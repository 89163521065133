import React from "react";
import { TestIds } from "../../../../TestIds";
import { HEADER_HEIGHT, ROW_HEIGHT } from "../utils/TimelineUtils";
import { GeneralObjectType } from "../../../../api/models/GeneralTypes";

export interface TimelineLeftHeaderProps {
    title: string;
    rows: GeneralObjectType[];
    timelineHeight?: number;
}

export const TimelineLeftHeader = React.forwardRef<HTMLDivElement, TimelineLeftHeaderProps>(
    (
        { title, rows, timelineHeight = 0, ...optionalProperties }: TimelineLeftHeaderProps,
        ref: React.ForwardedRef<HTMLDivElement>
    ): JSX.Element => {
        function createRows(): JSX.Element[] {
            return rows.map((row: GeneralObjectType) => (
                <div
                    key={row.id}
                    lang="de"
                    className="flex items-center justify-center break-words px-4"
                    style={{
                        height: ROW_HEIGHT,
                        hyphens: "auto",
                    }}
                >
                    {row.name}
                </div>
            ));
        }

        return (
            <div
                className="border-r-4 border-schedule-stroke"
                style={{ height: timelineHeight + HEADER_HEIGHT }}
                data-testid={TestIds.TIMELINE_LEFT_HEADER}
            >
                <div className="flex items-end justify-center border-b-4" style={{ height: HEADER_HEIGHT }}>
                    <div className="px-4 pb-4 text-base">{title}</div>
                </div>
                <div
                    className="w-36 overflow-hidden"
                    ref={ref}
                    style={{
                        height: timelineHeight,
                    }}
                >
                    {createRows()}
                </div>
            </div>
        );
    }
);
