import React from "react";
import logo from "../../assets/images/amexis.png";
import { useForm } from "react-hook-form";
import loginPost from "../../api/calls/Login";
import { toast } from "react-toastify";
import Button, { ButtonSize, ButtonTypes } from "../../components/buttons/Button";
import { LockClosedIcon } from "@heroicons/react/24/outline";
import { TestIds } from "../../TestIds";
import { useText } from "../../context/LanguageContext";
import TextIds from "../../language/TextIds";

export interface LoginData {
    username: string;
    password: string;
}

export interface LoginProps {
    setToken: Function;
}

export function Login({ setToken }: LoginProps) {
    const getText = useText();
    const { register, handleSubmit } = useForm<LoginData>();

    const onSubmit = handleSubmit((loginData: LoginData) => {
        loginPost(loginData)
            .then((value) => {
                setToken(value.data["access_token"]);
                toast.success(getText(TextIds.Login.SUCCESSFUL));
            })
            .catch(() => getFailedLoginBox());
    });

    function getFailedLoginBox() {
        toast.error(`${getText(TextIds.Login.FAILED)}\n ${getText(TextIds.Login.CREDENTIALS)}`);
    }

    return (
        <div className="flex h-screen items-center justify-center">
            <div className="w-full max-w-md rounded-lg p-8 shadow-xl md:max-w-lg">
                <div>
                    <img className="mx-auto h-12 w-auto" src={logo} alt="Amexis" />
                    <h1 className="mt-6 text-center font-extrabold text-gray-dark">{getText(TextIds.Login.SIGN_IN)}</h1>
                </div>

                <form className="mt-8 space-y-6" onSubmit={(e) => onSubmit(e)}>
                    <div className="space-y-4 rounded-md shadow-sm">
                        <div>
                            <label htmlFor="username" className="sr-only">
                                {getText(TextIds.Login.USERNAME)}
                            </label>
                            <input
                                {...register("username")}
                                data-testid={TestIds.INPUT_USERNAME}
                                name="username"
                                type="text"
                                autoComplete="username"
                                required
                                className="relative block w-full appearance-none rounded-md border border-gray-light px-3 py-2 text-gray-middle placeholder-gray-light focus:z-10 focus:border-green-middle focus:outline-none focus:ring-green-middle"
                                placeholder={getText(TextIds.Login.USERNAME)}
                            />
                        </div>
                        <div>
                            <label htmlFor="password" className="sr-only">
                                {getText(TextIds.Login.PASSWORD)}
                            </label>
                            <input
                                {...register("password")}
                                data-testid={TestIds.INPUT_PASSWORD}
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                required
                                className="relative block w-full appearance-none rounded-md border border-gray-light px-3 py-2 text-gray-light placeholder-gray-light focus:z-10 focus:border-green-middle focus:outline-none focus:ring-green-middle"
                                placeholder={getText(TextIds.Login.PASSWORD)}
                            />
                        </div>
                    </div>
                    <div className="flex justify-center">
                        <Button
                            title={getText(TextIds.Login.SIGN_IN)}
                            icon={<LockClosedIcon />}
                            buttonType={ButtonTypes.Submit}
                            buttonSize={ButtonSize.large}
                            onClick={() => onSubmit}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
}
