import { GRAY_MIDDLE } from "../colors";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { GeneralObjectType } from "../api/models/GeneralTypes";
import { TestIds } from "../TestIds";
import useClickPreventionOnDoubleClick from "../hook/ClickPrevention";
import { getIndex } from "../utils/FilterUtils";

interface TabProps {
    elements: GeneralObjectType[];
    defaultElement: GeneralObjectType;
    selectedTabColor?: string;
    setSelectedElement: Function;
    children?: JSX.Element;
    onDoubleClick?: Function;

    [optionalProperties: string]: any;
}

export default function Tabs({
    elements,
    selectedTabColor = GRAY_MIDDLE,
    setSelectedElement,
    children,
    defaultElement,
    onDoubleClick,
    ...optionalProperties
}: TabProps): JSX.Element {
    const [selectedTab, setSelectedTab] = useState<GeneralObjectType>(defaultElement);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [enabledElements, setEnabledElements] = useState<GeneralObjectType[]>([]);

    useEffect(() => {
        setSelectedTab(defaultElement);
        setSelectedIndex(getIndex(defaultElement.id, enabledElements, "id"));
    }, [defaultElement, enabledElements, setSelectedIndex, setSelectedTab]);

    useEffect(() => {
        setEnabledElements(elements.filter((element) => !element.disabled));
    }, [elements]);

    const chevronLeftClassname = useMemo(() => {
        return selectedIndex === 0 || enabledElements.length === 0
            ? "text-gray-light cursor-default"
            : "text-black cursor-pointer";
    }, [elements, selectedIndex]);

    const chevronRightClassname = useMemo(() => {
        return selectedIndex === enabledElements.length - 1
            ? "text-gray-light cursor-default"
            : "text-black cursor-pointer";
    }, [enabledElements, selectedIndex]);

    const setSelected = useCallback(
        (element: GeneralObjectType) => {
            setSelectedTab(element);
            setSelectedElement(element);
        },
        [setSelectedTab, setSelectedElement]
    );

    const nextItem = useCallback(() => {
        if (selectedIndex === enabledElements.length - 1) return;

        setSelected(enabledElements[selectedIndex + 1]);
        setSelectedIndex(selectedIndex + 1);
    }, [selectedIndex, enabledElements, setSelected]);

    const previousItem = useCallback(() => {
        if (selectedIndex === 0 || enabledElements.length === 0) return;

        setSelected(enabledElements[selectedIndex - 1]);
        setSelectedIndex(selectedIndex - 1);
    }, [selectedIndex, enabledElements, setSelected]);

    return (
        <div className="w-full">
            <div className="flex w-full select-none flex-row space-x-1 pr-2">
                <div className="-mb-4 flex h-12 select-none items-center justify-around space-x-2 rounded-tl-lg border border-gray-light bg-gray-very-light px-2 py-4 text-black">
                    <ChevronLeftIcon className={`h-4 ${chevronLeftClassname}`} onClick={() => previousItem()} />
                    <ChevronRightIcon className={`h-4 ${chevronRightClassname}`} onClick={() => nextItem()} />
                </div>
                <div className="flex w-full flex-row space-x-1 overflow-x-auto" data-testid={TestIds.TABS}>
                    {elements.map((element: GeneralObjectType) => (
                        <TabItem
                            key={element.id}
                            element={element}
                            onClick={setSelected}
                            onDoubleClick={onDoubleClick}
                            active={selectedTab.id === element.id}
                            selectedTabColor={selectedTabColor}
                        />
                    ))}
                </div>
            </div>
            <div className="z-10 h-full w-full rounded-xl border border-gray-light bg-gray-bright drop-shadow-lg">
                <div {...optionalProperties}>{children}</div>
            </div>
        </div>
    );
}

interface TabItemProps {
    element: GeneralObjectType;
    onClick: Function;
    onDoubleClick?: Function;
    active: boolean;
    selectedTabColor: string;
}

function TabItem({ element, onClick, onDoubleClick, active, selectedTabColor }: TabItemProps): JSX.Element {
    const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(onClick, onDoubleClick, element);

    const activeClass = active ? `font-semibold text-white ` : "bg-gray-very-light";
    const tabClass = "min-w-fit flex h-10 shrink-0 items-center justify-center rounded-t-lg p-4";
    const className = `${activeClass} cursor-pointer ${tabClass}`;

    if (element.disabled)
        return (
            <div key={element.id} className={tabClass}>
                {element.name}
            </div>
        );

    return (
        <div
            key={element.id}
            style={active ? { backgroundColor: selectedTabColor } : {}}
            className={className}
            onClick={handleClick}
            onDoubleClick={handleDoubleClick}
            data-testid={TestIds.TAB + element.id}
        >
            {element.name}
        </div>
    );
}
