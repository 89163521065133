import Loader from "./Loader";
import React from "react";
import { useText } from "../context/LanguageContext";
import TextIds from "../language/TextIds";
import { usePromiseTracker } from "react-promise-tracker";
import { TestIds } from "../TestIds";

export default function LoadScreen(): JSX.Element {
    const getText = useText();

    const { promiseInProgress } = usePromiseTracker();
    if (!promiseInProgress) return <></>;

    return (
        <div
            className="absolute z-50 flex h-screen w-screen flex-col items-center justify-center space-y-2 bg-gray-dark/50"
            data-testid={TestIds.LOADER}
        >
            <Loader className="relative h-24 w-24" />
            <span className="visually-hidden font-bold text-gray-dark">{getText(TextIds.LOADING)}...</span>
        </div>
    );
}
