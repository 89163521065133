export default class TextIds {
    static Production = class {
        static readonly NAME = "production";
        static readonly PLURAL_NAME = "productions";
        static readonly NONE = "noProduction";
        static readonly ADD = "addProduction";
        static readonly EDIT = "editProduction";
        static readonly FORM_SUBTITLE = "enterProduction";
        static readonly DELETE = "deleteProduction";
        static readonly IDENTICAL = "productionIdentical";
        static readonly FETCH_FAILED = "fetchProductionsFailed";
    };

    static Line = class {
        static readonly NAME = "line";
        static readonly PLURAL_NAME = "lines";
        static readonly NONE = "noLine";
        static readonly ADD = "addLine";
        static readonly EDIT = "editLine";
        static readonly FORM_SUBTITLE = "enterLine";
        static readonly DELETE = "deleteLine";
        static readonly SELECT = "selectLine";
        static readonly IDENTICAL = "lineIdentical";
    };

    static Station = class {
        static readonly NAME = "station";
        static readonly PLURAL_NAME = "stations";
        static readonly NONE = "noStation";
        static readonly ADD = "addStation";
        static readonly EDIT = "editStation";
        static readonly FORM_SUBTITLE = "enterStation";
        static readonly DELETE = "deleteStation";
        static readonly PREDECESSORS = "predecessors";
        static readonly IDENTICAL = "stationIdentical";
    };

    static Operation = class {
        static readonly NAME = "operation";
        static readonly PLURAL_NAME = "operations";
        static readonly NONE = "noOperation";
        static readonly ADD = "addOperation";
        static readonly EDIT = "editOperation";
        static readonly FORM_SUBTITLE = "enterOperation";
        static readonly DELETE = "deleteOperation";
        static readonly IDENTICAL = "operationIdentical";
    };

    static ProductGroup = class {
        static readonly NAME = "productGroup";
        static readonly PLURAL_NAME = "productGroups";
        static readonly PRE = "preProductGroups";
        static readonly END = "endProductGroups";
        static readonly NONE = "noProductGroup";
        static readonly ADD = "addProductGroup";
        static readonly EDIT = "editProductGroup";
        static readonly FORM_SUBTITLE = "enterProductGroup";
        static readonly DELETE = "deleteProductGroup";
        static readonly IDENTICAL = "productGroupIdentical";
        static readonly NO_SELECTION = "noProductGroupSelected";
    };

    static Product = class {
        static readonly NAME = "product";
        static readonly PLURAL_NAME = "products";
        static readonly PRE_PLURAL_NAME = "preProducts";
        static readonly END_PLURAL_NAME = "endProducts";
        static readonly PRE = "preProduct";
        static readonly END = "endProduct";
        static readonly NONE = "noProduct";
        static readonly ADD = "addProduct";
        static readonly EDIT = "editProduct";
        static readonly FORM_SUBTITLE = "enterProduct";
        static readonly DELETE = "deleteProduct";
        static readonly IDENTICAL = "productIdentical";
        static readonly NO_SELECTION = "noProductSelected";
    };

    static ExternalMaterialGroup = class {
        static readonly NAME = "externalMaterialGroup";
        static readonly PLURAL_NAME = "externalMaterialGroups";
        static readonly NONE = "noExternalMaterialGroup";
        static readonly ADD = "addExternalMaterialGroup";
        static readonly EDIT = "editExternalMaterialGroup";
        static readonly FORM_SUBTITLE = "enterExternalMaterialGroup";
        static readonly DELETE = "deleteExternalMaterialGroup";
        static readonly IDENTICAL = "externalMaterialGroupIdentical";
        static readonly NO_SELECTION = "noExternalMaterialGroupSelected";
    };

    static ExternalMaterial = class {
        static readonly NAME = "externalMaterial";
        static readonly PLURAL_NAME = "externalMaterials";
        static readonly NONE = "noExternalMaterial";
        static readonly ADD = "addExternalMaterial";
        static readonly EDIT = "editExternalMaterial";
        static readonly FORM_SUBTITLE = "enterExternalMaterial";
        static readonly DELETE = "deleteExternalMaterial";
        static readonly SELECT = "selectExternalMaterial";
        static readonly IDENTICAL = "externalMaterialIdentical";
    };

    static Customer = class {
        static readonly NAME = "customer";
        static readonly PLURAL_NAME = "customers";
        static readonly NONE = "noCustomer";
        static readonly ADD = "addCustomer";
        static readonly EDIT = "editCustomer";
        static readonly FORM_SUBTITLE = "enterCustomer";
        static readonly DELETE = "deleteCustomer";
        static readonly SELECT = "selectCustomer";
        static readonly IDENTICAL = "customerIdentical";
        static readonly NO_SELECTION = "noCustomerSelected";
    };

    static CustomerOrder = class {
        static readonly NAME = "customerOrder";
        static readonly PLURAL_NAME = "customerOrders";
        static readonly NONE = "noCustomerOrder";
        static readonly ADD = "addCustomerOrder";
        static readonly EDIT = "editCustomerOrder";
        static readonly FORM_SUBTITLE = "enterCustomerOrder";
        static readonly DELETE = "deleteCustomerOrder";
        static readonly IDENTICAL = "customerOrderIdentical";
    };

    static ProductionOrder = class {
        static readonly NAME = "productionOrder";
        static readonly PLURAL_NAME = "productionOrders";
        static readonly NONE = "noProductionOrder";
        static readonly DELETE = "deleteProductionOrder";
        static readonly PRODUCT_ID = "productId";
        static readonly PRODUCT_NAME = "productName";
    };

    static Downtime = class {
        static readonly NAME = "downtime";
        static readonly PLURAL_NAME = "downtimes";
        static readonly NONE = "noDowntimes";
        static readonly FORM_SUBTITLE = "enterDowntime";
        static readonly DELETE = "deleteDowntime";
        static readonly ADD = "addDowntime";
        static readonly EDIT = "editDowntime";
        static readonly SHOW = "showDowntime";
    };

    static Form = class {
        static readonly INFORMATION = "information";
        static readonly NAME = "name";
        static readonly ID = "id";
        static readonly EMAIL = "email";
        static readonly DESCRIPTION = "description";
        static readonly TYPE = "type";
        static readonly QUANTITY = "quantity";
        static readonly DURATION = "duration";
        static readonly END_HOUR = "endHour";
        static readonly START_HOUR = "startHour";
        static readonly DELIVERY_DATE = "deliveryDate";
        static readonly DATE = "date";
        static readonly END_DATE = "endDate";
        static readonly END = "end";
        static readonly START_DATE = "startDate";
        static readonly START = "start";
        static readonly INITIAL_DATE = "initialDate";
        static readonly TIME_FORMAT = "timeFormat";
        static readonly SHIFT_SCHEDULE = "shiftSchedule";
        static readonly WORK_HOURS = "workHours";
        static readonly IS_ALL_DAY = "isAllDay";
        static readonly HORIZON = "horizon";
        static readonly FREEZE = "freeze";

        static readonly EMPTY_SELECT = "emptySelect";
        static readonly NO_SELECTION = "noSelection";
        static readonly NOTHING_SELECTED = "nothingSelected";

        static readonly CANCEL = "cancel";
        static readonly CONFIRM = "confirm";
        static readonly RESET = "reset";
        static readonly SAVE = "save";
        static readonly DELETE = "delete";
        static readonly EDIT = "edit";

        static readonly NAME_ALLOWED_CHARACTER = "stringInputAllowed";
        static readonly NUMBER_INPUT_ALLOWED = "numberInputAllowed";
        static readonly TIME_FORMAT_MISMATCH = "timeFormatValid";
        static readonly DATE_INPUT_ALLOWED = "dateInputAllowed";
        static readonly DATE_NEEDED = "dateNeeded";
        static readonly INITIAL_DATE_BEFORE_NOW = "initialDateBeforeNow";
        static readonly DESCRIPTION_NEEDED = "descriptionNeeded";
        static readonly DAILY_START_END_INVALID = "dailyStartEndValid";
        static readonly TIME_FORMAT_ALLOWED = "timeFormatAllowed";
        static readonly NOT_VALID_DATE = "notValidDate";
        static readonly GETS_RESCHEDULED = "getsRescheduled";
        static readonly CHECK_SAVE = "checkSave";
        static readonly COUNTER_ERROR = "counterError";
    };

    static Navigation = class {
        static readonly HOME = "home";
        static readonly ORDERS = "orders";
        static readonly OPERATOR = "operator";
        static readonly OPERATORS = "operators";
        static readonly REPORTING = "reporting";
        static readonly SCHEDULE = "schedule";
        static readonly FEEDBACK = "feedback";
        static readonly BOM = "bom";
        static readonly VIEW_MODE = "viewMode";
        static readonly MATERIAL = "material";
        static readonly PROCESS = "process";
        static readonly MATERIAL_PRODUCT = "materialProduct";
    };

    static Time = class {
        static readonly NOW = "now";
        static readonly WEEK = "week";
        static readonly TODAY = "today";
        static readonly CW = "cw";
        static readonly DAY = "day";
        static readonly MONTH = "month";
    };

    static Days = class {
        static readonly MONDAY = "monday";
        static readonly TUESDAY = "tuesday";
        static readonly WEDNESDAY = "wednesday";
        static readonly THURSDAY = "thursday";
        static readonly FRIDAY = "friday";
        static readonly SATURDAY = "saturday";
        static readonly SUNDAY = "sunday";
    };

    static Status = class {
        static readonly READY = "READY";
        static readonly SCHEDULED = "SCHEDULED";
        static readonly RUNNING = "RUNNING";
        static readonly DONE = "DONE";
        static readonly BLOCKED = "BLOCKED";
        static readonly STOPPED = "STOPPED";
        static readonly DELAY = "DELAY";
        static readonly DELAYED = "DELAYED";
        static readonly TIGHT = "TIGHT";
        static readonly DELIVERY_STATUS = "DELIVERY_STATUS";
        static readonly FINISHED = "FINISHED";
        static readonly ON_RISK = "ON_RISK";
        static readonly IN_TIME = "IN_TIME";
        static readonly STATUS = "STATUS";
        static readonly SINGLE = "SINGLE";
        static readonly SUBSCRIPTION = "SUBSCRIPTION";
        static readonly PRE = "PRE";
        static readonly END = "END";
    };

    static Login = class {
        static readonly USERNAME = "username";
        static readonly PASSWORD = "password";
        static readonly SIGN_IN = "signIn";
        static readonly SUCCESSFUL = "loginSuccessful";
        static readonly FAILED = "loginFailed";
        static readonly CREDENTIALS = "loginCredentials";
        static readonly LOGOUT = "logout";
        static readonly FORGOT_PASSWORD = "forgotPassword";
        static readonly ENTER_EMAIL = "enterEmail";
        static readonly ENTER_NEW_PASSWORD = "enterNewPassword";
        static readonly RESET_PASSWORD = "resetPassword";
        static readonly CREATE_ACCOUNT = "createAccount";
        static readonly CHANGE_PASSWORD = "changePassword";
        static readonly CONFIRM_PASSWORD = "confirmPassword";
    };

    static Feedback = class {
        static readonly FEEDBACK_HEADER = "feedbackHeader";
        static readonly FEEDBACK_SUPPORT = "support";
        static readonly FEEDBACK_SUBJECT = "subject";
    };

    static Schedule = class {
        static readonly CALCULATE = "calculate";
        static readonly CAPACITY = "capacity";
        static readonly CREATE = "createSchedule";
        static readonly METHOD = "scheduleMethod";
        static readonly MAKESPAN = "makespan";
    };

    static readonly TO = "to";
    static readonly ORDER = "order";
    static readonly NOT_OPTIMISED = "notOptimised";
    static readonly OPTIMISED = "optimised";
    static readonly STANDARD = "standard";
    static readonly PER_ORDER = "perOrder";
    static readonly PO = "po";
    static readonly GROUP = "group";
    static readonly PO_INFOS = "poInfos";
    static readonly ROWS = "rows";
    static readonly SHOW = "show";
    static readonly TOTAL_SAVINGS = "totalSavings";
    static readonly DATE_FORMAT_DE = "dateFormatDe";
    static readonly TOTAL = "total";
    static readonly ENTRIES = "entries";
    static readonly LOADING = "loading";
    static readonly SURE_CONFIRM = "sureConfirm";
    static readonly OPS = "ops";
    static readonly PAGE_NOT_FOUND = "notFound";
    static readonly PAGE_NOT_EXIST = "notExist";
    static readonly GO_HOME = "goHome";
    static readonly NO_RESPONSE = "noResponse";
}
