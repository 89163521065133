import { ProductGroup } from "./ProductGroup";
import { getUniqueVersionOfArray } from "../../utils/FilterUtils";

export interface Product {
    id: number;
    name: string;
    internal_id?: string;
    operations: number[];
    external_materials: number[];
    pre_products: number[];
    product_group_id: number;
}

export function createEmptyProduct(): Product {
    return {
        id: 0,
        name: "",
        internal_id: "",
        operations: [],
        external_materials: [],
        pre_products: [],
        product_group_id: 0,
    };
}

export function getProductsOfGroups(productGroups: ProductGroup[]): Product[] {
    let preProducts: Product[] = [];
    productGroups.forEach((preProductGroup) => preProducts.push(...preProductGroup.products));
    return preProducts;
}

export function getPreProductsDeepSearch(product: Product, productionProducts: Product[]): Product[] {
    let products: Product[] = [];

    for (const preProductId of product.pre_products) {
        const product = productionProducts.find((product) => product.id === preProductId);
        if (product) {
            products.push(product);
            if (product.pre_products.length > 0)
                products.push(...getPreProductsDeepSearch(product, productionProducts));
        }
    }

    return getUniqueVersionOfArray(products);
}

export function getProductQuantityBySuccessorGroup(productGroup: ProductGroup, product: Product): number {
    let productQuantity = productGroup.pre_product_groups.find(
        (value) => value.pre_product_group_id === product.product_group_id
    );
    return productQuantity ? productQuantity.quantity : 0;
}
