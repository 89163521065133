import { useEffect, useState } from "react";

interface WindowDimensions {
    width: number;
    height: number;
}

function getWindowDimensions(): WindowDimensions {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

export default function useWindowDimensions(): WindowDimensions {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}

export function isWindowXSmall(): boolean {
    return getWindowDimensions().width < 255;
}

export function isWindowSmall(): boolean {
    return getWindowDimensions().width < 640 && getWindowDimensions().width >= 255;
}

export function isWindowMiddle(): boolean {
    return getWindowDimensions().width < 768 && getWindowDimensions().width >= 640;
}

export function isWindowLarge(): boolean {
    return getWindowDimensions().width < 1024 && getWindowDimensions().width >= 768;
}

export function isWindowXLarge(): boolean {
    return getWindowDimensions().width >= 1024;
}

export function isWindowHeightLarge(): boolean {
    return getWindowDimensions().height > 950;
}
