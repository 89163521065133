import React, { useEffect, useState } from "react";
import { useProduction } from "../../../context/ProductionContext";
import Tabs from "../../../components/Tabs";
import { sortByIdAsc } from "../../../utils/SortUtils";
import { createFlowForLineModeling } from "../../../utils/flow/ProcessFlowUtils";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import StationForm from "../../../components/forms/station/StationForm";
import LineForm from "../../../components/forms/line/LineForm";
import { createEmptyLine, Line } from "../../../api/models/Line";
import { createEmptyStation } from "../../../api/models/Station";
import { TestIds } from "../../../TestIds";
import { useText } from "../../../context/LanguageContext";
import TextIds from "../../../language/TextIds";
import { findObjectByFieldInList } from "../../../utils/FilterUtils";

export default function ProcessLineModeling(): JSX.Element {
    const getText = useText();
    const production = useProduction();

    const [selectedLine, setSelectedLine] = useState<Line>(createEmptyLine);
    const [flow, setFlow] = useState<JSX.Element>();
    const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

    const [isStationFormOpen, setIsStationFormOpen] = useState<boolean>(false);
    const [isLineFormOpen, setIsLineFormOpen] = useState<boolean>(false);
    const [formLine, setFormLine] = useState<Line>(createEmptyLine);

    useEffect(() => {
        if (production.lines.length === 0) return;

        const line = findObjectByFieldInList(selectedLine.id, "id", production.lines);
        setSelectedLine(line);
    }, [production, setSelectedLine]);

    useEffect(() => {
        if (selectedLine.stations.length !== 0) {
            setFlow(createFlowForLineModeling(production, selectedLine, isFullscreen, setIsFullscreen));
            return;
        }
        setFlow(
            <div>
                <div className="p-4">{getText(TextIds.Station.NONE)}</div>
            </div>
        );
    }, [selectedLine, isFullscreen, production, getText]);

    function createAddButton(element: string, onClick: Function, id: string): JSX.Element {
        return (
            <div
                className="flex h-8 w-36 cursor-pointer items-center justify-between rounded-xl bg-gray-very-light px-4 font-semibold drop-shadow-xl"
                onClick={() => onClick()}
                data-testid={id}
            >
                {element}
                <PlusCircleIcon className="h-6 w-6" />
            </div>
        );
    }

    function openEditLineForm(line: Line) {
        setFormLine(line);
        setIsLineFormOpen(true);
    }

    if (production.lines.length === 0)
        return (
            <div className="flex justify-center">
                <div className="flex flex-col text-center">
                    <p>{getText(TextIds.Line.NONE)}</p>
                    <div className="flex flex-row justify-center">
                        <div
                            className="flex h-10 w-fit cursor-pointer select-none justify-between truncate rounded-lg border border-gray-light p-2"
                            onClick={() => openEditLineForm(createEmptyLine())}
                            data-testid={TestIds.ADD_LINE_BUTTON}
                        >
                            {getText(TextIds.Line.NAME)}
                            <PlusCircleIcon className="ml-2 h-6 text-gray-middle" />
                        </div>
                    </div>
                </div>
                <LineForm selectedLine={createEmptyLine()} setOpen={setIsLineFormOpen} open={isLineFormOpen} />
            </div>
        );
    return (
        <div className="flex space-x-8">
            <div className="grow">
                <Tabs
                    elements={production.lines.sort(sortByIdAsc)}
                    defaultElement={selectedLine}
                    setSelectedElement={setSelectedLine}
                    onDoubleClick={openEditLineForm}
                >
                    {flow}
                </Tabs>
            </div>
            <div className="mt-10 space-y-4">
                {createAddButton(
                    getText(TextIds.Line.NAME),
                    () => openEditLineForm(createEmptyLine()),
                    TestIds.ADD_LINE_BUTTON
                )}
                {createAddButton(
                    getText(TextIds.Station.NAME),
                    () => setIsStationFormOpen(true),
                    TestIds.ADD_STATION_BUTTON
                )}
            </div>
            <LineForm selectedLine={formLine} setOpen={setIsLineFormOpen} open={isLineFormOpen} />
            <StationForm
                selectedStation={createEmptyStation()}
                open={isStationFormOpen}
                setOpen={setIsStationFormOpen}
                selectedLine={selectedLine}
            />
        </div>
    );
}
