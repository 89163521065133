import React from "react";
import useWindowDimensions, { isWindowMiddle, isWindowSmall, isWindowXSmall } from "../../utils/WindowUtils";
import { TestIds } from "../../TestIds";

export enum ButtonTypes {
    Cancel,
    Submit,
    Div,
}

export enum ButtonSize {
    small,
    middle,
    large,
}

export interface ButtonProps {
    title: string;
    icon: JSX.Element;
    buttonType: ButtonTypes;
    buttonSize: ButtonSize;
    onClick?: Function;
    classNames?: string;
}

export default function Button({
    title,
    buttonType,
    buttonSize,
    icon,
    onClick = () => {},
    classNames = "",
}: ButtonProps) {
    useWindowDimensions();

    function sizeClasses(): string {
        let classes = ["w-9", "h-9", "font-medium", "border-none"];
        if (buttonSize >= 1) classes.push(...["xs:min-w-fit", "xs:w-48", "xs:h-10", "shadow-lg"]);
        if (buttonSize === 2) classes.push(...["md:h-10", "md:w-full"]);
        return classes.join(" ");
    }

    function colorClasses(): string {
        let classes = ["justify-center", "rounded-lg", "shadow-lg"];

        if (buttonType === ButtonTypes.Cancel)
            classes.push(
                ...[
                    "border",
                    "border-gray-light",
                    "text-gray-middle",
                    "hover:bg-gray-middle",
                    "hover:text-white",
                    "focus:rounded-lg",
                    "focus:outline-none",
                    "focus:ring",
                    "focus:ring-gray-middle",
                ]
            );
        else
            classes.push(
                ...[
                    "bg-green-middle",
                    "text-white",
                    "hover:bg-green-hover",
                    "focus:border-green-focus",
                    "focus:rounded-lg",
                    "focus:outline-none",
                    "focus:ring",
                    "focus:ring-green-light",
                ]
            );

        return classes.join(" ");
    }

    function buttonTitle() {
        if (buttonSize === 0 || isWindowXSmall()) return <div className="h-7 w-7 stroke-1">{icon}</div>;
        if (buttonSize === 1 || isWindowSmall() || isWindowMiddle())
            return <div className="text-middle mx-2 p-1.5">{title}</div>;
        return (
            <>
                <div className="text-middle mx-2 p-1.5"> {title}</div>
                <div className="h-7 w-7 stroke-1"> {icon} </div>
            </>
        );
    }

    function createButtons(): JSX.Element {
        if (buttonType === ButtonTypes.Div)
            return (
                <div
                    className={sizeClasses() + " " + colorClasses() + " " + classNames}
                    onClick={() => onClick()}
                    data-testid={TestIds.DIV_BUTTON}
                >
                    <div className="flex items-center justify-center">{buttonTitle()}</div>
                </div>
            );

        let type: "submit" | "button" | "reset" | undefined = "submit";
        let testId = TestIds.SUBMIT_BUTTON;

        if (buttonType === ButtonTypes.Cancel) {
            type = "button";
            testId = TestIds.CANCEL_BUTTON;
        }
        return (
            <button
                type={type}
                className={sizeClasses() + " " + colorClasses() + " " + classNames}
                onClick={() => onClick()}
                data-testid={testId}
            >
                <div className="flex items-center justify-center">{buttonTitle()}</div>
            </button>
        );
    }

    return createButtons();
}
