import React from "react";
import { Dialog } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { TestIds } from "../TestIds";

export interface ModalProps {
    title: string;
    description: string;
    open: boolean;
    setOpen: Function;
    children?: JSX.Element | JSX.Element[];
}

export default function Modal({ title, description, open, children, setOpen }: ModalProps) {
    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <div className="fixed inset-0 z-100 bg-black bg-opacity-90" />
            <div className="fixed inset-0 z-100 flex items-center justify-center" data-testid={TestIds.MODAL}>
                <Dialog.Panel className="w-full max-w-2xl transform rounded-lg bg-white p-6">
                    <Dialog.Title className="flex justify-between text-3xl font-bold text-green-middle">
                        {title}
                        <XCircleIcon
                            className="mr-[-1rem] mt-[-1rem] h-8 w-8 cursor-pointer text-gray-middle"
                            onClick={() => setOpen(false)}
                            data-testid={TestIds.CLOSE_BUTTON}
                        />
                    </Dialog.Title>
                    <Dialog.Description className="text-md ml-2 mt-1 text-gray-middle">
                        {description}
                    </Dialog.Description>
                    <div className="pt-8">{children}</div>
                </Dialog.Panel>
            </div>
        </Dialog>
    );
}
