import React, { useCallback, useEffect, useState } from "react";
import ProductGroupNode from "../../../components/flow/ProductGroupNode";
import ExtMaterialGroupNode from "../../../components/flow/ExtMaterialGroupNode";
import { useProduction } from "../../../context/ProductionContext";
import Tabs from "../../../components/Tabs";
import { useProductGroups, useSelectedProductGroup, useUpdateProductGroup } from "../../../context/ProductGroupContext";
import {
    createAllPreProductGroups,
    createFlowForProductModeling,
    createNodesAndEdgesForProductGroup,
} from "../../../utils/flow/ProductFlowUtils";
import {
    createEmptyProductGroup,
    getPreProductGroupsDeepSearch,
    ProductGroup,
    ProductType,
} from "../../../api/models/ProductGroup";
import { createEmptyExtMaterialGroup } from "../../../api/models/ExternalMaterialGroup";
import ProductGroupForm from "../../../components/forms/ProductGroupForm";
import { useText } from "../../../context/LanguageContext";
import TextIds from "../../../language/TextIds";

export interface ProductGroupModelingProps {
    selectableProductGroups: ProductGroup[];
    tabColor: string;
}

export default function ProductGroupModeling({
    selectableProductGroups,
    tabColor,
}: ProductGroupModelingProps): JSX.Element {
    const getText = useText();
    const production = useProduction();
    const preProductGroups = useProductGroups().preProductGroups;

    const [flow, setFlow] = useState<JSX.Element>(<></>);
    const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

    const selectedProductGroup = useSelectedProductGroup();
    const setSelectedProductGroup = useUpdateProductGroup();
    const [selectablePreProductGroups, setSelectablePreProductGroups] = useState<ProductGroup[]>([]);
    const [selectedPreProductGroup, setSelectedPreProductGroup] = useState<ProductGroup>(createEmptyProductGroup);

    const [isProductGroupFormOpen, setIsProductGroupFormOpen] = useState<boolean>(false);
    const [formProductGroup, setFormProductGroup] = useState<ProductGroup>(createEmptyProductGroup);

    const isSelectedProductGroupInSelectableProductGroups = selectableProductGroups.some(
        (productGroup) => productGroup.id === selectedProductGroup?.id
    );
    const setProductGroup = useCallback(
        (productGroup: ProductGroup) => setSelectedProductGroup(productGroup),
        [setSelectedProductGroup]
    );
    const getFlowOption = useCallback(
        (flowElement: ProductGroup) => {
            return {
                production,
                element: flowElement,
                selectableElements: selectablePreProductGroups,
                defaultSelectedElement: selectedPreProductGroup,
                setSelectedElement: (productGroup: ProductGroup) => setSelectedPreProductGroup(productGroup),
                createNodesAndEdges: createNodesAndEdgesForProductGroup,
                getAll: createAllPreProductGroups,
                isFullscreen,
                setIsFullscreen,
            };
        },
        [production, isFullscreen, selectablePreProductGroups, selectedPreProductGroup]
    );
    useEffect(() => {
        if (selectableProductGroups.length === 0) {
            setFlow(<div className="p-4">{getText(TextIds.ProductGroup.NONE)}</div>);
            setSelectedProductGroup(createEmptyProductGroup());
            return;
        }
        if (!isSelectedProductGroupInSelectableProductGroups) setSelectedProductGroup(selectableProductGroups[0]);
        // eslint-disable-next-line
    }, [selectableProductGroups, isSelectedProductGroupInSelectableProductGroups]);

    useEffect(() => {
        let productGroup = getPreProductGroupsDeepSearch(selectedProductGroup, preProductGroups).find(
            (productGroup) => productGroup.id === selectedPreProductGroup.id
        );

        if (!productGroup) {
            productGroup = { ...selectedProductGroup, id: 0, name: getText(TextIds.Form.NO_SELECTION) };

            let groups: ProductGroup[] = [productGroup];
            groups.push(...getPreProductGroupsDeepSearch(selectedProductGroup, preProductGroups));
            setSelectablePreProductGroups(groups);
        }
        setSelectedPreProductGroup(productGroup);
        // eslint-disable-next-line
    }, [selectedProductGroup, preProductGroups, selectedPreProductGroup.id]);

    useEffect(() => {
        if (selectedProductGroup.id === 0 && selectedPreProductGroup.id === 0) {
            setFlow(<div className="p-4">{getText(TextIds.ProductGroup.NONE)}</div>);
            return;
        }

        let flowElement = selectedPreProductGroup;
        if (selectedPreProductGroup.id === 0) flowElement = selectedProductGroup;

        setFlow(createFlowForProductModeling(getFlowOption(flowElement)));
        // eslint-disable-next-line
    }, [selectedPreProductGroup, getFlowOption]);

    function getAddProductGroupButton(name: string, type: ProductType): JSX.Element {
        return (
            <ProductGroupNode
                productGroup={{ ...createEmptyProductGroup(), name: name, product_type: type }}
                addButton={true}
            />
        );
    }

    function getAddExtMaterialGroupButton(name: string): JSX.Element {
        return (
            <ExtMaterialGroupNode
                extMaterialGroup={{ ...createEmptyExtMaterialGroup(), name: name }}
                addButton={true}
            />
        );
    }

    function openEditProductGroupForm(productGroup: ProductGroup) {
        setFormProductGroup(productGroup);
        setIsProductGroupFormOpen(true);
    }

    return (
        <div className="flex w-full space-x-8">
            <div className="w-full grow">
                <Tabs
                    elements={selectableProductGroups}
                    setSelectedElement={setProductGroup}
                    defaultElement={selectedProductGroup}
                    selectedTabColor={tabColor}
                    onDoubleClick={(productGroup: ProductGroup) => openEditProductGroupForm(productGroup)}
                >
                    {flow}
                </Tabs>
            </div>
            <div className="mt-10 space-y-2">
                {getAddProductGroupButton(getText(TextIds.Product.END), ProductType.END)}
                {getAddProductGroupButton(getText(TextIds.Product.PRE), ProductType.PRE)}
                {getAddExtMaterialGroupButton(getText(TextIds.ExternalMaterial.NAME))}
            </div>
            <ProductGroupForm
                selectedProductGroup={formProductGroup}
                setOpen={setIsProductGroupFormOpen}
                open={isProductGroupFormOpen}
            />
        </div>
    );
}
