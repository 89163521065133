import React, { useState } from "react";
import ProductForm from "../forms/ProductForm";
import { ProductType } from "../../api/models/ProductGroup";
import { Product } from "../../api/models/Product";
import PreProductIcon from "../icons/PreProductIcon.svg";
import EndProductIcon from "../icons/EndProductIcon.svg";
import InnerNode from "./InnerNode";
import { TestIds } from "../../TestIds";

export interface ProductNodeProps {
    product: Product;
    productType: ProductType;
    quantity?: number;
    addButton?: boolean;
}

export default function ProductNode({
    product,
    productType,
    quantity = 0,
    addButton = false,
}: ProductNodeProps): JSX.Element {
    const [isProductFormOpen, setIsProductFormOpen] = useState<boolean>(false);
    const isPreProduct = productType === ProductType.PRE;
    const testIdButton = isPreProduct ? TestIds.ADD_PRE_PRODUCT_BUTTON : TestIds.ADD_END_PRODUCT_BUTTON;
    const testId = product.id !== 0 ? TestIds.PRODUCT_NODE + product.id : testIdButton;
    const className = isPreProduct ? "bg-schedule-blue-light" : "bg-status-green";
    const icon = isPreProduct ? PreProductIcon : EndProductIcon;

    return (
        <>
            <InnerNode
                className={className}
                name={product.name}
                icon={icon}
                openForm={() => setIsProductFormOpen(true)}
                addButton={addButton}
                quantity={quantity}
                id={testId}
            />
            <ProductForm selectedProduct={product} setOpen={setIsProductFormOpen} open={isProductFormOpen} />
        </>
    );
}
