export interface ExternalMaterial {
    id: number;
    name: string;
    external_material_group_id: number;
}

export function createEmptyExtMaterial(): ExternalMaterial {
    return {
        id: 0,
        name: "",
        external_material_group_id: 0,
    };
}
