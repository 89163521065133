import Button, { ButtonSize, ButtonTypes } from "../../components/buttons/Button";
import { CalculatorIcon, ClockIcon } from "@heroicons/react/24/outline";
import Select from "../../components/form-inputs/Select";
import React, { useCallback, useEffect, useState } from "react";
import { useProduction } from "../../context/ProductionContext";
import { calculateSchedule } from "../../api/calls/Schedule";
import { ScheduleMode, ViewMode } from "./Schedule";
import { TestIds } from "../../TestIds";
import SwitchButton from "../../components/SwitchButton";
import { SelectBoxOption } from "../../api/models/GeneralTypes";
import { GRAY_DARK } from "../../colors";
import { SchedulingMethod } from "../../api/models/Schedule";
import RadioButtonGroup from "../../components/form-inputs/RadioButtonGroup";
import Popup from "../../components/Popup";
import { Popover } from "@headlessui/react";
import { useText } from "../../context/LanguageContext";
import TextIds from "../../language/TextIds";
import { createEmptyLine, Line } from "../../api/models/Line";
import { findObjectByFieldInList } from "../../utils/FilterUtils";
import Checkbox from "../../components/form-inputs/Checkbox";

interface ScheduleHeaderProps {
    setViewDate: Function;
    setViewMode: Function;
    viewMode: ViewMode;
    setSelectedScheduleMode: Function;
    setLine: Function;
    reschedule: Function;
    setShowDowntimes: Function;
    showDowntimes: boolean;
}

export default function ScheduleHeader({
    setViewDate,
    setViewMode,
    viewMode,
    setSelectedScheduleMode,
    setLine,
    reschedule,
    setShowDowntimes,
    showDowntimes,
}: ScheduleHeaderProps): JSX.Element {
    const getText = useText();
    const production = useProduction();

    const [selectedLine, setSelectedLine] = useState<Line>(createEmptyLine);

    const viewModeOptions: SelectBoxOption[] = [
        {
            id: 1,
            switchColor: GRAY_DARK,
            name: ViewMode.Month,
        },
        {
            id: 2,
            switchColor: GRAY_DARK,
            name: ViewMode.Week,
        },
        {
            id: 3,
            switchColor: GRAY_DARK,
            name: ViewMode.Day,
        },
    ];
    const [selectedViewMode, setSelectedViewMode] = useState<SelectBoxOption>(viewModeOptions[0]);

    const scheduleModeOptions: SelectBoxOption[] = [
        {
            id: 1,
            switchColor: GRAY_DARK,
            name: ScheduleMode.Station,
        },
        {
            id: 2,
            switchColor: GRAY_DARK,
            name: ScheduleMode.Order,
        },
    ];
    const [scheduleMode, setScheduleMode] = useState<SelectBoxOption>(scheduleModeOptions[0]);

    const schedulingMethodOptions: SelectBoxOption[] = [
        { id: 1, name: SchedulingMethod.FIFO },
        { id: 2, name: SchedulingMethod.GA },
    ];
    const [selectedMethod, setSelectedMethod] = useState<SelectBoxOption>(schedulingMethodOptions[0]);

    const rescheduleSchedule = useCallback(() => {
        calculateSchedule(selectedLine.schedule_id, SchedulingMethod[selectedMethod.name], getText).then(() => {
            reschedule(selectedLine);
        });
        // eslint-disable-next-line
    }, [selectedLine, selectedMethod]);

    const scrollToNow = useCallback(() => setViewDate(new Date()), [setViewDate]);

    useEffect(() => {
        setSelectedScheduleMode(scheduleMode.name);
    }, [scheduleMode, setSelectedScheduleMode]);

    useEffect(() => {
        setLine(selectedLine);
    }, [selectedLine, setLine]);

    useEffect(() => {
        setSelectedViewMode(findObjectByFieldInList(viewMode, "name", viewModeOptions));
    }, [viewMode]);

    useEffect(() => {
        setViewMode(selectedViewMode.name);
    }, [selectedViewMode, setViewMode]);

    useEffect(() => {
        if (production.lines.length === 0) return;
        setSelectedLine(production.lines[0]);
    }, [production, setSelectedLine]);

    function createCalculationButton() {
        const popupButton = (
            <Button
                classNames="flex items-center"
                title={getText(TextIds.Schedule.CREATE)}
                icon={<CalculatorIcon />}
                buttonType={ButtonTypes.Div}
                buttonSize={ButtonSize.middle}
            />
        );
        const calculateButton = (
            <Button
                classNames="flex items-center"
                title={getText(TextIds.Schedule.CALCULATE)}
                icon={<CalculatorIcon />}
                buttonType={ButtonTypes.Div}
                buttonSize={ButtonSize.middle}
            />
        );
        return (
            <Popup onClickElement={popupButton}>
                <div className="flex flex-col space-y-4">
                    <div className="text-lg font-semibold text-green-middle">{getText(TextIds.Schedule.METHOD)}</div>
                    <RadioButtonGroup
                        options={schedulingMethodOptions}
                        defaultRadio={selectedMethod}
                        setChecked={setSelectedMethod}
                        required={false}
                    />
                    <Popover.Button onClick={rescheduleSchedule} data-testid={TestIds.SCHEDULE_CALCULATION_BUTTON}>
                        {calculateButton}
                    </Popover.Button>
                </div>
            </Popup>
        );
    }

    return (
        <div data-testid={TestIds.SCHEDULE_HEADER}>
            <div className="float-right -mt-8">
                <Checkbox
                    label={getText(TextIds.Downtime.SHOW)}
                    checked={showDowntimes}
                    setChecked={setShowDowntimes}
                />
            </div>
            <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                    <Button
                        title={getText(TextIds.Time.NOW)}
                        buttonSize={ButtonSize.middle}
                        onClick={scrollToNow}
                        buttonType={ButtonTypes.Submit}
                        icon={<ClockIcon />}
                    />
                    <SwitchButton
                        key="switch-view"
                        options={viewModeOptions}
                        selectedElement={selectedViewMode}
                        setSelectedElement={setSelectedViewMode}
                        id={TestIds.TIMELINE_VIEW_MODE_SWITCH}
                    />
                </div>
                {createCalculationButton()}
                <div className="flex items-center space-x-2">
                    <SwitchButton
                        key="switch-view"
                        options={scheduleModeOptions}
                        selectedElement={scheduleMode}
                        setSelectedElement={setScheduleMode}
                        id={TestIds.TIMELINE_SCHEDULE_MODE_SWITCH}
                    />
                    <Select
                        className="w-48"
                        options={production.lines}
                        defaultValue={selectedLine}
                        noSelectionOption={false}
                        onChange={setSelectedLine}
                        id={TestIds.LINE_SELECT}
                    />
                </div>
            </div>
        </div>
    );
}
