import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useProduction } from "./ProductionContext";
import { sortByIdAsc } from "../utils/SortUtils";
import { getById } from "../utils/FilterUtils";
import { createEmptyProductGroup, ProductGroup, ProductType } from "../api/models/ProductGroup";

const ProductGroupsContext = createContext<{
    preProductGroups: ProductGroup[];
    endProductGroups: ProductGroup[];
}>({ preProductGroups: [], endProductGroups: [] });
const ProductGroupContext = createContext<ProductGroup>(createEmptyProductGroup());
const ProductGroupUpdateContext = createContext<Function>(() => "");

export function useProductGroups() {
    return useContext(ProductGroupsContext);
}

export function useSelectedProductGroup() {
    return useContext(ProductGroupContext);
}

export function useUpdateProductGroup() {
    return useContext(ProductGroupUpdateContext);
}

export default function ProductGroupProvider({ children }: any): JSX.Element {
    const production = useProduction();
    const [selectedProductGroup, setSelectedProductGroup] = useState(createEmptyProductGroup());

    const preProductGroups: ProductGroup[] = useMemo(
        () =>
            production.product_groups
                .filter((productGroup) => productGroup.product_type === ProductType.PRE)
                .sort(sortByIdAsc),
        [production]
    );

    const endProductGroups: ProductGroup[] = useMemo(
        () =>
            production.product_groups
                .filter((productGroup) => productGroup.product_type === ProductType.END)
                .sort(sortByIdAsc),
        [production]
    );

    useEffect(() => {
        if (selectedProductGroup.id !== 0)
            setSelectedProductGroup(
                getById(selectedProductGroup.id, production.product_groups, createEmptyProductGroup)
            );
    }, [production, setSelectedProductGroup]);

    const updateSelectedProductGroup = useCallback(
        (productGroup: ProductGroup) => {
            setSelectedProductGroup(productGroup);
        },
        [setSelectedProductGroup]
    );

    return (
        <ProductGroupsContext.Provider
            value={{ preProductGroups: preProductGroups, endProductGroups: endProductGroups }}
        >
            <ProductGroupContext.Provider value={selectedProductGroup}>
                <ProductGroupUpdateContext.Provider value={updateSelectedProductGroup}>
                    {children}
                </ProductGroupUpdateContext.Provider>
            </ProductGroupContext.Provider>
        </ProductGroupsContext.Provider>
    );
}
