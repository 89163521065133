import React, { useCallback, useMemo } from "react";
import { TASK_HEIGHT } from "../utils/TimelineUtils";
import { TimelineElement } from "../../../../api/models/GeneralTypes";
import { TestIds } from "../../../../TestIds";
import TimelineItemPopUp from "./TimelineItemPopUp";

export interface TimelineItemProps {
    item: TimelineElement;
    onClick: Function;
    color: string;
}

export function TimelineItem({ item, onClick, color }: TimelineItemProps): JSX.Element {
    const handleClick = useCallback(() => {
        onClick(item);
    }, [item, onClick]);

    const timelineItem = useMemo(() => {
        return (
            <div
                onClick={handleClick}
                className="cursor-pointer rounded-lg"
                style={{
                    width: item.endPosition - item.startPosition,
                    backgroundColor: color,
                    height: TASK_HEIGHT,
                }}
            />
        );
    }, [item, color]);

    return (
        <div
            className="absolute"
            style={{ left: item.startPosition, top: item.yPosition }}
            data-testid={TestIds.TIMELINE_ITEM + item.id}
        >
            <TimelineItemPopUp item={item} timelineItem={timelineItem} />
        </div>
    );
}
