import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { namePattern } from "../../utils/FormUtils";
import { toast } from "react-toastify";
import { useProduction, useProductionUpdate } from "../../context/ProductionContext";
import Modal from "../Modal";
import Input from "../form-inputs/Input";
import FormFooter from "../FormFooter";
import { createEmptyExtMaterialGroup, ExternalMaterialGroup } from "../../api/models/ExternalMaterialGroup";
import {
    deleteExternalMaterialGroup,
    postExternalMaterialGroup,
    putExternalMaterialGroup,
} from "../../api/calls/ExternalMaterialGroup";
import { TestIds } from "../../TestIds";
import { useText } from "../../context/LanguageContext";
import TextIds from "../../language/TextIds";
import { NAME_LENGTH } from "../../constants";

export interface ExtMaterialGroupProps {
    selectedExtMaterialGroup: ExternalMaterialGroup;
    setOpen: Function;
    open: boolean;
}

export default function ExtMaterialGroupForm({ selectedExtMaterialGroup, setOpen, open }: ExtMaterialGroupProps) {
    const getText = useText();
    const production = useProduction();
    const updateProduction = useProductionUpdate();

    const [extMaterialGroup, setExtMaterialGroup] = useState<ExternalMaterialGroup>(createEmptyExtMaterialGroup());
    const [label, setLabel] = useState("");

    const close = useCallback(() => setOpen(false), [setOpen]);
    const deleteFunction = () =>
        deleteExternalMaterialGroup(extMaterialGroup.id, getText).then(() => {
            updateProduction(production.id);
            close();
        });

    const setName = useCallback(
        (event: ChangeEvent<HTMLInputElement>) =>
            setExtMaterialGroup((extMaterialGroup) => ({
                ...extMaterialGroup,
                name: event.target.value,
            })),
        []
    );
    const isDataValid = useCallback(() => {
        if (!namePattern.test(extMaterialGroup.name)) {
            toast.error(getText(TextIds.Form.NAME_ALLOWED_CHARACTER));
            return;
        }
        return true;
    }, [extMaterialGroup, getText]);

    const handleSubmit = useCallback(
        (event: any) => {
            if (event !== undefined) {
                event.preventDefault();
                if (!isDataValid()) return;

                const promise = () =>
                    selectedExtMaterialGroup.id > 0
                        ? putExternalMaterialGroup(extMaterialGroup, extMaterialGroup.id, getText)
                        : postExternalMaterialGroup(extMaterialGroup, getText);
                promise().then(() => {
                    updateProduction(production.id);
                    close();
                });
            }
        },
        [close, extMaterialGroup, isDataValid, production, selectedExtMaterialGroup, updateProduction, getText]
    );

    useEffect(() => {
        if (selectedExtMaterialGroup.id > 0) {
            setLabel(getText(TextIds.ExternalMaterialGroup.EDIT));
            setExtMaterialGroup({
                ...selectedExtMaterialGroup,
            });
        } else {
            setLabel(getText(TextIds.ExternalMaterialGroup.ADD));
            setExtMaterialGroup({
                ...selectedExtMaterialGroup,
                name: "",
                production_id: production.id,
            });
        }
        // eslint-disable-next-line
    }, [selectedExtMaterialGroup, production.id]);

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title={label}
            description={getText(TextIds.ExternalMaterialGroup.FORM_SUBTITLE)}
        >
            <form className="flex flex-col space-y-4 divide-y divide-solid" onSubmit={(event) => handleSubmit(event)}>
                <div className="space-y-4">
                    <Input
                        labelText={getText(TextIds.Form.NAME)}
                        name="name"
                        value={extMaterialGroup.name}
                        type="text"
                        placeholder={getText(TextIds.Form.NAME)}
                        pattern={namePattern.source}
                        title={getText(TextIds.Form.NAME_ALLOWED_CHARACTER)}
                        maxLength={NAME_LENGTH}
                        onChange={setName}
                        required
                        data-testid={TestIds.INPUT_NAME}
                    />
                </div>
                <FormFooter
                    handleSubmitFunction={handleSubmit}
                    close={close}
                    deleteFunction={deleteFunction}
                    objectToDelete={selectedExtMaterialGroup}
                    isEditMode={selectedExtMaterialGroup.id > 0}
                />
            </form>
        </Modal>
    );
}
