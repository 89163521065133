import { createEmptyOperation, Operation } from "../../api/models/Operation";
import Modal from "../Modal";
import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from "react";
import { namePattern, numberPattern } from "../../utils/FormUtils";
import { toast } from "react-toastify";
import Input from "../form-inputs/Input";
import Label from "../Label";
import MultiSelect from "../form-inputs/MultiSelect";
import { Station } from "../../api/models/Station";
import { deleteOperation, postOperation, putOperation } from "../../api/calls/Operation";
import { Product } from "../../api/models/Product";
import { TestIds } from "../../TestIds";
import { useText } from "../../context/LanguageContext";
import TextIds from "../../language/TextIds";
import FormFooter from "../FormFooter";
import { useProduction, useProductionUpdate } from "../../context/ProductionContext";
import { NAME_LENGTH } from "../../constants";

interface OperationFormProps {
    selectedOperation: Operation;
    selectedStation: Station;
    setOpen: Function;
    open: boolean;
}

export default function OperationForm({
    selectedOperation,
    selectedStation,
    setOpen,
    open,
}: OperationFormProps): JSX.Element {
    const getText = useText();

    const production = useProduction();
    const updateProduction = useProductionUpdate();
    const label = selectedOperation.id === 0 ? getText(TextIds.Operation.ADD) : getText(TextIds.Operation.EDIT);
    const [operation, setOperation] = useState<Operation>(createEmptyOperation);

    const close = useCallback(() => setOpen(false), [setOpen]);
    const deleteFunction = useCallback(() => {
        deleteOperation(operation.id, getText).then(() => {
            updateProduction(production.id);
            close();
        });
    }, [operation, getText, close]);

    useEffect(() => {
        selectedOperation.station_id = selectedStation.id;
        setOperation(selectedOperation);
    }, [selectedOperation]);

    const setName = useCallback(
        (event: ChangeEvent<HTMLInputElement>) =>
            setOperation((operation) => ({
                ...operation,
                name: event.target.value,
            })),
        []
    );

    const setDuration = useCallback(
        (event: ChangeEvent<HTMLInputElement>) =>
            setOperation((operation) => ({
                ...operation,
                duration: +event.target.value,
            })),
        []
    );

    const setDescription = useCallback(
        (event: ChangeEvent<HTMLTextAreaElement>) =>
            setOperation((operation) => ({
                ...operation,
                description: event.target.value,
            })),
        []
    );

    const setProducts = useCallback(
        (selectedProducts: Product[]) =>
            setOperation((operation) => ({
                ...operation,
                products: selectedProducts,
            })),
        []
    );

    const isDataValid = useCallback(() => {
        if (operation.id !== 0 && JSON.stringify(selectedOperation) === JSON.stringify(operation)) {
            toast.error(getText(TextIds.Operation.IDENTICAL));
            return false;
        }
        if (!namePattern.test(operation.name)) {
            toast.error(getText(TextIds.Form.NAME_ALLOWED_CHARACTER));
            return false;
        }
        if (!numberPattern.test(operation.duration.toString()) || operation.duration === 0) {
            toast.error(getText(TextIds.Form.NUMBER_INPUT_ALLOWED));
            return false;
        }
        return true;
    }, [operation, getText, selectedOperation]);

    const handleSubmit = useCallback(
        (e: FormEvent<HTMLFormElement>) => {
            if (e === undefined) return;
            e.preventDefault();

            if (!isDataValid()) return;
            const promise =
                operation.id !== 0 ? putOperation(operation, operation.id, getText) : postOperation(operation, getText);

            promise.then(() => {
                updateProduction(production.id);
                close();
            });
        },
        [close, operation, isDataValid, getText, production, selectedStation.id, updateProduction]
    );

    function getProductsFromGroup(): Product[] {
        if (selectedStation.product_group === null) return [];
        return selectedStation.product_group.products;
    }

    return (
        <Modal title={label} description={getText(TextIds.Operation.FORM_SUBTITLE)} open={open} setOpen={setOpen}>
            <form
                className="flex flex-col space-y-4 divide-y divide-solid"
                onSubmit={(e) => handleSubmit(e)}
                data-testid={TestIds.OPERATION_FORM}
            >
                <div className="space-y-4">
                    <Input
                        labelText={getText(TextIds.Form.NAME)}
                        name="name"
                        value={operation.name}
                        type="text"
                        placeholder={getText(TextIds.Form.NAME)}
                        pattern={namePattern.source}
                        title={getText(TextIds.Form.NAME_ALLOWED_CHARACTER)}
                        maxLength={NAME_LENGTH}
                        onChange={setName}
                        required
                        data-testid={TestIds.INPUT_NAME}
                    />
                    <Input
                        labelText={getText(TextIds.Form.DURATION)}
                        name="duration"
                        value={operation.duration}
                        type="number"
                        placeholder={getText(TextIds.Form.DURATION)}
                        pattern={numberPattern.source}
                        title={getText(TextIds.Form.NUMBER_INPUT_ALLOWED)}
                        maxLength={NAME_LENGTH}
                        onChange={setDuration}
                        required
                        data-testid={TestIds.INPUT_DURATION}
                    />
                    <div>
                        <Label>
                            {getText(TextIds.Form.DESCRIPTION)}
                            <span className="text-red-600">*</span>
                        </Label>
                        <textarea
                            className="relative mt-1 block h-48 w-full resize-none appearance-none overflow-auto rounded-md border border-gray-light px-3 py-2 text-gray-middle placeholder-gray-light focus:z-10 focus:border-green-middle focus:outline-none focus:ring-green-middle"
                            name="description"
                            value={operation.description}
                            placeholder={getText(TextIds.Form.DESCRIPTION)}
                            maxLength={100}
                            onChange={setDescription}
                            required
                            data-testid={TestIds.INPUT_DESCRIPTION}
                        />
                    </div>
                    <div>
                        <MultiSelect
                            label={getText(TextIds.Product.PLURAL_NAME)}
                            options={getProductsFromGroup()}
                            defaultValues={[...operation.products]}
                            onChange={setProducts}
                            id={TestIds.PRODUCT_SELECT}
                        />
                    </div>
                </div>
                <FormFooter
                    handleSubmitFunction={handleSubmit}
                    close={close}
                    isEditMode={operation.id !== 0}
                    deleteFunction={deleteFunction}
                    objectToDelete={operation}
                />
            </form>
        </Modal>
    );
}
