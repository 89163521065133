import { ReactElement } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

interface TooltipProps {
    children: ReactElement;
    direction?: "top" | "bottom" | "right" | "left";
    content: ReactElement[];
    tooltipId: string;
}

export default function Tooltip({ content, children, direction = "top", tooltipId }: TooltipProps) {
    if (content.length === 0) return children;
    return (
        <div className="relative inline-block">
            <div id={tooltipId}>{children}</div>
            <ReactTooltip place={direction} anchorSelect={"#" + tooltipId}>
                {content}
            </ReactTooltip>
        </div>
    );
}
