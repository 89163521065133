import { CustomerOrder } from "./CustomerOrder";

export interface Customer {
    id: number;
    name: string;
    production_id: number;
    orders: CustomerOrder[];
}

export function createEmptyCustomer(): Customer {
    return {
        id: 0,
        name: "",
        production_id: 0,
        orders: [],
    };
}
