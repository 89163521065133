import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useProduction, useProductionUpdate } from "../../../context/ProductionContext";
import Modal from "../../Modal";
import Input from "../../form-inputs/Input";
import FormFooter from "../../FormFooter";
import { deleteLine, postLine, putLine } from "../../../api/calls/Line";
import { createEmptyLine, Line } from "../../../api/models/Line";
import { datePattern, namePattern, timePattern } from "../../../utils/FormUtils";
import { TestIds } from "../../../TestIds";
import { useText } from "../../../context/LanguageContext";
import TextIds from "../../../language/TextIds";
import WorkdayForm from "./WorkdayForm";
import { Workday } from "../../../api/models/Workday";
import Tabs from "../../Tabs";
import { SCHEDULE_BLUE_LIGHT } from "../../../colors";
import { GeneralObjectType } from "../../../api/models/GeneralTypes";
import DowntimeView from "./DowntimeView";
import TimeInput from "../../form-inputs/TimeInput";
import { isAfter, isBefore } from "date-fns";
import CounterBox from "../../form-inputs/CounterBox";
import RescheduleWarning from "./RescheduleWarning";
import { NAME_LENGTH } from "../../../constants";

export interface LineFormProps {
    selectedLine: Line;
    setOpen: Function;
    open: boolean;
}

export default function LineForm({ selectedLine, setOpen, open }: LineFormProps) {
    const getText = useText();

    const production = useProduction();
    const updateProduction = useProductionUpdate();

    const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(true);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [isInitialDateEditable, setIsInitialDateEditable] = useState<boolean>(true);

    const [downtimeForm, setDowntimeForm] = useState<JSX.Element>(<div />);

    const [line, setLine] = useState<Line>(createEmptyLine());
    const [initialDate, setInitialDate] = useState<string>("");
    const [initialTime, setInitialTime] = useState<string>("");
    const [label, setLabel] = useState("");

    const tabSelections = [
        {
            id: 1,
            name: getText(TextIds.Form.INFORMATION),
        },
        {
            id: 2,
            name: getText(TextIds.Form.SHIFT_SCHEDULE),
        },
        {
            id: 3,
            name: getText(TextIds.Downtime.NAME),
            disabled: line.id === 0,
        },
    ];

    const [selectedTab, setSelectedTab] = useState<GeneralObjectType>(tabSelections[0]);

    const close = () => setOpen(false);
    const deleteFunction = () =>
        deleteLine(line.id, getText).then(() => {
            updateProduction(production.id);
            close();
        });

    const setWarning = useCallback(
        (horizon: number, freeze_time: number, initial_date: string) => {
            const showWarning = !(
                selectedLine.horizon === horizon &&
                selectedLine.freeze_time === freeze_time &&
                selectedLine.initial_date === initial_date
            );
            setIsSaveEnabled(!showWarning);
            setShowWarning(selectedLine.id !== 0 && showWarning);
        },
        [selectedLine, setShowWarning]
    );

    const setName = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setLine((line: Line) => ({
            ...line,
            name: event.target.value,
        }));
    }, []);

    const setWorkdays = useCallback((workdays: Workday[]) => {
        setLine((line: Line) => ({
            ...line,
            workdays: workdays,
        }));
    }, []);

    const setHorizon = useCallback(
        (time: number) => {
            setWarning(time, line.freeze_time, line.initial_date);
            setLine((line: Line) => ({
                ...line,
                horizon: time,
            }));
        },
        [selectedLine, line]
    );

    const setFreezeTime = useCallback(
        (time: number) => {
            setWarning(line.horizon, time, line.initial_date);
            setLine((line: Line) => ({
                ...line,
                freeze_time: time,
            }));
        },
        [selectedLine, line]
    );

    const setInitialDateInput = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            let dateInput = event.target.value;
            const initialDate = dateInput + " " + initialTime;
            setWarning(line.horizon, line.freeze_time, initialDate);

            setLine((line) => ({
                ...line,
                initial_date: initialDate,
            }));
            setInitialDate(dateInput);
        },
        [initialTime, line]
    );

    const setInitialTimeInput = useCallback(
        (time: string) => {
            const initial = initialDate + " " + time + ":00";
            setWarning(line.horizon, line.freeze_time, initial);

            setLine((line) => ({ ...line, initial_date: initial }));
            setInitialTime(time);
        },
        [initialDate, line]
    );

    function isDataValid() {
        if (!namePattern.test(line.name)) {
            toast.error(getText(TextIds.Form.NAME_ALLOWED_CHARACTER));
            return;
        }

        if (!datePattern.test(initialDate)) {
            toast.error(getText(TextIds.Form.DATE_NEEDED));
            return;
        }

        if (!timePattern.test(initialTime)) {
            toast.error(getText(TextIds.Form.TIME_FORMAT_MISMATCH));
            return;
        }

        if (isInitialDateEditable)
            if (isAfter(new Date(), new Date(line.initial_date))) {
                toast.error(getText(TextIds.Form.INITIAL_DATE_BEFORE_NOW));
                return;
            }

        for (let workday of line.workdays) {
            if (workday.enabled) {
                if (!timePattern.test(workday.end) && timePattern.test(workday.start)) {
                    toast.error(getText(TextIds.Form.TIME_FORMAT_MISMATCH));
                    return;
                }
                if (workday.end < workday.start) {
                    toast.error(getText(TextIds.Form.DAILY_START_END_INVALID));
                    return;
                }
            }
        }
        return true;
    }

    function handleSubmit(event: any) {
        if (event !== undefined) {
            event.preventDefault();

            if (!isDataValid()) return;

            if (selectedLine.id !== 0 && !isSaveEnabled) {
                toast.error(getText(TextIds.Form.CHECK_SAVE));
                return;
            }

            const updatedLine: Line = {
                ...line,
                production_id: production.id,
            };

            const promise = () =>
                selectedLine.id > 0 ? putLine(updatedLine, line.id, getText) : postLine(updatedLine, getText);

            promise()
                .then(() => {
                    updateProduction(production.id);
                    close();
                })
                .catch((error) => console.error(error));
        }
    }

    useEffect(() => {
        if (selectedLine.id > 0) {
            setLabel(getText(TextIds.Line.EDIT));
            setIsInitialDateEditable(isBefore(new Date(), new Date(selectedLine.initial_date)));
        } else {
            setLabel(getText(TextIds.Line.ADD));
            setIsInitialDateEditable(true);
        }
        setLine({ ...selectedLine });
        setInitialTime(selectedLine.initial_date.substring(11, 16));
        setInitialDate(selectedLine.initial_date.substring(0, 10));
        setSelectedTab(tabSelections[0]);
        setWarning(selectedLine.horizon, selectedLine.freeze_time, selectedLine.initial_date);
    }, [selectedLine, getText]);

    const createInnerTabView = useCallback(() => {
        if (selectedTab.id === 3) return <DowntimeView line={selectedLine} setDowntimeForm={setDowntimeForm} />;

        if (selectedTab.id === 2)
            return (
                <WorkdayForm
                    setSaveEnabled={setIsSaveEnabled}
                    setSelectedWorkdays={setWorkdays}
                    selectedWorkdays={selectedLine.workdays}
                    savedWorkdays={line.workdays}
                    isEditMode={selectedLine.id > 0}
                />
            );

        return (
            <div className="h-[30rem] space-y-4">
                <Input
                    labelText={getText(TextIds.Form.NAME)}
                    name="name"
                    value={line.name}
                    type="text"
                    placeholder={getText(TextIds.Form.NAME)}
                    pattern={namePattern.source}
                    title={getText(TextIds.Form.NAME_ALLOWED_CHARACTER)}
                    maxLength={NAME_LENGTH}
                    onChange={setName}
                    required
                    data-testid={TestIds.INPUT_NAME}
                />
                <div className="flex space-x-2">
                    <Input
                        labelText={getText(TextIds.Form.INITIAL_DATE)}
                        disabled={!isInitialDateEditable}
                        name="initialDate"
                        value={initialDate}
                        type="date"
                        placeholder={getText(TextIds.Form.DATE)}
                        pattern={datePattern.source}
                        title={getText(TextIds.Form.DATE_INPUT_ALLOWED)}
                        maxLength={10}
                        onChange={setInitialDateInput}
                        data-testid={TestIds.INPUT_INITIAL_DATE}
                        required
                    />
                    <TimeInput
                        value={initialTime}
                        disabled={!isInitialDateEditable}
                        setTime={setInitialTimeInput}
                        name="initialTime"
                        placeholder={getText(TextIds.Form.START_HOUR)}
                        title={getText(TextIds.Form.TIME_FORMAT_ALLOWED)}
                        required
                        data-testid={TestIds.INPUT_INITIAL_TIME}
                    />
                </div>
                <div className="flex w-60 justify-between" data-testid={TestIds.LINE_COUNTER_BOX}>
                    <CounterBox
                        data-testid={TestIds.HORIZON_COUNTER}
                        initialCounter={line.horizon}
                        labelText={getText(TextIds.Form.HORIZON)}
                        setAmount={setHorizon}
                    />
                    <CounterBox
                        data-testid={TestIds.FREEZE_TIME_COUNTER}
                        initialCounter={line.freeze_time}
                        labelText={getText(TextIds.Form.FREEZE)}
                        setAmount={setFreezeTime}
                    />
                </div>
                <RescheduleWarning
                    showWarning={showWarning}
                    isSaveEnabled={isSaveEnabled}
                    setIsSaveEnabled={setIsSaveEnabled}
                />
            </div>
        );
    }, [selectedTab, selectedLine, line, getText, setName, setWorkdays, isSaveEnabled, setIsSaveEnabled]);

    return (
        <Modal open={open} setOpen={setOpen} title={label} description={getText(TextIds.Line.FORM_SUBTITLE)}>
            <form className="flex flex-col space-y-4 divide-y divide-solid" onSubmit={(event) => handleSubmit(event)}>
                <Tabs
                    elements={tabSelections}
                    defaultElement={selectedTab}
                    setSelectedElement={setSelectedTab}
                    selectedTabColor={SCHEDULE_BLUE_LIGHT}
                    className="h-full rounded-xl bg-white p-4"
                >
                    {createInnerTabView()}
                </Tabs>
                <FormFooter
                    handleSubmitFunction={handleSubmit}
                    close={close}
                    deleteFunction={deleteFunction}
                    objectToDelete={selectedLine}
                    isEditMode={selectedLine.id > 0}
                />
            </form>
            {downtimeForm}
        </Modal>
    );
}
