import { format, getWeek, getYear, isSameDay, isSameMonth, isSameWeek, isToday, lastDayOfMonth } from "date-fns";
import { de, enGB } from "date-fns/locale";
import React from "react";
import { ViewMode } from "../../Schedule";
import { COLUMN_WIDTH } from "./TimelineUtils";
import { TimelineDates } from "../../../../api/models/GeneralTypes";
import { TimelineHeaderProps } from "../components/TimelineHeader";
import TextIds from "../../../../language/TextIds";
import { createDowntimes } from "./GridUtils";

const SHORT_DAY_FORMAT = "EEEEE";
const FULL_DAY_FORMAT = "EEEE";
const DAY_OF_MONTH_FORMAT = "dd";
const HOUR_FORMAT = "HH";
const FULL_MONTH_FORMAT = "MMMM";

const dateLocale = {
    de: de,
    en: enGB,
};

export interface TimelineHeaderValues {
    monthLine: JSX.Element[];
    dayLine: JSX.Element[];
    downtimes: JSX.Element[];
}

export function createMonthHeader(
    timelineHeaderProps: TimelineHeaderProps,
    getText: Function,
    localeString: "de" | "en"
): TimelineHeaderValues {
    const monthLine: JSX.Element[] = [];
    const dayLine: JSX.Element[] = [];
    let downtimes: JSX.Element[] = [];

    let dates = timelineHeaderProps.timelineDates.dates;

    const locale = { locale: dateLocale[localeString] };

    dates.forEach((date: Date, index: number) => {
        dayLine.push(
            createDay(date, DAY_OF_MONTH_FORMAT, COLUMN_WIDTH, true, isToday(date), timelineHeaderProps, locale)
        );

        if (index !== 0 && !isSameMonth(date, dates[index - 1])) {
            const month = format(date, FULL_MONTH_FORMAT, locale);
            const calenderWeek = `${getText(TextIds.Time.CW)} ${getWeek(date, locale)} - ${getWeek(
                lastDayOfMonth(date),
                locale
            )} | ${getYear(date)}`;

            monthLine.push(createMonth(COLUMN_WIDTH * index, calenderWeek, month, date, timelineHeaderProps));
        }
        downtimes.push(...createDowntimes(COLUMN_WIDTH * index, timelineHeaderProps.downtimeItems, date, 1, 18));
    });
    return { monthLine, dayLine, downtimes };
}

export function createWeekHeader(
    timelineHeaderProps: TimelineHeaderProps,
    getText: Function,
    localeString: "de" | "en"
): TimelineHeaderValues {
    const monthLine: JSX.Element[] = [];
    const dayLine: JSX.Element[] = [];
    let downtimes: JSX.Element[] = [];

    let dates = timelineHeaderProps.timelineDates.dates;
    const locale = { locale: dateLocale[localeString] };

    dayLine.push(
        <div key={"cell one"}>
            <div style={{ width: COLUMN_WIDTH * 2 }} />
        </div>
    );

    dates.forEach((date: Date, index: number) => {
        if (index % 3 === 0) {
            const day = createDay(
                date,
                DAY_OF_MONTH_FORMAT,
                COLUMN_WIDTH * 3,
                true,
                isToday(date),
                timelineHeaderProps,
                locale
            );
            dayLine.push(day);
        }

        if (index !== 0 && !isSameWeek(date, dates[index - 1])) {
            const calenderWeek = `${getText(TextIds.Time.CW)} ${getWeek(date, locale)} | ${getYear(date)}`;
            const month = format(date, FULL_MONTH_FORMAT, locale);
            const monthDiv = createMonth(
                COLUMN_WIDTH * index + COLUMN_WIDTH * 3,
                calenderWeek,
                month,
                date,
                timelineHeaderProps
            );
            monthLine.push(monthDiv);
        }
        if (date.getHours() === 0) {
            const downtimeDivs = createDowntimes(COLUMN_WIDTH * index, timelineHeaderProps.downtimeItems, date, 3, 18);
            downtimes.push(...downtimeDivs);
        }
    });
    return { monthLine, dayLine, downtimes };
}

export function createDayHeader(
    timelineHeaderProps: TimelineHeaderProps,
    getText: Function,
    localeString: "de" | "en"
): TimelineHeaderValues {
    const monthLine: JSX.Element[] = [];
    const dayLine: JSX.Element[] = [];
    let downtimes: JSX.Element[] = [];

    let lastTop = 0;
    const locale = { locale: dateLocale[localeString] };

    let dates = timelineHeaderProps.timelineDates.dates;

    dates.forEach((date: Date, index: number) => {
        let columWidth = COLUMN_WIDTH;
        if (index === 0) columWidth = COLUMN_WIDTH / 8;
        const day = createDay(
            date,
            HOUR_FORMAT,
            columWidth,
            false,
            isToday(date) && date.getHours() === new Date().getHours(),
            timelineHeaderProps,
            locale
        );
        dayLine.push(day);

        if (index !== 0 && !isSameDay(date, dates[index - 1])) {
            const calenderWeek = `${getText(TextIds.Time.CW)} ${getWeek(date, locale)} | ${getYear(date)}`;
            const month = format(date, FULL_MONTH_FORMAT, locale);
            const position = COLUMN_WIDTH * index;
            const monthDiv = createMonth(position, calenderWeek, month, date, timelineHeaderProps);
            monthLine.push(monthDiv);

            if (lastTop !== 0) {
                const weekday = createWeekday(position, lastTop, date, dates[index - 1], timelineHeaderProps, locale);
                monthLine.push(weekday);
            }
            lastTop = position;
        }
        if (date.getHours() === 0) {
            const downtimeDivs = createDowntimes(COLUMN_WIDTH * index, timelineHeaderProps.downtimeItems, date, 24, 18);
            downtimes.push(...downtimeDivs);
        }
    });

    return { monthLine, dayLine, downtimes };
}

function createDay(
    date: Date,
    dateFormat: string,
    width: number,
    dayInfo: boolean,
    isCurrent: boolean,
    timelineHeaderProps: TimelineHeaderProps,
    locale: any
): JSX.Element {
    const currentStyle = isCurrent ? "w-8 rounded-full bg-status-red text-white" : "";
    const pointerStyle = timelineHeaderProps.viewMode === ViewMode.Day ? "cursor-default" : "cursor-pointer";

    let className = `${currentStyle} ${pointerStyle} flex h-8 items-center justify-center`;

    function handleDateClick(timelineHeaderProps: TimelineHeaderProps, date: Date): void {
        if (timelineHeaderProps.viewMode === ViewMode.Day) return;
        timelineHeaderProps.setCurrentViewDate(date);
        setPreviousViewMode(timelineHeaderProps.timelineDates, timelineHeaderProps.setViewMode);
    }

    return (
        <div key={date.toString()}>
            <div
                className="grid place-content-center"
                style={{ width: width }}
                onClick={() => handleDateClick(timelineHeaderProps, date)}
            >
                <div className={className}>
                    {format(date, dateFormat)}
                    {dayInfo && (
                        <div className="self-start pt-0.5 text-xs">{format(date, SHORT_DAY_FORMAT, locale)}</div>
                    )}
                </div>
            </div>
        </div>
    );
}

function createMonth(
    xStart: number,
    upperText: string,
    lowerText: string,
    date: Date,
    timelineHeaderProps: TimelineHeaderProps
): JSX.Element {
    const className = `absolute border-l-4 pl-2 uppercase text-gray-light ${
        timelineHeaderProps.viewMode === ViewMode.Month ? "cursor-default" : "cursor-pointer"
    }`;

    function handleMonthClick(timelineHeaderProps: TimelineHeaderProps, date: Date): void {
        if (timelineHeaderProps.viewMode === ViewMode.Month) return;
        timelineHeaderProps.setCurrentViewDate(date);
        setNextViewMode(timelineHeaderProps.timelineDates, timelineHeaderProps.setViewMode);
    }

    return (
        <div
            key={xStart + upperText}
            style={{ left: xStart }}
            className={className}
            onClick={() => handleMonthClick(timelineHeaderProps, date)}
        >
            <div className="text-xs">{upperText}</div>
            <div className="text-xl">{lowerText}</div>
        </div>
    );
}

function createWeekday(
    position: number,
    lastTop: number,
    date: Date,
    dateBefore: Date,
    timelineHeaderProps: TimelineHeaderProps,
    locale: any
): JSX.Element {
    function weekdayClick() {
        timelineHeaderProps.setCurrentViewDate(dateBefore);
        setNextViewMode(timelineHeaderProps.timelineDates, timelineHeaderProps.setViewMode);
    }

    return (
        <div
            style={{ left: (position - lastTop) / 2 + lastTop }}
            className="absolute top-1 cursor-pointer text-xl"
            key={date.getTime()}
            onClick={weekdayClick}
        >
            {format(dateBefore, `${FULL_DAY_FORMAT} ${DAY_OF_MONTH_FORMAT}`, locale)}
        </div>
    );
}

function setPreviousViewMode(timelineDates: TimelineDates, setViewMode: Function): void {
    switch (timelineDates.viewMode) {
        case ViewMode.Month:
            setViewMode(ViewMode.Week);
            break;
        case ViewMode.Week:
            setViewMode(ViewMode.Day);
            break;
    }
}

function setNextViewMode(timelineDates: TimelineDates, setViewMode: Function): void {
    switch (timelineDates.viewMode) {
        case ViewMode.Week:
            setViewMode(ViewMode.Month);
            break;
        case ViewMode.Day:
            setViewMode(ViewMode.Week);
            break;
    }
}
