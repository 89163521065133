import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { sortByIdAsc } from "../utils/SortUtils";
import { useProductGroups } from "./ProductGroupContext";
import { useProduction } from "./ProductionContext";
import { getById } from "../utils/FilterUtils";
import { createEmptyProduct, getProductsOfGroups, Product } from "../api/models/Product";

const ProductsContext = createContext<{
    preProducts: Product[];
    endProducts: Product[];
}>({ preProducts: [], endProducts: [] });

const ProductContext = createContext<Product>(createEmptyProduct());
const ProductUpdateContext = createContext<Function>(() => "");

export function useProducts() {
    return useContext(ProductsContext);
}

export function useSelectedProduct() {
    return useContext(ProductContext);
}

export function useUpdateProduct() {
    return useContext(ProductUpdateContext);
}

export default function ProductProvider({ children }: any): JSX.Element {
    const production = useProduction();
    const [selectedProduct, setSelectedProduct] = useState(createEmptyProduct());

    const { preProductGroups, endProductGroups } = useProductGroups();
    const preProducts: Product[] = getProductsOfGroups(preProductGroups).sort(sortByIdAsc);
    const endProducts: Product[] = getProductsOfGroups(endProductGroups).sort(sortByIdAsc);
    const products = useMemo(() => ({ preProducts, endProducts }), [preProducts, endProducts]);

    useEffect(() => {
        if (selectedProduct.id !== 0)
            setSelectedProduct(
                getById(selectedProduct.id, getProductsOfGroups(production.product_groups), createEmptyProduct)
            );
    }, [production, selectedProduct.id]);

    const updateSelectedProduct = useCallback(
        (productGroup: Product) => {
            setSelectedProduct(productGroup);
        },
        [setSelectedProduct]
    );

    return (
        <ProductsContext.Provider value={products}>
            <ProductContext.Provider value={selectedProduct}>
                <ProductUpdateContext.Provider value={updateSelectedProduct}>{children}</ProductUpdateContext.Provider>
            </ProductContext.Provider>
        </ProductsContext.Provider>
    );
}
