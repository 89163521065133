import { PlusCircleIcon } from "@heroicons/react/24/outline";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import Input from "../../components/form-inputs/Input";
import { useProduction } from "../../context/ProductionContext";
import CustomerForm from "../../components/forms/CustomerForm";
import CustomerOrderForm from "../../components/forms/CustomerOrderForm";
import { createEmptyCustomerOrder, CustomerOrder } from "../../api/models/CustomerOrder";
import { SelectBoxOption } from "../../api/models/GeneralTypes";
import { createEmptyCustomer } from "../../api/models/Customer";
import { TestIds } from "../../TestIds";
import CustomerOrderTable from "./CustomerOrderTable";
import ProductionOrderTable from "./ProductionOrderTable";
import { ProductionOrder } from "../../api/models/ProductionOrder";
import SwitchButton from "../../components/SwitchButton";
import { GRAY_DARK } from "../../colors";
import TextIds from "../../language/TextIds";
import { useText } from "../../context/LanguageContext";
import { getProductionOrders } from "../../api/calls/ProductionOrders";

export default function Orders(): JSX.Element {
    const getText = useText();
    const production = useProduction();
    const customers = production?.customers;

    const [customerOrders, setCustomerOrders] = useState<CustomerOrder[]>([]);
    const [productionOrders, setProductionOrders] = useState<ProductionOrder[]>([]);
    const [filterInput, setFilterInput] = useState<string>("");

    const [isCustomerFormOpen, setIsCustomerFormOpen] = useState(false);
    const [isCustomerOrderFormOpen, setIsCustomerOrderFormOpen] = useState(false);
    const viewSelectOptions = [
        {
            id: 1,
            name: TextIds.CustomerOrder.NAME,
            switchColor: GRAY_DARK,
        },
        {
            id: 2,
            name: TextIds.ProductionOrder.NAME,
            switchColor: GRAY_DARK,
        },
    ];

    const [selectedView, setSelectedView] = useState<SelectBoxOption>(viewSelectOptions[0]);

    const filterOrders = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFilterInput(event.target.value.toLowerCase());
    }, []);

    useEffect(() => {
        let orders: CustomerOrder[] = [];
        customers.map((customer) => {
            return orders.push(...customer.orders);
        });
        setCustomerOrders([...orders]);
    }, [customers]);

    useEffect(() => {
        if (production.id === 0) return;

        getProductionOrders(production.id, getText).then((orders: any) => {
            setProductionOrders([...orders.production_orders]);
        });
    }, [production.id, getText, selectedView]);

    function getOpenFormButton(title: string, openForm: Function, buttonType: string): JSX.Element {
        return (
            <div
                className="flex h-10 w-fit cursor-pointer select-none justify-between truncate rounded-lg border border-gray-light p-2"
                onClick={() => openForm(true)}
                data-testid={buttonType}
            >
                {title}
                <PlusCircleIcon className="ml-2 h-6 text-gray-middle" />
            </div>
        );
    }

    function getView() {
        if (selectedView.id === 1)
            return <CustomerOrderTable customerOrders={customerOrders} filterInput={filterInput} />;
        return <ProductionOrderTable productionOrders={productionOrders} filterInput={filterInput} />;
    }

    function createMarbleLegend() {
        let status = [getText(TextIds.Status.IN_TIME), getText(TextIds.Status.TIGHT), getText(TextIds.Status.DELAYED)];
        if (selectedView.id === 2) {
            status = [getText(TextIds.Status.DONE), getText(TextIds.Status.RUNNING), getText(TextIds.Status.BLOCKED)];
        }
        return (
            <div className="mt-14 h-fit w-fit rounded-2xl border border-gray-light p-3">
                <div className="flex gap-2">
                    <div className="h-7 w-7 rounded-full border border-gray-light bg-status-green shadow-2xl" />
                    {status[0]}
                </div>
                <div className="mt-2 flex gap-2">
                    <div className="h-7 w-7 rounded-full border border-gray-light bg-status-yellow shadow-2xl" />
                    {status[1]}
                </div>
                <div className="mt-2 flex gap-2">
                    <div className="h-7 w-7 rounded-full border border-gray-light bg-status-red shadow-2xl" />
                    {status[2]}
                </div>
            </div>
        );
    }

    function getCustomerForm() {
        return (
            <CustomerForm
                selectedCustomer={createEmptyCustomer()}
                setOpen={setIsCustomerFormOpen}
                open={isCustomerFormOpen}
            />
        );
    }

    function getCustomerOrderForm() {
        return (
            <CustomerOrderForm
                selectedCustomerOrder={createEmptyCustomerOrder()}
                setOpen={setIsCustomerOrderFormOpen}
                open={isCustomerOrderFormOpen}
            />
        );
    }

    if (customerOrders.length === 0)
        return (
            <div className="flex justify-center">
                <div className="flex flex-col text-center">
                    {getText(TextIds.Customer.NONE)}
                    <div className="flex flex-row space-x-4">
                        {getOpenFormButton(
                            getText(TextIds.Customer.NAME),
                            setIsCustomerFormOpen,
                            TestIds.ADD_CUSTOMER_BUTTON
                        )}
                        {getOpenFormButton(
                            getText(TextIds.CustomerOrder.NAME),
                            setIsCustomerOrderFormOpen,
                            TestIds.ADD_CUSTOMER_ORDER_BUTTON
                        )}
                    </div>
                </div>
                {getCustomerForm()}
                {getCustomerOrderForm()}
            </div>
        );

    return (
        <div className="flex justify-center">
            <div className="flex gap-8">
                <div className="grow">
                    <div className="mb-4 flex flex-row items-center justify-between">
                        <Input
                            name="filter"
                            type="text"
                            placeholder="Filter"
                            className="!w-64 text-sm"
                            onInput={filterOrders}
                        />
                        <SwitchButton
                            key="switch-view"
                            options={viewSelectOptions}
                            selectedElement={selectedView}
                            setSelectedElement={setSelectedView}
                            id={TestIds.PRODUCT_MODELING_SWITCH}
                        />
                        <div className="flex flex-row justify-between space-x-4">
                            {getOpenFormButton(
                                getText(TextIds.Customer.NAME),
                                setIsCustomerFormOpen,
                                TestIds.ADD_CUSTOMER_BUTTON
                            )}
                            {getOpenFormButton(
                                getText(TextIds.CustomerOrder.NAME),
                                setIsCustomerOrderFormOpen,
                                TestIds.ADD_CUSTOMER_ORDER_BUTTON
                            )}
                        </div>
                    </div>
                    {getView()}
                </div>
                {createMarbleLegend()}
            </div>
            {getCustomerForm()}
            {getCustomerOrderForm()}
        </div>
    );
}
