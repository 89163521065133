import Downtime, { createEmptyDowntime } from "../../../api/models/Downtime";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import EditButton from "../../buttons/EditButton";
import { deleteDowntime } from "../../../api/calls/Downtime";
import { useText } from "../../../context/LanguageContext";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import TextIds from "../../../language/TextIds";
import ConfirmationDialog from "../../ConfirmationDialog";
import DeleteButton from "../../buttons/DeleteButton";
import { TestIds } from "../../../TestIds";
import { useProduction, useProductionUpdate } from "../../../context/ProductionContext";
import { Line } from "../../../api/models/Line";
import { getDowntimes } from "../../../api/calls/Downtimes";
import DowntimeForm from "./DowntimeForm";
import Input from "../../form-inputs/Input";
import { datePattern } from "../../../utils/FormUtils";
import { sortByValue } from "../../../utils/SortUtils";

export interface DowntimeViewProps {
    line: Line;
    setDowntimeForm: Function;
}

export default function DowntimeView({ line, setDowntimeForm }: DowntimeViewProps): JSX.Element {
    const getText = useText();
    const production = useProduction();
    const updateProduction = useProductionUpdate();

    const [downtimes, setDowntimes] = useState<Downtime[]>([]);
    const [downtime, setDowntime] = useState<Downtime>(createEmptyDowntime);

    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);

    const today = new Date();
    const [filterDate, setFilterDate] = useState<string>(today.toISOString().substring(0, 10));
    const setFilterInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFilterDate(event.target.value);
    }, []);

    const updateDowntimes = useCallback(
        (line: Line) => {
            if (line.schedule_id === 0) return;

            return getDowntimes(line.id, filterDate, getText).then((value) => {
                setDowntimes(value["downtimes"].sort((a: Downtime, b: Downtime) => sortByValue(a, b, ["start"], true)));
            });
        },
        // eslint-disable-next-line
        [setDowntimes, filterDate]
    );

    const deleteFunction = useCallback(() => {
        deleteDowntime(downtime.id, getText)
            .then(() => {
                updateProduction(production.id);
                updateDowntimes(line);
                setIsConfirmationDialogOpen(false);
            })
            .catch((error) => console.error(error));
    }, [downtime, getText, production, updateProduction, updateDowntimes, line]);

    const createDowntimeRow = useCallback(
        (downtime: Downtime) => {
            return (
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-3" data-testid={TestIds.DOWNTIME_ENTRY + downtime.id}>
                        <div className="rounded-xl border border-gray-light p-2">{downtime.start.substring(0, 10)}</div>
                        <div className="rounded-xl border border-gray-light p-2">
                            {downtime.start.substring(11, 16)}
                        </div>
                        <p>{getText(TextIds.TO)}</p>
                        <div className="rounded-xl border border-gray-light p-2 ">{downtime.end.substring(0, 10)}</div>
                        <div className="rounded-xl border border-gray-light p-2 ">{downtime.end.substring(11, 16)}</div>
                        <div className="flex justify-center space-x-2">
                            <p>{downtime.is_all_day && getText(TextIds.Form.IS_ALL_DAY)}</p>
                        </div>
                    </div>
                    <div className="flex basis-1/12 space-x-2">
                        <EditButton handleClick={setDowntime} openForm={setOpen} item={downtime} />
                        <DeleteButton
                            handleClick={setDowntime}
                            openForm={setIsConfirmationDialogOpen}
                            item={downtime}
                        />
                    </div>
                </div>
            );
        },
        [getText, setOpen, setDowntime, setIsConfirmationDialogOpen]
    );

    const createDowntimeListView = useCallback(() => {
        if (downtimes.length === 0)
            return <div className="flex h-[30rem] justify-center p-4">{getText(TextIds.Downtime.NONE)}</div>;
        return (
            <div className="flex h-[30rem] select-none flex-col space-y-4 overflow-x-auto p-4">
                {downtimes.map((downtime: Downtime) => {
                    return (
                        <div key={downtime.id} className="border-l-2 pl-2">
                            <div className="font-bold">{downtime.description}</div>
                            {createDowntimeRow(downtime)}
                        </div>
                    );
                })}
                <ConfirmationDialog
                    title={`${getText(TextIds.Form.DELETE)} ${downtime.description}`}
                    submitFunction={deleteFunction}
                    open={isConfirmationDialogOpen}
                    setOpen={setIsConfirmationDialogOpen}
                />
            </div>
        );
    }, [downtimes, deleteFunction, getText, isConfirmationDialogOpen, downtime.start, createDowntimeRow]);

    function openCreateDowntime() {
        setDowntime(createEmptyDowntime());
        setOpen(true);
    }

    useEffect(() => {
        updateDowntimes(line);
    }, [line, updateDowntimes, filterDate]);

    useEffect(() => {
        setDowntimeForm(createDowntimeForm());
    }, [line, downtime, open, updateDowntimes, setDowntimeForm]);

    function createDowntimeForm() {
        return (
            <DowntimeForm
                selectedDowntime={downtime}
                selectedLine={line}
                setOpen={setOpen}
                open={open}
                reloadDowntimes={updateDowntimes}
            />
        );
    }

    return (
        <>
            <div className="float-right -mr-4 -mt-28 flex items-center space-x-4">
                <Input
                    name="filterDate"
                    value={filterDate}
                    type="date"
                    placeholder={getText(TextIds.Form.DATE)}
                    pattern={datePattern.source}
                    title={getText(TextIds.Form.DATE_INPUT_ALLOWED)}
                    maxLength={10}
                    onChange={setFilterInput}
                    data-testid={TestIds.INPUT_DATE}
                    required
                />
                <PlusCircleIcon
                    className="h-10 cursor-pointer text-gray-middle"
                    onClick={openCreateDowntime}
                    data-testid={TestIds.ADD_DOWNTIME_BUTTON}
                />
            </div>
            {createDowntimeListView()}
        </>
    );
}
