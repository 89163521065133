import { GeneralObjectType } from "../../api/models/GeneralTypes";
import React, { useCallback } from "react";
import { TrashIcon } from "@heroicons/react/24/outline";
import { TestIds } from "../../TestIds";

interface DeleteButtonProps {
    handleClick: Function;
    openForm: Function;
    item: GeneralObjectType;
}

export default function DeleteButton({ handleClick, openForm, item }: DeleteButtonProps): JSX.Element {
    const onClick = useCallback(() => {
        handleClick(item);
        openForm(true);
    }, [item, handleClick, openForm]);

    return (
        <TrashIcon
            className="h-6 cursor-pointer text-gray-middle"
            data-testid={TestIds.DELETE_BUTTON}
            onClick={onClick}
        />
    );
}
