import axios from "axios";
import { BASE_URL, CUSTOMER_ORDER_ENDPOINT } from "../ApiConfig";
import { getToken } from "../../hook/UseToken";
import { CustomerOrder } from "../models/CustomerOrder";
import { generateToastText, handleApiCall, handleApiGetCall } from "../../utils/ApiUtils";

const customerOrder = "CustomerOrder";
const endpoint = BASE_URL + CUSTOMER_ORDER_ENDPOINT;

export async function getCustomerOrder(customerOrderId: number, getText: Function): Promise<CustomerOrder> {
    const response = axios.get(endpoint + "/" + customerOrderId, getToken());
    return handleApiGetCall(response, customerOrder, customerOrderId, getText);
}

export async function putCustomerOrder(
    customerOrderJson: CustomerOrder,
    customerOrderId: number,
    getText: Function
): Promise<CustomerOrder> {
    const messages = generateToastText(customerOrder, "PUT", getText);
    const response = axios.put(endpoint + "/" + customerOrderId, customerOrderJson, getToken());
    return handleApiCall(response, messages);
}

export async function postCustomerOrder(customerOrderJson: CustomerOrder, getText: Function): Promise<CustomerOrder> {
    const messages = generateToastText(customerOrder, "POST", getText);
    const response = axios.post(endpoint, customerOrderJson, getToken());
    return handleApiCall(response, messages);
}

export async function deleteCustomerOrder(customerOrderId: number, getText: Function): Promise<void> {
    const messages = generateToastText(customerOrder, "DELETE", getText);
    const response = axios.delete(endpoint + "/" + customerOrderId, getToken());
    return handleApiCall(response, messages);
}
