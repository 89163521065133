import axios from "axios";
import { BASE_URL, PRODUCTION_ENDPOINT } from "../ApiConfig";
import { getToken } from "../../hook/UseToken";
import { Production } from "../models/Production";
import { generateToastText, handleApiCall, handleApiGetCall } from "../../utils/ApiUtils";

const production = "Production";
const endpoint = BASE_URL + PRODUCTION_ENDPOINT;

export async function getProduction(productionId: number, getText: Function): Promise<Production> {
    const response = axios.get(endpoint + "/" + productionId, getToken());
    return handleApiGetCall(response, production, productionId, getText);
}

export async function putProduction(
    productionJson: Production,
    productionId: number,
    getText: Function
): Promise<Production> {
    const messages = generateToastText(production, "PUT", getText);
    const response = axios.put(endpoint + "/" + productionId, productionJson, getToken());
    return handleApiCall(response, messages);
}

export async function postProduction(productionJson: Production, getText: Function): Promise<Production> {
    const messages = generateToastText(production, "POST", getText);
    const response = axios.post(endpoint, productionJson, getToken());
    return handleApiCall(response, messages);
}

export async function deleteProduction(productionId: number, getText: Function): Promise<Production> {
    const messages = generateToastText(production, "DELETE", getText);
    const response = axios.delete(endpoint + "/" + productionId, getToken());
    return handleApiCall(response, messages);
}
