import { Listbox, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import Label from "../Label";
import { sortByIdAsc } from "../../utils/SortUtils";
import { SelectBoxOption } from "../../api/models/GeneralTypes";
import { TestIds } from "../../TestIds";
import TextIds from "../../language/TextIds";
import { useText } from "../../context/LanguageContext";

interface SelectBoxProps {
    options: SelectBoxOption[];
    defaultValue: SelectBoxOption;
    className?: string;
    onChange: Function;
    label?: string;
    required?: boolean;
    noSelectionOption?: boolean;
    id?: string;
}

export default function Select({
    options,
    onChange,
    defaultValue,
    className,
    label,
    required = false,
    noSelectionOption = !required,
    id,
}: SelectBoxProps): JSX.Element {
    const getText = useText();
    const emptySelectedValue: SelectBoxOption = { id: 0, name: getText(TextIds.Form.NOTHING_SELECTED) };
    const [selectedValue, setSelectedValue] = useState(defaultValue ? defaultValue : emptySelectedValue);

    const [sortedOptions, setSortedOptions] = useState<SelectBoxOption[]>([]);

    useEffect(() => {
        setSelectedValue(defaultValue ? defaultValue : emptySelectedValue);
    }, [defaultValue]);

    useEffect(() => {
        let sortOptions = [...options];
        if (noSelectionOption && options.length > 0)
            sortOptions.push({ id: 0, name: getText(TextIds.Form.NO_SELECTION) });

        if (sortOptions.length > 0) {
            sortOptions.sort(sortByIdAsc);
        }
        setSortedOptions(sortOptions);
    }, [options, required, getText]);

    function getSelectionText(): string | JSX.Element {
        if (options.length === 0) return getText(TextIds.Form.EMPTY_SELECT);
        if (selectedValue.id === 0) return getText(TextIds.Form.NOTHING_SELECTED);
        return selectedValue.name;
    }

    return (
        <div className={className} data-testid={id}>
            <Label>
                {label}
                {required && <span className="text-red-600">*</span>}
            </Label>
            <Listbox
                value={selectedValue}
                onChange={(item) => {
                    setSelectedValue(item);
                    onChange(item);
                }}
            >
                <div className="relative">
                    <Listbox.Button
                        className="relative z-auto w-full cursor-pointer rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md hover:bg-gray-very-light focus:outline-none"
                        data-testid={TestIds.SELECT_BUTTON}
                    >
                        <span className="block truncate">{getSelectionText()}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon className="h-5 w-5 text-gray-light" aria-hidden="true" />
                        </span>
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options
                            className="absolute z-50 mt-1 max-h-32 w-full overflow-auto rounded-md bg-white py-1 shadow-lg focus:outline-none"
                            data-testid={TestIds.SELECT_OPTIONS}
                        >
                            {sortedOptions.map((option, _) => (
                                <Listbox.Option
                                    data-testid={TestIds.OPTION + option.id}
                                    key={option.id}
                                    className="relative cursor-default select-none py-2 pl-10 pr-4 text-black hover:bg-gray-very-light"
                                    value={option}
                                >
                                    <>
                                        <span className="block truncate font-normal">{option.name}</span>
                                        {selectedValue.id === option.id && (
                                            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                                                <CheckIcon className="h-5 w-5 text-green-middle" />
                                            </div>
                                        )}
                                    </>
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        </div>
    );
}
