import { createEmptyOperation, Operation } from "../../../api/models/Operation";
import React, { useCallback, useEffect, useState } from "react";
import EditButton from "../../buttons/EditButton";
import { deleteOperation } from "../../../api/calls/Operation";
import { useText } from "../../../context/LanguageContext";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import TextIds from "../../../language/TextIds";
import ConfirmationDialog from "../../ConfirmationDialog";
import DeleteButton from "../../buttons/DeleteButton";
import { TestIds } from "../../../TestIds";
import { useProduction, useProductionUpdate } from "../../../context/ProductionContext";

export interface StationOperationViewProps {
    operations: Operation[];
    setSelectedOperation: Function;
    setOpenOperation: Function;
}

export default function StationOperationView({
    operations,
    setSelectedOperation,
    setOpenOperation,
}: StationOperationViewProps): JSX.Element {
    const getText = useText();
    const production = useProduction();
    const updateProduction = useProductionUpdate();
    const [shownOperations, setShownOperations] = useState<Operation[]>(operations);
    const [operation, setOperation] = useState<Operation>(createEmptyOperation);
    const [isOpenConfirmationDialog, setIsOpenConfirmationDialog] = useState<boolean>(false);

    const deleteFunction = useCallback(() => {
        deleteOperation(operation.id, getText).then((response) => {
            if (response) setShownOperations(shownOperations.filter((o) => o.id !== operation.id));
            updateProduction(production.id);
            setIsOpenConfirmationDialog(false);
        });
    }, [operation, getText, shownOperations, production, updateProduction]);

    useEffect(() => {
        setSelectedOperation(operation);
    }, [operation, setSelectedOperation]);

    useEffect(() => {
        setShownOperations(operations);
    }, [operations]);

    function openCreateOperation() {
        setOperation(createEmptyOperation());
        setOpenOperation(true);
    }

    const createOperationListView = useCallback(() => {
        if (shownOperations.length === 0)
            return <div className="flex h-[30rem] justify-center p-4">{getText(TextIds.Operation.NONE)}</div>;
        return (
            <div className="flex h-[30rem] flex-col space-y-4 overflow-x-auto p-4">
                {shownOperations.map((operation: Operation) => {
                    return (
                        <div
                            key={operation.id}
                            className="flex items-center space-x-4"
                            data-testid={TestIds.OPERATION_ENTRY + operation.id}
                        >
                            <div className="basis-3/4 rounded-xl border border-gray-light p-2">{operation.name}</div>
                            <div className="basis-1/6 rounded-xl border border-gray-light p-2">
                                {operation.duration} Min
                            </div>
                            <div className="flex basis-1/12 space-x-2">
                                <EditButton handleClick={setOperation} openForm={setOpenOperation} item={operation} />
                                <DeleteButton
                                    handleClick={setOperation}
                                    openForm={setIsOpenConfirmationDialog}
                                    item={operation}
                                />
                            </div>
                        </div>
                    );
                })}
                <ConfirmationDialog
                    title={`${getText(TextIds.Form.DELETE)} ${operation.name}`}
                    submitFunction={deleteFunction}
                    open={isOpenConfirmationDialog}
                    setOpen={setIsOpenConfirmationDialog}
                />
            </div>
        );
    }, [shownOperations, deleteFunction, getText, isOpenConfirmationDialog, operation.name, setOpenOperation]);

    return (
        <>
            <PlusCircleIcon
                className="float-right mt-[-2.25rem] h-8 w-8 cursor-pointer text-gray-middle"
                onClick={openCreateOperation}
                data-testid={TestIds.ADD_OPERATION_BUTTON}
            />
            {createOperationListView()}
        </>
    );
}
