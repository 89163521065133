import { IDictionary } from "../context/LanguageContext";

export const en: IDictionary = {
    //Production
    production: "Production",
    productions: "Productions",
    noProduction: "No Production yet!",
    addProduction: "Add Production",
    editProduction: "Edit Production",
    enterProduction: "Enter your Production details below",
    deleteProduction: "Delete Production",
    productionIdentical: "Production is identical!",
    deletionProductionFailed: "Deletion of Production failed!",
    creationProductionFailed: "Creation of Production failed!",
    updateProductionFailed: "Update of Production failed!",
    deletedProduction: "Production deleted!",
    createdProduction: "Production created!",
    updatedProduction: "Production updated!",
    fetchProductionFailed: "Could not fetch Production with Id:",
    fetchProductionsFailed: "Could not fetch Productions!",
    //Line
    line: "Line",
    lines: "Linen",
    noLine: "No Line yet!",
    addLine: "Add Line",
    editLine: "Edit Line",
    enterLine: "Enter your Line details below",
    deleteLine: "Delete Line",
    selectLine: "Select Line",
    lineIdentical: "Line is identical!",
    deletionLineFailed: "Deletion of Line failed!",
    creationLineFailed: "Creation of Line failed!",
    updateLineFailed: "Update of Line failed!",
    deletedLine: "Line deleted!",
    createdLine: "Line created!",
    updatedLine: "Line updated!",
    fetchLineFailed: "Could not fetch Line with Id:",
    //Station
    station: "Station",
    stations: "Stations",
    noStation: "No Station yet!",
    addStation: "Add Station",
    editStation: "Edit Station",
    enterStation: "Enter your Station details below",
    deleteStation: "Delete Station",
    predecessors: "Predecessors",
    stationIdentical: "Station is identical!",
    deletionStationFailed: "Deletion of Station failed!",
    creationStationFailed: "Creation of Station failed!",
    updateStationFailed: "Update of Station failed!",
    deletedStation: "Station deleted!",
    createdStation: "Station created!",
    updatedStation: "Station updated!",
    fetchStationFailed: "Could not fetch Station with Id:",
    //Operation
    operation: "Operation",
    operations: "Operations",
    noOperation: "No Operation yet!",
    addOperation: "Add Operation",
    editOperation: "Edit Operation",
    enterOperation: "Enter your Operation details below",
    deleteOperation: "Delete Operation",
    operationIdentical: "Operation is identical!",
    deletionOperationFailed: "Deletion of Operation failed!",
    creationOperationFailed: "Creation of Operation failed!",
    updateOperationFailed: "Update of Operation failed!",
    deletedOperation: "Operation deleted!",
    createdOperation: "Operation created!",
    updatedOperation: "Operation updated!",
    fetchOperationFailed: "Could not fetch Operation with Id:",
    //Product Group
    productGroup: "Product Group",
    productGroups: "Product Groups",
    preProductGroups: "Pre Product Groups",
    endProductGroups: "End Product Groups",
    noProductGroup: "No Product Group yet!",
    addProductGroup: "Add Product Group",
    editProductGroup: "Edit Product Group",
    enterProductGroup: "Enter your Product Group details below",
    deleteProductGroup: "Delete Product Group",
    productGroupIdentical: "Product Group is identical!",
    noProductGroupSelected: "No Product Group selected!",
    deletionProductGroupFailed: "Deletion of Product Group failed!",
    creationProductGroupFailed: "Creation of Product Group failed!",
    updateProductGroupFailed: "Update of Product Group failed!",
    deletedProductGroup: "Product Group deleted!",
    createdProductGroup: "Product Group created!",
    updatedProductGroup: "Product Group updated!",
    fetchProductGroupFailed: "Could not fetch ProductGroup with Id:",
    //Product
    product: "Product",
    products: "Products",
    preProducts: "Pre Products",
    endProducts: "End Products",
    preProduct: "Pre Product",
    endProduct: "End Product",
    noProduct: "No Product yet!",
    addProduct: "Add Product",
    editProduct: "Edit Product",
    enterProduct: "Enter your Product details below",
    deleteProduct: "Delete Product",
    productIdentical: "Product is identical!",
    noProductSelected: "No Product selected!",
    deletionProductFailed: "Deletion of Product failed!",
    creationProductFailed: "Creation of Product failed!",
    updateProductFailed: "Update of Product failed!",
    deletedProduct: "Product deleted!",
    createdProduct: "Product created!",
    updatedProduct: "Product updated!",
    fetchProductFailed: "Could not fetch Product with Id:",
    //External Material Group
    externalMaterialGroup: "External Material Group",
    externalMaterialGroups: "External Material Groups",
    noExternalMaterialGroup: "No External Material Group yet!",
    addExternalMaterialGroup: "Add External Material Group",
    editExternalMaterialGroup: "Edit External Material Group",
    enterExternalMaterialGroup: "Enter your External Material Group details below",
    deleteExternalMaterialGroup: "Delete External Material Group",
    externalMaterialGroupIdentical: "External Material Group is identical!",
    noExternalMaterialGroupSelected: "No External Material Group selected!",
    deletionExternalMaterialGroupFailed: "Deletion of External Material Group failed!",
    creationExternalMaterialGroupFailed: "Creation of External Material Group failed!",
    updateExternalMaterialGroupFailed: "Update of External Material Group failed!",
    deletedExternalMaterialGroup: "External Material Group deleted!",
    createdExternalMaterialGroup: "External Material Group created!",
    updatedExternalMaterialGroup: "External Material Group updated!",
    fetchExternalMaterialGroupFailed: "Could not fetch External Material Group with Id:",
    //External Material
    externalMaterial: "External Material",
    externalMaterials: "External Materials",
    noExternalMaterial: "No External Material yet!",
    addExternalMaterial: "Add External Material",
    editExternalMaterial: "Edit External Material",
    enterExternalMaterial: "Enter your External Material details below",
    deleteExternalMaterial: "Delete External Material",
    selectExternalMaterial: "Select External Material",
    externalMaterialIdentical: "External Material is identical!",
    deletionExternalMaterialFailed: "Deletion of External Material failed!",
    creationExternalMaterialFailed: "Creation of External Material failed!",
    updateExternalMaterialFailed: "Update of External Material failed!",
    deletedExternalMaterial: "External Material deleted!",
    createdExternalMaterial: "External Material created!",
    updatedExternalMaterial: "External Material updated!",
    fetchExternalMaterialFailed: "Could not fetch External Material with Id:",
    //Customer
    customer: "Customer",
    customers: "Customers",
    noCustomer: "No Customer yet!",
    addCustomer: "Add Customer",
    editCustomer: "Edit Customer",
    enterCustomer: "Enter your Customer details below",
    deleteCustomer: "Delete Customer",
    selectCustomer: "Select Customer",
    customerIdentical: "Customer is identical!",
    noCustomerSelected: "No Customer selected!",
    deletionCustomerFailed: "Deletion of Customer failed!",
    creationCustomerFailed: "Creation of Customer failed!",
    updateCustomerFailed: "Update of Customer failed!",
    deletedCustomer: "Customer deleted!",
    createdCustomer: "Customer created!",
    updatedCustomer: "Customer updated!",
    fetchCustomerFailed: "Could not fetch Customer with Id:",
    //Customer Order
    customerOrder: "Customer Order",
    customerOrders: "Customer Orders",
    noCustomerOrder: "No Customer Order yet!",
    addCustomerOrder: "Add Customer Order",
    editCustomerOrder: "Edit Customer Order",
    enterCustomerOrder: "Enter your Customer Order details below",
    deleteCustomerOrder: "Delete Customer Order",
    customerOrderIdentical: "Customer Order is identical!",
    deletionCustomerOrderFailed: "Deletion of Customer Order failed!",
    creationCustomerOrderFailed: "Creation of Customer Order failed!",
    updateCustomerOrderFailed: "Update of Customer Order failed!",
    deletedCustomerOrder: "Customer Order deleted!",
    createdCustomerOrder: "Customer Order created!",
    updatedCustomerOrder: "Customer Order updated!",
    fetchCustomerOrderFailed: "Could not fetch Customer Order with Id:",
    //Production Order
    productionOrder: "Production Order",
    productionOrders: "Production Orders",
    noProductionOrder: "No Production Order yet!",
    deleteProductionOrder: "Delete Production Order",
    deletionProductionOrderFailed: "Deletion of Production Order failed!",
    creationProductionOrderFailed: "Creation of Production Order failed!",
    updateProductionOrderFailed: "Update of Production Order failed!",
    deletedProductionOrder: "Production Order deleted!",
    createdProductionOrder: "Production Order created!",
    updatedProductionOrder: "Production Order updated!",
    productId: "Product ID",
    productName: "Product Name",
    //Downtime
    downtime: "Downtime",
    downtimes: "Downtimes",
    noDowntimes: "No downtimes yet!",
    enterDowntime: "Enter your downtime details below",
    deleteDowntime: "Delete downtime",
    addDowntime: "Add downtime",
    editDowntime: "Edit downtime",
    deletionDowntimeFailed: "Deletion of downtime failed!",
    creationDowntimeFailed: "Creation of downtime failed!",
    updateDowntimeFailed: "Update of downtime failed!",
    deletedDowntime: "downtime deleted!",
    createdDowntime: "downtime created!",
    updatedDowntime: "downtime updated!",
    fetchDowntimesFailed: "Could not fetch downtimes!",
    showDowntime: "Show downtimes",

    //Form
    information: "Information",
    name: "Name",
    id: "ID",
    email: "E-mail",
    description: "Description",
    type: "Type",
    quantity: "Quantity",
    duration: "Duration in Min",
    endHour: "End Hour",
    startHour: "Start Hour",
    deliveryDate: "Desired date",
    date: "Date",
    endDate: "End Date",
    end: "End",
    startDate: "Start Date",
    start: "Start",
    initialDate: "Initial Date",
    timeFormat: "(HH:MM, 24-hour)",
    shiftSchedule: "Shift Schedule",
    workHours: "Work Hours",
    isAllDay: "all day",
    horizon: "Planning Horizon (in Days)",
    freeze: "Planning Freeze (in Min)",
    emptySelect: "Nothing to select!",
    noSelection: "No Selection!",
    nothingSelected: "Nothing selected!",
    cancel: "Cancel",
    confirm: "Confirm",
    reset: "Reset",
    save: "Save",
    delete: "Delete",
    edit: "Edit",
    //Failure
    stringInputAllowed: "Allowed characters: letters, numbers, spaces and ( ) - , / & .",
    numberInputAllowed: "Has to be greater 0!",
    dateInputAllowed: "Allowed characters: numbers and .!",
    dateNeeded: "Please enter a date!",
    initialDateBeforeNow: "The initial date has to be after now!",
    descriptionNeeded: "Please enter a description!",
    timeFormatValid: "Time format mismatch: Format should be HH:MM!",
    dailyStartEndValid: "The end has to be greater than the start!",
    timeFormatAllowed: "Time format is HH:MM, 24-hour",
    notValidDate: "Not a valid date!",
    getsRescheduled: "Saving will cause a reschedule for this Line!",
    checkSave: "Please confirm the checkbox before saving",
    counterError: "Only numbers allowed!",
    //Navigation
    home: "Home",
    orders: "Orders",
    operator: "Operator",
    operators: "Operators",
    reporting: "Reporting",
    schedule: "Schedule",
    feedback: "Feedback",
    bom: "BoM",
    viewMode: "View mode",
    material: "Material",
    process: "Process",
    materialProduct: "Materials & Products",
    //Time
    now: "Now",
    week: "Week",
    today: "Today",
    cw: "CW",
    day: "Day",
    month: "Month",
    //Days
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    //Status
    READY: "Ready",
    SCHEDULED: "Scheduled",
    RUNNING: "Running",
    DONE: "Done",
    BLOCKED: "Blocked",
    STOPPED: "Stopped",
    DELAY: "Delay",
    DELAYED: "Delayed",
    TIGHT: "Tight",
    DELIVERY_STATUS: "Delivery Status",
    FINISHED: "Finished",
    ON_RISK: "On Risk",
    IN_TIME: "In Time",
    STATUS: "Status",
    SINGLE: "Single",
    SUBSCRIPTION: "Subscription",
    PRE: "Pre",
    END: "End",
    //Login
    username: "Username",
    password: "Password",
    signIn: "Sign in",
    loginSuccessful: "Login successful!",
    loginFailed: "Login failed",
    loginCredentials: "Your login credentials are wrong. Please try again.",
    logout: "Logout",
    forgotPassword: "Forgot your password?",
    enterEmail: "Enter your email address to reset your password",
    enterNewPassword: "Enter your new password",
    resetPassword: "Reset Password",
    createAccount: "Create Account",
    changePassword: "Change your password",
    confirmPassword: "Confirm Password",
    //Feedback
    feedbackHeader: "Give us your Feedback",
    support: "Support E-Mail",
    subject: "Subject",
    //Schedule
    calculate: "Plan",
    capacity: "Capacity",
    createSchedule: "Create Schedule",
    scheduleMethod: "Scheduling Method",
    makespan: "Makespan",
    //Others
    to: "to",
    order: "Order",
    notOptimised: "not optimised",
    optimised: "Optimised",
    standard: "Default",
    perOrder: "per order",
    po: "PO",
    group: "Group",
    deleted: "deleted",
    poInfos: "PO Infos",
    rows: "rows",
    show: "Show",
    totalSavings: "Total Savings",
    dateFormatDe: "DD.MM.YYYY",
    total: "total",
    entries: "entries",
    loading: "Loading",
    sureConfirm: "Are you sure you want to confirm?",
    ops: "Oops!",
    notFound: "Page not found",
    notExist: "The page you’re looking for doesn’t exist.",
    goHome: "Go Home",
    noResponse: "No response from server!",
};
