import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { en } from "../language/en";
import { de } from "../language/de";

export interface IDictionary {
    [index: string]: string;
}

export const dictionaryList = { en, de };

interface LanguageProps {
    language: "de" | "en";
    setLanguage: Function;
}

export enum Dictionary {
    en = "en",
    de = "de",
}

export const TextContext = createContext<Function>(() => "");
const LanguageContext = createContext<LanguageProps>({} as LanguageProps);

export function useText() {
    return useContext(TextContext);
}

export function useLanguage() {
    return useContext(LanguageContext);
}

export default function LanguageProvider({ children }: any) {
    const [language, setLanguage] = useState<Dictionary>(getInitialLanguage());

    const [dictionary, setDictionary] = useState<IDictionary>(dictionaryList[Dictionary.de]);

    function getInitialLanguage(): Dictionary {
        let language: string | null = localStorage.getItem("language");
        if (language === null || language === "undefined") {
            language = Dictionary.de;
            localStorage.setItem("language", language);
        }
        return Dictionary[getEnumKeyByEnumValue(Dictionary, language)];
    }

    function getEnumKeyByEnumValue<T extends { [index: string]: string }>(myEnum: T, enumValue: string): keyof T {
        let keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
        return keys[0];
    }

    const getText = useCallback(
        (textId: string) => {
            return dictionary[textId];
        },
        [dictionary]
    );

    useEffect(() => {
        if (language !== undefined) {
            setDictionary(dictionaryList[language]);
            localStorage.setItem("language", language);
        }
    }, [language]);

    return (
        <TextContext.Provider value={getText}>
            <LanguageContext.Provider value={{ language, setLanguage }}>{children}</LanguageContext.Provider>
        </TextContext.Provider>
    );
}
