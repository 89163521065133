import React, { useEffect, useState } from "react";
import { deleteProduction, postProduction, putProduction } from "../../api/calls/Production";
import { toast } from "react-toastify";
import { useProductions, useProductionsUpdate } from "../../context/ProductionsContext";
import Modal from "../../components/Modal";
import Input from "../form-inputs/Input";
import FormFooter from "../../components/FormFooter";
import { useProduction } from "../../context/ProductionContext";
import { createEmptyProduction, Production } from "../../api/models/Production";
import { namePattern } from "../../utils/FormUtils";
import { TestIds } from "../../TestIds";
import { useText } from "../../context/LanguageContext";
import TextIds from "../../language/TextIds";
import { NAME_LENGTH } from "../../constants";

interface ProductionFormProps {
    open: boolean;
    setIsOpen: Function;
    production: Production;
}

export default function ProductionForm({ open, setIsOpen, production }: ProductionFormProps) {
    const getText = useText();
    const [formProduction, setFormProduction] = useState<Production>(createEmptyProduction());
    const updateProduction = useProductionsUpdate();
    const initialEditProduction = useProduction();

    const [label, setLabel] = useState<string>("");
    const productions = useProductions();

    const close = () => setIsOpen(false);
    const deleteFunction = () =>
        deleteProduction(production.id, getText).then(() => {
            updateProduction(productions[0].id);
            close();
        });

    useEffect(() => {
        if (production.id > 0) {
            setLabel(getText(TextIds.Production.EDIT));
        } else {
            setLabel(getText(TextIds.Production.ADD));
        }
        setFormProduction(production);
    }, [production, getText]);

    function isDataValid() {
        if (!namePattern.test(formProduction.name)) {
            toast.error(getText(TextIds.Form.NAME_ALLOWED_CHARACTER));
            return false;
        }
        return true;
    }

    function sendUpdateProduction() {
        const updatedProduction = {
            ...initialEditProduction,
            name: formProduction.name,
        };
        return putProduction(updatedProduction, formProduction.id, getText);
    }

    function handleSubmit(e: any) {
        if (e !== undefined) {
            e.preventDefault();
            if (!isDataValid()) return;
            const promise = () =>
                production.id > 0 ? sendUpdateProduction() : postProduction(formProduction, getText);
            promise().then(() => {
                updateProduction();
                close();
            });
        }
    }

    return (
        <Modal title={label} description={getText(TextIds.Production.FORM_SUBTITLE)} open={open} setOpen={setIsOpen}>
            <form className="flex flex-col space-y-4 divide-y divide-solid" onSubmit={(e) => handleSubmit(e)}>
                <div className="space-y-4">
                    <Input
                        labelText={getText(TextIds.Form.NAME)}
                        name="name"
                        value={formProduction.name}
                        type="text"
                        placeholder={getText(TextIds.Form.NAME)}
                        pattern={namePattern.source}
                        title={getText(TextIds.Form.NAME_ALLOWED_CHARACTER)}
                        maxLength={NAME_LENGTH}
                        onChange={(event: any) => setFormProduction({ ...formProduction, name: event.target.value })}
                        required
                        data-testid={TestIds.INPUT_NAME}
                    />
                </div>
                <FormFooter
                    close={close}
                    handleSubmitFunction={handleSubmit}
                    isEditMode={production.id > 0}
                    objectToDelete={production}
                    deleteFunction={deleteFunction}
                />
            </form>
        </Modal>
    );
}
