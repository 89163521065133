import React from "react";
import { Dialog } from "@headlessui/react";
import Button, { ButtonSize, ButtonTypes } from "./buttons/Button";
import { CheckCircleIcon, CheckIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { TestIds } from "../TestIds";
import { useText } from "../context/LanguageContext";
import TextIds from "../language/TextIds";

export interface ConfirmationDialogProps {
    title: string;
    open: boolean;
    setOpen: Function;
    submitFunction: Function;
    children?: JSX.Element | JSX.Element[];
}

export default function ConfirmationDialog({ title, open, setOpen, submitFunction }: ConfirmationDialogProps) {
    const getText = useText();
    const close = () => setOpen(false);

    return (
        <Dialog open={open} onClose={() => undefined}>
            <div className="fixed inset-0 z-100 bg-black bg-opacity-90" />
            <div className="fixed inset-0 z-100 flex items-center justify-center">
                <Dialog.Panel className="w-full max-w-2xl transform divide-y rounded-lg bg-white p-6">
                    <div className="mb-4 grid space-y-4">
                        <CheckIcon className="h-9 justify-self-center rounded-full bg-green-middle text-white"></CheckIcon>
                        <Dialog.Title className=" text-center text-2xl font-semibold text-gray-dark">
                            {title}
                        </Dialog.Title>
                        <Dialog.Description className="text-md ml-2 text-center text-gray-middle">
                            {getText(TextIds.SURE_CONFIRM)}
                        </Dialog.Description>
                    </div>
                    <div className="border-0 border-white pt-4">
                        <div className="flex place-content-between pt-4" data-testid={TestIds.CONFIRM_FOOTER}>
                            <Button
                                title={getText(TextIds.Form.CANCEL)}
                                icon={<XCircleIcon />}
                                buttonType={ButtonTypes.Cancel}
                                buttonSize={ButtonSize.middle}
                                onClick={() => close()}
                            />
                            <Button
                                title={getText(TextIds.Form.CONFIRM)}
                                icon={<CheckCircleIcon />}
                                buttonType={ButtonTypes.Submit}
                                buttonSize={ButtonSize.middle}
                                onClick={() => submitFunction()}
                            />
                        </div>
                    </div>
                </Dialog.Panel>
            </div>
        </Dialog>
    );
}
