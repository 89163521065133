import { Edge, Node } from "reactflow";
import { ViewMode } from "../../pages/schedule/Schedule";
import { ReactElement } from "react";
import TextIds from "../../language/TextIds";

export interface GeneralObjectType {
    id: number;
    name?: string | JSX.Element;

    [propName: string]: any;
}

export interface NavigationItem {
    id: number;
    name: string;
    href: string;
    icon: ReactElement;
}

export interface SelectBoxOption {
    id: number;
    name: any;

    [propName: string]: any;
}

export interface PopUpInfo {
    title: string;
    info: string | number;
}

export interface TimelineElement {
    id: number;
    groupId: number;
    startPosition: number;
    endPosition: number;
    yPosition: number;
    color: string;
    popUp: PopUpInfo[];
}

export interface TimelineDates {
    dates: Date[];
    viewMode: ViewMode;
}

export interface NodesAndEdges {
    nodes: Node[];
    edges: Edge[];
}

export enum OrderStatus {
    READY = TextIds.Status.READY as any,
    SCHEDULED = TextIds.Status.SCHEDULED as any,
    RUNNING = TextIds.Status.RUNNING as any,
    DONE = TextIds.Status.DONE as any,
    BLOCKED = TextIds.Status.BLOCKED as any,
}

export enum TaskStatus {
    READY = TextIds.Status.READY as any,
    SCHEDULED = TextIds.Status.SCHEDULED as any,
    RUNNING = TextIds.Status.RUNNING as any,
    DONE = TextIds.Status.DONE as any,
}

export enum Status {
    READY = TextIds.Status.READY as any,
    RUNNING = TextIds.Status.RUNNING as any,
    STOPPED = TextIds.Status.STOPPED as any,
}

export enum DeliveryStatus {
    DELAYED = TextIds.Status.DELAYED as any,
    TIGHT = TextIds.Status.TIGHT as any,
    IN_TIME = TextIds.Status.IN_TIME as any,
}

export function createEmptyGeneralObjectType(): GeneralObjectType {
    return {
        id: 0,
        name: "",
        internal_id: "",
    };
}
