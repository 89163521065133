import React from "react";
import { ViewMode } from "../../Schedule";
import {
    createDayHeader,
    createMonthHeader,
    createWeekHeader,
    TimelineHeaderValues,
} from "../utils/TimelineHeaderUtils";
import { COLUMN_WIDTH, HEADER_HEIGHT } from "../utils/TimelineUtils";
import { TimelineDates } from "../../../../api/models/GeneralTypes";
import { TestIds } from "../../../../TestIds";
import { useLanguage, useText } from "../../../../context/LanguageContext";
import { DowntimeItem } from "../../../../api/models/Downtime";

export interface TimelineHeaderProps {
    timelineDates: TimelineDates;
    viewMode: ViewMode;
    setViewMode: Function;
    setCurrentViewDate: Function;
    downtimeItems: DowntimeItem[];
}

export function TimelineHeader({
    timelineDates,
    viewMode,
    setViewMode,
    setCurrentViewDate,
    downtimeItems,
}: TimelineHeaderProps): JSX.Element {
    const getText = useText();
    const language = useLanguage().language;
    let timelineHeaderValues: TimelineHeaderValues = {} as TimelineHeaderValues;

    const timelineHeaderProps = {
        timelineDates,
        viewMode,
        setViewMode,
        setCurrentViewDate,
        downtimeItems,
    };

    switch (timelineDates.viewMode) {
        case ViewMode.Month:
            timelineHeaderValues = createMonthHeader(timelineHeaderProps, getText, language);
            break;
        case ViewMode.Week:
            timelineHeaderValues = createWeekHeader(timelineHeaderProps, getText, language);
            break;
        case ViewMode.Day:
            timelineHeaderValues = createDayHeader(timelineHeaderProps, getText, language);
            break;
    }

    return (
        <div style={{ width: COLUMN_WIDTH * timelineDates.dates.length }} data-testid={TestIds.TIMELINE_HEADER}>
            <div className="relative flex">{timelineHeaderValues.monthLine}</div>
            <div className="flex items-center justify-center" style={{ marginTop: HEADER_HEIGHT / 2 }}>
                {timelineHeaderValues.dayLine}
            </div>
            <div className="relative flex">{timelineHeaderValues.downtimes}</div>
        </div>
    );
}
