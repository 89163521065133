import React, { useEffect, useRef } from "react";
import { Grid, GridProps } from "./Grid";
import { TimelineHeader, TimelineHeaderProps } from "./TimelineHeader";
import { TimelineContent } from "./TimelineContent";
import { HEADER_HEIGHT } from "../utils/TimelineUtils";
import { TimelineElement } from "../../../../api/models/GeneralTypes";
import { TimelineLeftHeader, TimelineLeftHeaderProps } from "./TimelineLeftHeader";

export interface TimelineBodyProps {
    gridProps: GridProps;
    timelineHeaderProps: TimelineHeaderProps;
    items: TimelineElement[];
    leftHeaderProps: TimelineLeftHeaderProps;
    scrollX: number | undefined;
    scrollToToday: boolean;
    timelineHeight: number;
}

export function TimelineBody({
    gridProps,
    timelineHeaderProps,
    items,
    leftHeaderProps,
    scrollX,
    scrollToToday,
    timelineHeight,
}: TimelineBodyProps): JSX.Element {
    const horizontalTimelineRef = useRef<HTMLDivElement>(null);
    const verticalTimelineRef = useRef<HTMLDivElement>(null);
    const verticalScrollLeftHeaderRef = useRef<HTMLDivElement>(null);

    function onScrollY() {
        if (!verticalTimelineRef.current) {
            return;
        }
        if (!verticalScrollLeftHeaderRef.current) {
            return;
        }
        verticalScrollLeftHeaderRef.current.scrollTop = verticalTimelineRef.current.scrollTop;
    }

    useEffect(() => {
        if (!scrollToToday) {
            return;
        }
        if (!horizontalTimelineRef.current || !scrollX) {
            return;
        }
        horizontalTimelineRef.current.scrollLeft = scrollX;
    }, [scrollToToday, scrollX]);

    return (
        <div className="flex grow overflow-x-auto">
            <TimelineLeftHeader
                {...leftHeaderProps}
                timelineHeight={timelineHeight}
                ref={verticalScrollLeftHeaderRef}
            />
            <div className="relative overflow-x-auto" ref={horizontalTimelineRef}>
                <div className="border-b-4" style={{ width: gridProps.bodyWidth, height: HEADER_HEIGHT }}>
                    <TimelineHeader {...timelineHeaderProps} />
                </div>
                <div
                    onScroll={onScrollY}
                    ref={verticalTimelineRef}
                    className="relative overflow-y-auto overflow-x-hidden border-b-2 bg-schedule-background"
                    style={{
                        height: timelineHeight + 3,
                        width: gridProps.bodyWidth,
                    }}
                >
                    <Grid {...gridProps} />
                    <TimelineContent items={items} timelineHeight={timelineHeight} />
                </div>
            </div>
        </div>
    );
}
