import React, { ReactElement, useEffect, useState } from "react";
import Tooltip from "../Tooltip";
import StationForm from "../forms/station/StationForm";
import { Handle, Position } from "reactflow";
import { Station } from "../../api/models/Station";
import { Line } from "../../api/models/Line";
import { TestIds } from "../../TestIds";

export interface StationNodeProps {
    data: {
        station: Station;
        line: Line;
    };
}

export default function StationNode({ data }: StationNodeProps): JSX.Element {
    const [isStationFormOpen, setIsStationFormOpen] = useState<boolean>(false);

    const [operationElement, setOperationElement] = useState<ReactElement[]>([]);
    const [isActive, setIsActive] = useState<boolean>(false);
    const OPERATION_BUBBLE_AMOUNT = 10;
    const handlePosition = data.station.operations.length > 0 ? "33%" : "50%";

    useEffect(() => {
        let stationOperations = data.station.operations;
        setOperationElement(stationOperations.map((operation) => <p key={operation.id}> {operation.name}</p>));
    }, [data]);

    function getOperationsCount(): JSX.Element[] {
        let operationDivs = [];
        for (let i = 1; i <= OPERATION_BUBBLE_AMOUNT; i++) {
            if (i <= data.station.operations.length)
                operationDivs.push(<div key={i} className="ml-0.5 h-2.5 w-2.5 rounded-full bg-black" />);
        }
        if (data.station.operations.length > OPERATION_BUBBLE_AMOUNT)
            operationDivs.push(
                <div key="plus" className="ml-0.5 text-xs font-semibold">
                    +{data.station.operations.length - OPERATION_BUBBLE_AMOUNT}
                </div>
            );
        return operationDivs;
    }

    return (
        <div
            onDoubleClick={() => setIsStationFormOpen(true)}
            onClick={() => setIsActive(!isActive)}
            onMouseLeave={() => setIsActive(false)}
            className="grid cursor-pointer justify-items-center"
        >
            <Handle
                type="target"
                position={Position.Left}
                isConnectable={false}
                style={{ background: "transparent", border: "none", left: 0, top: handlePosition }}
            />
            <Tooltip content={operationElement} tooltipId={"tooltip" + data.station.id}>
                <div className="flex flex-col items-center">
                    <div
                        className="flex h-10 min-w-[6rem] items-center justify-center rounded-xl border border-black bg-gray-very-light px-2 xl:w-40"
                        style={{ boxShadow: isActive ? "0 0 0 100vmax rgba(0, 0, 0, .3)" : "" }}
                        data-testid={TestIds.STATION_NODE + data.station.id}
                    >
                        <div
                            className="grow font-semibold"
                            lang="de"
                            style={{
                                wordWrap: "break-word",
                                overflowWrap: "break-word",
                                hyphens: "auto",
                            }}
                        >
                            {data.station.name}
                        </div>
                    </div>
                    {data.station.operations.length > 0 && (
                        <div className="flex w-36 items-center rounded-md border border-black bg-gray-very-light p-0.5">
                            {getOperationsCount()}
                        </div>
                    )}
                </div>
            </Tooltip>
            <Handle
                type="source"
                position={Position.Right}
                isConnectable={false}
                style={{ background: "transparent", border: "none", right: 0, top: handlePosition }}
            />
            <StationForm
                selectedStation={data.station}
                selectedLine={data.line}
                setOpen={setIsStationFormOpen}
                open={isStationFormOpen}
            />
        </div>
    );
}
