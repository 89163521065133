import { GeneralObjectType } from "../api/models/GeneralTypes";
import { sortByIdAsc } from "./SortUtils";

export const namePattern: RegExp = /[a-zA-Z\d\u00C0-\u00FF][a-zA-Z\d\u00C0-\u00FF\u0020\-,()/&.]{0,29}/;

export const timePattern: RegExp = /([0-1]?\d|2[0-3]):[0-5]\d/;

export const textPattern: RegExp = /[\u0021-\u007e\u00a0-\u00ff][\u0020-\u007e\u00c0-\u00ff]{0,99}/;

export const textAreaPattern: RegExp = /[a-zA-Z\d\u00C0-\u00FF][\u0020-\u007e\u00a0-\u00ff]{0,99}/;

export const numberPattern: RegExp = /^\d+$/;

export const datePattern: RegExp = /\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])/;

export function getJsonFromList(elements: GeneralObjectType[]) {
    elements.sort(sortByIdAsc);
    return JSON.stringify(elements);
}
