import { useRef } from "react";

interface CancelPromiseType {
    promise: Promise<unknown>;
    cancel: () => boolean;
}

const delay = (milliseconds: number) => new Promise((resolve) => setTimeout(resolve, milliseconds));

function useClickPreventionOnDoubleClick(onClick: Function, onDoubleClick?: Function, ...params: any[]) {
    const promiseHandler = useCancellablePromises();

    const handleClick = () => {
        promiseHandler.clearPendingPromises();
        const waitForClick: CancelPromiseType = cancellablePromise(delay(200));
        promiseHandler.appendPendingPromise(waitForClick);

        return waitForClick.promise
            .then(() => {
                promiseHandler.removePendingPromise(waitForClick);
                onClick(...params);
            })
            .catch((errorInfo) => {
                promiseHandler.removePendingPromise(waitForClick);
                if (!errorInfo.isCanceled) {
                    throw errorInfo.error;
                }
            });
    };

    const handleDoubleClick = () => {
        promiseHandler.clearPendingPromises();
        if (onDoubleClick) onDoubleClick(...params);
    };

    return [handleClick, handleDoubleClick];
}

function cancellablePromise(promise: Promise<any>) {
    let isCanceled = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            (value) => (isCanceled ? reject({ isCanceled, value }) : resolve(value)),
            (error) => reject({ isCanceled, error })
        );
    });

    return {
        promise: wrappedPromise,
        cancel: () => (isCanceled = true),
    };
}

function useCancellablePromises() {
    const pendingPromises = useRef([] as CancelPromiseType[]);

    const appendPendingPromise = (promise: CancelPromiseType) =>
        (pendingPromises.current = [...pendingPromises.current, promise]);

    const removePendingPromise = (promise: CancelPromiseType) =>
        (pendingPromises.current = pendingPromises.current.filter((p) => p !== promise));

    const clearPendingPromises = () => pendingPromises.current.map((p) => p.cancel());

    return {
        appendPendingPromise,
        removePendingPromise,
        clearPendingPromises,
    };
}

export default useClickPreventionOnDoubleClick;
