import React, { useCallback } from "react";
import { Login } from "./pages/login/Login";
import Page from "./pages/Page";
import "react-toastify/dist/ReactToastify.css";
import useToken from "./hook/UseToken";
import ProductionContext from "./context/ProductionContext";
import ProductionsContext from "./context/ProductionsContext";
import LanguageContext from "./context/LanguageContext";
import { ToastContainer } from "react-toastify";

export default function App() {
    const { token, removeToken, setToken } = useToken();

    const getPage = useCallback(() => {
        if (!token) return <Login setToken={setToken} />;
        return (
            <ProductionsContext>
                <ProductionContext>
                    <div className="overflow-x-hidden text-sm">
                        <Page logout={removeToken} />
                    </div>
                </ProductionContext>
            </ProductionsContext>
        );
    }, [token, removeToken, setToken]);

    return (
        <LanguageContext>
            {getPage()}
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                className="whitespace-pre-line"
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
            />
        </LanguageContext>
    );
}
