import axios from "axios";
import { BASE_URL, PRODUCTS_ENDPOINT } from "../ApiConfig";
import { getToken } from "../../hook/UseToken";
import { handleApiGetCall } from "../../utils/ApiUtils";
import { Product } from "../models/Product";

const products = "Products";
const endpoint = BASE_URL + PRODUCTS_ENDPOINT;

type Products = {
    products: Product[];
};

export async function getValidEndProducts(production_id: number, getText: Function): Promise<Products> {
    const response = axios.get(endpoint + "/" + production_id, getToken());
    return handleApiGetCall(response, products, production_id, getText);
}
