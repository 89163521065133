import Label from "../Label";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { TestIds } from "../../TestIds";
import { useEffect, useState } from "react";

interface CheckboxProps {
    label: string;
    checked: boolean;
    setChecked: Function;
}

export default function Checkbox({ checked, setChecked, label }: CheckboxProps): JSX.Element {
    const [active, setActive] = useState(checked);

    const handleChange = () => {
        setChecked(!active);
    };

    useEffect(() => {
        setActive(checked);
    }, [checked]);

    return (
        <div className="flex items-center space-x-2">
            <div
                className="flex h-6 min-w-6 cursor-pointer select-none justify-center rounded-md border border-gray-light p-0"
                onClick={handleChange}
                data-testid={TestIds.CHECKBOX}
            >
                {active && <XMarkIcon className="h-5 text-xl font-thin" data-testid={TestIds.CHECKBOX_CHECKED} />}
            </div>
            <Label>{label}</Label>
        </div>
    );
}
