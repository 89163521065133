import axios from "axios";
import { BASE_URL, CUSTOMER_ENDPOINT } from "../ApiConfig";
import { generateToastText, handleApiCall, handleApiGetCall } from "../../utils/ApiUtils";
import { getToken } from "../../hook/UseToken";
import { Customer } from "../models/Customer";

const customer = "Customer";
const endpoint = BASE_URL + CUSTOMER_ENDPOINT;

export async function getCustomer(customerId: number, getText: Function): Promise<Customer> {
    const response = axios.get(endpoint + "/" + customerId, getToken());
    return handleApiGetCall(response, customer, customerId, getText);
}

export async function putCustomer(customerJson: Customer, customerId: number, getText: Function): Promise<Customer> {
    const messages = generateToastText(customer, "PUT", getText);
    const response = axios.put(endpoint + "/" + customerId, customerJson, getToken());
    return handleApiCall(response, messages);
}

export async function postCustomer(customerJson: Customer, getText: Function): Promise<Customer> {
    const messages = generateToastText(customer, "POST", getText);
    const response = axios.post(endpoint, customerJson, getToken());
    return handleApiCall(response, messages);
}

export async function deleteCustomer(customerId: number, getText: Function): Promise<Customer> {
    const messages = generateToastText(customer, "DELETE", getText);
    const response = axios.delete(endpoint + "/" + customerId, getToken());
    return handleApiCall(response, messages);
}
