import React, { useEffect, useState } from "react";
import { GeneralObjectType } from "../api/models/GeneralTypes";
import { sortByValue } from "../utils/SortUtils";
import { createSortChevrons, tableHeaderClass } from "../utils/TableUtils";

interface HeaderProps {
    className?: string;
    columns: { headerTitle: string; sortingValue: string[]; className?: string }[];
}

interface TableProps {
    header: HeaderProps;
    elements: GeneralObjectType[];
    setSortedElements: Function;
    isExpanding?: boolean;
    isEditable?: boolean;
    id?: string;
    children?: JSX.Element | JSX.Element[];
}

export default function Table({
    header,
    elements,
    isExpanding = false,
    isEditable = false,
    setSortedElements,
    id,
    children,
}: TableProps): JSX.Element {
    const initialSorting = header.columns[0] ? header.columns[0].sortingValue : [];
    const [sortBy, setSortBy] = useState(initialSorting);
    const [isSortAsc, setIsSortAsc] = useState(true);

    useEffect(() => {
        elements.sort((a, b) => sortByValue(a, b, sortBy, isSortAsc));
        setSortedElements([...elements]);
    }, [sortBy, isSortAsc, elements]);

    function createHeaderItem(title: string, sortValue: string[], className?: string): JSX.Element {
        const classes = `${header.className} ${className} `;
        return (
            <th className={classes} key={title}>
                <div className="flex gap-1">
                    {title}
                    {createSortChevrons(
                        sortValue,
                        sortBy,
                        isSortAsc,
                        (sortByValues: string[]) => setSortBy(sortByValues),
                        (isAsc: boolean) => setIsSortAsc(isAsc)
                    )}
                </div>
            </th>
        );
    }

    function createHeader(): JSX.Element[] {
        let headerItems = [];
        if (isExpanding) headerItems.push(<th key="chevron" className={header.className} />);
        for (let item of header.columns) {
            headerItems.push(createHeaderItem(item.headerTitle, item.sortingValue, item.className));
        }
        if (isEditable) headerItems.push(<th key="edit" className={header.className} />);
        return headerItems;
    }

    return (
        <table className="w-full table-auto select-none" data-testid={id}>
            <thead className="sticky top-0 z-10">
                <tr className={tableHeaderClass}>{createHeader()}</tr>
            </thead>
            {children}
        </table>
    );
}
