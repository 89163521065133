import { ProductionListElement } from "../../api/models/Production";
import { useText } from "../../context/LanguageContext";
import useClickPreventionOnDoubleClick from "../../hook/ClickPrevention";
import { TestIds } from "../../TestIds";
import { BuildingOfficeIcon } from "@heroicons/react/20/solid";
import TextIds from "../../language/TextIds";
import React from "react";

interface ProductionElementProps {
    production: ProductionListElement;
    active: boolean;
    onClick: Function;
    onDoubleClick: Function;
}

export default function ProductionElement({
    production,
    active,
    onClick,
    onDoubleClick,
}: ProductionElementProps): JSX.Element {
    const getText = useText();
    const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(onClick, onDoubleClick, production.id);

    let className = `h-6 ${active ? "text-green-middle" : "text-gray-light"} `;
    return (
        <div
            data-testid={TestIds.PRODUCTION + production.id}
            className="flex h-48 w-48 cursor-pointer flex-col justify-center gap-2 rounded-md border border-gray-very-light p-4"
            onClick={handleClick}
            onDoubleClick={handleDoubleClick}
        >
            <div className="flex items-center space-x-2">
                <BuildingOfficeIcon className={className} />
                <b>{production.name}</b>
            </div>
            <div>
                {getText(TextIds.Line.PLURAL_NAME)}: {production.lines_count}
            </div>
            <div>
                {getText(TextIds.ProductGroup.PLURAL_NAME)}: {production.product_groups_count}
            </div>
            <div>
                {getText(TextIds.CustomerOrder.PLURAL_NAME)}: {production.customer_orders_count}
            </div>
            <div>
                {getText(TextIds.ProductionOrder.PLURAL_NAME)}: {production.production_orders_count}
            </div>
        </div>
    );
}
