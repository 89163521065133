import { useProductions } from "../../context/ProductionsContext";
import { TestIds } from "../../TestIds";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import React, { useCallback, useMemo, useState } from "react";
import ProductionForm from "../../components/forms/ProductionForm";
import { useProduction, useProductionUpdate } from "../../context/ProductionContext";
import { createEmptyProduction, ProductionListElement } from "../../api/models/Production";
import { useText } from "../../context/LanguageContext";
import TextIds from "../../language/TextIds";
import { useNavigate } from "react-router-dom";
import ProductionElement from "./ProductionElement";

export default function Productions(): JSX.Element {
    const getText = useText();

    const navigate = useNavigate();
    const activeProduction = useProduction();
    const updateProduction = useProductionUpdate();

    const productions = useProductions();
    const [productionId, setProductionId] = useState<number>(activeProduction.id);

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const openModal = () => setIsModalOpen(true);

    const changeProduction = useCallback(
        (productionId: number) => {
            if (activeProduction.id !== productionId) updateProduction(productionId);
            navigate("/orders");
        },
        [updateProduction, navigate]
    );

    const editProduction = useCallback(
        (productionId: number) => {
            updateProduction(productionId);
            setProductionId(productionId);
            openModal();
        },
        [setProductionId, updateProduction]
    );

    const addProductionButton = useMemo(() => {
        return (
            <div
                className="flex h-20 w-20 cursor-pointer items-center justify-center rounded-md border"
                onClick={() => {
                    setProductionId(0);
                    openModal();
                }}
                data-testid={TestIds.ADD_PRODUCTION_BUTTON}
            >
                <PlusCircleIcon className="h-7 w-7" />
                <ProductionForm
                    open={isModalOpen}
                    setIsOpen={setIsModalOpen}
                    production={productionId === 0 ? createEmptyProduction() : activeProduction}
                />
            </div>
        );
    }, [isModalOpen, productionId, setIsModalOpen, activeProduction]);

    if (productions.length === 0)
        return (
            <div className="flex flex-col items-center justify-center">
                <div className="text-lg text-gray-dark">{getText(TextIds.Production.NONE)}</div>
                {addProductionButton}
            </div>
        );

    return (
        <div className="flex flex-wrap gap-4">
            {productions.map((production: ProductionListElement) => {
                return (
                    <ProductionElement
                        key={production.id}
                        production={production}
                        active={production.id === activeProduction.id}
                        onClick={changeProduction}
                        onDoubleClick={editProduction}
                    />
                );
            })}
            {addProductionButton}
        </div>
    );
}
