import axios from "axios";
import { BASE_URL, STATION_ENDPOINT } from "../ApiConfig";
import { getToken } from "../../hook/UseToken";
import { Station } from "../models/Station";
import { generateToastText, handleApiCall, handleApiGetCall } from "../../utils/ApiUtils";

const station = "Station";
const endpoint = BASE_URL + STATION_ENDPOINT;

export async function getStation(stationId: number, getText: Function): Promise<Station> {
    const response = axios.get(endpoint + "/" + stationId, getToken());
    return handleApiGetCall(response, station, stationId, getText);
}

export async function putStation(stationJson: Station, stationId: number, getText: Function): Promise<Station> {
    const messages = generateToastText(station, "PUT", getText);
    const response = axios.put(endpoint + "/" + stationId, stationJson, getToken());
    return handleApiCall(response, messages);
}

export async function postStation(stationJson: Station, getText: Function): Promise<Station> {
    const messages = generateToastText(station, "POST", getText);
    const response = axios.post(endpoint, stationJson, getToken());
    return handleApiCall(response, messages);
}

export async function deleteStation(stationId: number, getText: Function): Promise<Station> {
    const messages = generateToastText(station, "DELETE", getText);
    const response = axios.delete(endpoint + "/" + stationId, getToken());
    return handleApiCall(response, messages);
}
