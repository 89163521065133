import React from "react";
import { Handle, Position } from "reactflow";

export interface HandleNodeProps {
    data: { children: JSX.Element };
}

export default function HandleNode({ data }: HandleNodeProps): JSX.Element {
    return (
        <>
            <Handle
                type="target"
                position={Position.Left}
                isConnectable={false}
                style={{ background: "transparent", border: "none", left: 0 }}
            />
            {data.children}
            <Handle
                type="source"
                position={Position.Right}
                isConnectable={false}
                style={{ background: "transparent", border: "none", right: 0 }}
            />
        </>
    );
}
