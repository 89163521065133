import { ChevronDownIcon, ChevronRightIcon, ChevronUpDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import React from "react";
import { GeneralObjectType } from "../api/models/GeneralTypes";

export const tableHeaderClass = [
    "h-10",
    "first:[&>th]:rounded-l-lg",
    "first:[&>th]:pl-4",
    "last:[&>th]:rounded-r-lg",
    "last:[&>th]:pr-4",
].join(" ");

export const tableRowClass = [
    "group",
    "h-10",
    "first:[&>td]:rounded-l-lg",
    "first:[&>td]:pl-4",
    "last:[&>td]:rounded-r-lg",
    "last:[&>td]:pr-4",
    "[&>td]:hover:bg-gray-very-light",
    "group/row",
    "group-hover/row:[&>td]:bg-gray-very-light",
].join(" ");
export const tableBodyClass = "overflow-hidden";

export function createSortChevrons(
    sortValue: string[],
    sortBy: string[],
    isSortAsc: boolean,
    setSortBy: Function,
    setIsSortAsc: Function
): JSX.Element {
    if (!isColumSorted(sortValue, sortBy))
        return (
            <ChevronUpDownIcon
                className="w-5 cursor-pointer"
                onClick={() => {
                    setSortBy(sortValue);
                    setIsSortAsc(true);
                }}
            />
        );
    if (isSortAsc) return <ChevronDownIcon className="w-3 cursor-pointer" onClick={() => setIsSortAsc(false)} />;
    return <ChevronUpIcon className="w-3 cursor-pointer" onClick={() => setIsSortAsc(true)} />;
}

export function isColumSorted(sortValue: string[], sortBy: string[]): boolean {
    return sortBy.every((value, index) => value === sortValue[index]);
}

export function createExpanderChevron(
    element: GeneralObjectType,
    expandedElementId: number,
    expandedTableElements: GeneralObjectType[],
    expand: any
): JSX.Element {
    if (expandedTableElements.length === 0) return <></>;
    if (element.id === expandedElementId) return <ChevronDownIcon className="w-4 cursor-pointer" onClick={expand} />;

    return <ChevronRightIcon className="w-4 cursor-pointer" onClick={expand} />;
}
