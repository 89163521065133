import axios from "axios";
import { BASE_URL, SCHEDULE_ENDPOINT } from "../ApiConfig";
import { getToken } from "../../hook/UseToken";
import { generateToastText, handleApiCall, handleApiGetCall } from "../../utils/ApiUtils";
import { Schedule } from "../models/Schedule";

const schedule = "Schedule";
const endpoint = BASE_URL + SCHEDULE_ENDPOINT;

export async function getSchedule(scheduleId: number, getText: Function): Promise<Schedule> {
    const response = axios.get(endpoint + "/" + scheduleId, getToken());
    return handleApiGetCall(response, schedule, scheduleId, getText);
}

export async function calculateSchedule(scheduleId: number, method: string, getText: Function): Promise<Schedule> {
    const messages = generateToastText(schedule, "POST", getText);
    const response = axios.post(endpoint, { id: scheduleId, scheduling_method: method }, getToken());
    return handleApiCall(response, messages);
}
