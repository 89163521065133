import { timePattern } from "../../utils/FormUtils";
import Input from "./Input";
import React, { ChangeEvent, useCallback } from "react";

interface TimeInputProps {
    value: string;
    label?: string;
    setTime: Function;

    [optionalProperties: string]: any;
}

export default function TimeInput({ value, label, setTime, ...optionalProperties }: TimeInputProps): JSX.Element {
    const setValue = useCallback((event: ChangeEvent<HTMLInputElement>) => setTime(event.target.value), [setTime]);
    return (
        <Input
            labelText={label}
            value={value.substring(0, 5)}
            type="text"
            pattern={timePattern.source}
            maxLength={5}
            onChange={setValue}
            required
            className="w-24"
            {...optionalProperties}
        />
    );
}
