import React, { ChangeEvent, useEffect, useState } from "react";
import Input from "../../form-inputs/Input";
import Label from "../../Label";
import MultiSelect from "../../form-inputs/MultiSelect";
import { useProduction } from "../../../context/ProductionContext";
import Select from "../../form-inputs/Select";
import { Station } from "../../../api/models/Station";
import { Line } from "../../../api/models/Line";
import { ProductGroup } from "../../../api/models/ProductGroup";
import { namePattern } from "../../../utils/FormUtils";
import { TestIds } from "../../../TestIds";
import TextIds from "../../../language/TextIds";
import { useText } from "../../../context/LanguageContext";
import { NAME_LENGTH } from "../../../constants";

export interface StationInformationFormProps {
    selectedStation: Station;
    selectedLine: Line;
    setSelectedStation: Function;
    setSelectedPredecessors: Function;
}

export default function StationInformationForm({
    selectedStation,
    selectedLine,
    setSelectedStation,
    setSelectedPredecessors,
}: StationInformationFormProps): JSX.Element {
    const getText = useText();

    const production = useProduction();
    const [predecessors, setPredecessors] = useState<Station[]>([]);

    const [station, setStation] = useState<Station>({ ...selectedStation });

    const [productGroups, setProductGroups] = useState<ProductGroup[]>([]);

    useEffect(() => {
        if (selectedStation.id > 0) {
            let newPredecessors = [];
            for (let station of selectedLine.stations) {
                if (selectedStation.predecessors.includes(station.id)) newPredecessors.push(station);
            }
            setPredecessors(newPredecessors);
        } else {
            setPredecessors([]);
        }
        setStation({ ...selectedStation, line_id: selectedLine.id });
        filterProductGroups();
        // eslint-disable-next-line
    }, [selectedStation, selectedLine]);

    useEffect(() => {
        setSelectedStation(station);
    }, [station, setSelectedStation]);

    useEffect(() => {
        setSelectedPredecessors(predecessors);
    }, [predecessors, setSelectedPredecessors]);

    function filterProductGroups(): void {
        const filteredProductGroups = production.product_groups.filter((productGroup) => productGroup.station_id === 0);

        if (selectedStation.product_group && selectedStation.product_group?.id !== 0) {
            setProductGroups([selectedStation.product_group, ...filteredProductGroups]);
            return;
        }
        setProductGroups(filteredProductGroups);
    }

    function getPredecessors(): Station[] {
        return selectedLine.stations.filter(
            (deleteStation) => !station.successors.includes(deleteStation.id) && station.id !== deleteStation.id
        );
    }

    return (
        <div className="space-y-4 p-4">
            <Input
                labelText={getText(TextIds.Form.NAME)}
                name="name"
                value={station.name}
                type="text"
                placeholder={getText(TextIds.Form.NAME)}
                pattern={namePattern.source}
                title={getText(TextIds.Form.NAME_ALLOWED_CHARACTER)}
                maxLength={NAME_LENGTH}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    setStation({ ...station, name: event.target.value })
                }
                required
                data-testid={TestIds.INPUT_NAME}
            />
            <div>
                <Label>
                    {getText(TextIds.Form.DESCRIPTION)}: <span className="text-red-600">*</span>
                </Label>
                <textarea
                    className="relative mt-1 block h-48 w-full resize-none appearance-none overflow-auto rounded-md border border-gray-light px-3 py-2 text-gray-middle placeholder-gray-light focus:z-10 focus:border-green-middle focus:outline-none focus:ring-green-middle"
                    name="description"
                    value={station.description}
                    placeholder={getText(TextIds.Form.DESCRIPTION)}
                    maxLength={100}
                    onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                        setStation({ ...station, description: event.target.value })
                    }
                    required
                    data-testid={TestIds.INPUT_DESCRIPTION}
                />
            </div>
            <div>
                <Select
                    label={getText(TextIds.ProductGroup.NAME)}
                    options={productGroups}
                    defaultValue={station.product_group}
                    onChange={(productGroup: ProductGroup) =>
                        setStation({
                            ...station,
                            product_group: productGroup,
                        })
                    }
                />
                <MultiSelect
                    label={getText(TextIds.Station.PREDECESSORS)}
                    options={[...getPredecessors()]}
                    defaultValues={[...predecessors]}
                    onChange={setPredecessors}
                    id={TestIds.STATION_SELECT}
                />
            </div>
        </div>
    );
}
