import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { createEmptyProduction } from "../api/models/Production";
import { useProductions } from "./ProductionsContext";
import { isElementInListById } from "../utils/FilterUtils";
import { getProduction } from "../api/calls/Production";
import { useText } from "./LanguageContext";

const ProductionContext = createContext(createEmptyProduction());
const ProductionUpdateContext = createContext<Function>(() => "");

export function useProduction() {
    return useContext(ProductionContext);
}

export function useProductionUpdate() {
    return useContext(ProductionUpdateContext);
}

export default function ProductionProvider({ children }: any) {
    const getText = useText();
    const productions = useProductions();
    const [production, setProduction] = useState(createEmptyProduction);

    const getProductionById = useCallback(
        (id: number) => {
            getProduction(id, getText).then((production) => {
                localStorage.setItem("production_id", production.id.toString());
                setProduction(production);
            });
        },
        [setProduction, getText]
    );

    const updateProduction = useCallback(
        (production_id: number) => {
            if (production_id !== 0) getProductionById(production_id);
        },
        [getProductionById]
    );

    useEffect(() => {
        let production_id: number | string | null = localStorage.getItem("production_id");
        if (productions.length === 0) {
            setProduction(createEmptyProduction);
            return;
        }
        if (!production_id || !isElementInListById(productions, +production_id)) {
            production_id = productions[0].id;
        }
        getProductionById(+production_id);
    }, [productions, getProductionById]);

    return (
        <ProductionContext.Provider value={production}>
            <ProductionUpdateContext.Provider value={updateProduction}>{children}</ProductionUpdateContext.Provider>
        </ProductionContext.Provider>
    );
}
