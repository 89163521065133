import axios from "axios";
import { BASE_URL, USER_PRODUCTIONS_ENDPOINT } from "../ApiConfig";
import { getToken } from "../../hook/UseToken";
import { ProductionListElement } from "../models/Production";
import { sortByIdAsc } from "../../utils/SortUtils";
import { handleApiCall } from "../../utils/ApiUtils";
import TextIds from "../../language/TextIds";

const endpoint = BASE_URL + USER_PRODUCTIONS_ENDPOINT;

export async function getUserProductions(getText: Function): Promise<ProductionListElement[]> {
    return handleApiCall(axios.get(endpoint, getToken()), ["", getText(TextIds.Production.FETCH_FAILED)]).then(
        (response) => {
            if (response["productions"] === undefined) return [];
            return response["productions"].sort(sortByIdAsc);
        }
    );
}
