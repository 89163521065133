import React, { useState } from "react";
import { CustomerOrderDetails } from "../../api/models/CustomerOrderDetails";
import { getDeliveryMarble } from "../../utils/StatusMarbleUtils";
import Table from "../../components/Table";
import { TestIds } from "../../TestIds";
import { tableBodyClass, tableRowClass } from "../../utils/TableUtils";
import { useText } from "../../context/LanguageContext";
import TextIds from "../../language/TextIds";

export interface CustomerOrderDetailTableProps {
    orderDetails: CustomerOrderDetails[];
}

export default function CustomerOrderDetailTable({ orderDetails }: CustomerOrderDetailTableProps): JSX.Element {
    const getText = useText();
    const [sortedOrderDetails, setSortedOrderDetails] = useState<CustomerOrderDetails[]>([]);

    const tableHeader = {
        className: "bg-gray-bright font-thin",
        columns: [
            { headerTitle: getText(TextIds.ProductionOrder.NAME), sortingValue: ["production_order_id"] },
            {
                headerTitle: `${getText(TextIds.Product.NAME)} ${getText(TextIds.Form.ID)}`,
                sortingValue: ["product", "internal_id"],
            },
            {
                headerTitle: `${getText(TextIds.Product.NAME)} ${getText(TextIds.Form.NAME)}`,
                sortingValue: ["product", "name"],
            },
            { headerTitle: getText(TextIds.Form.QUANTITY), sortingValue: ["quantity"], className: "w-32" },
            {
                headerTitle: getText(TextIds.Status.DELIVERY_STATUS),
                sortingValue: ["delivery_status"],
                className: "w-32",
            },
        ],
    };
    return (
        <div className="rounded-2xl border-8 border-gray-very-light">
            <div className="flex max-h-64 justify-center overflow-auto rounded-lg">
                <Table
                    elements={orderDetails}
                    setSortedElements={setSortedOrderDetails}
                    id={TestIds.CUSTOMER_ORDER_DETAIL_TABLE}
                    header={tableHeader}
                >
                    <tbody className={tableBodyClass}>
                        {sortedOrderDetails.map((orderDetail) => (
                            <tr className={tableRowClass} key={orderDetail.id}>
                                <td>{orderDetail.production_order_id}</td>
                                <td>{orderDetail.product?.internal_id}</td>
                                <td>{orderDetail.product?.name}</td>
                                <td>{orderDetail.quantity}</td>
                                <td className="pl-3">{getDeliveryMarble(orderDetail.delivery_status)}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}
