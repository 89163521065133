import React from "react";
import FlowTree from "../../components/flow/FlowTree";
import { Edge, MarkerType, Node } from "reactflow";
import { GRAY_DARK } from "../../colors";
import { GeneralObjectType, NodesAndEdges, SelectBoxOption } from "../../api/models/GeneralTypes";
import Select from "../../components/form-inputs/Select";

export function createFlowTree(
    nodesAndEdges: NodesAndEdges,
    element: GeneralObjectType,
    isFullscreen?: boolean,
    setIsFullscreen?: Function,
    children?: JSX.Element
): JSX.Element {
    if (nodesAndEdges.nodes.length === 0) return <div />;
    return (
        <div className="h-[65vh]">
            <FlowTree
                edges={nodesAndEdges.edges}
                nodes={nodesAndEdges.nodes}
                key={"Flow" + element.id}
                isFlowFullscreen={isFullscreen}
                setIsFlowFullscreen={setIsFullscreen}
            >
                {children}
            </FlowTree>
        </div>
    );
}

export function createSelect(
    setSelectedElement: Function,
    defaultSelectedElement: SelectBoxOption,
    selectableElements: SelectBoxOption[]
): JSX.Element {
    if (selectableElements.length === 1) return <div />;
    return (
        <Select
            className="m-2 w-64"
            onChange={(element: GeneralObjectType) => setSelectedElement(element)}
            defaultValue={defaultSelectedElement}
            options={selectableElements}
            noSelectionOption={false}
        />
    );
}

export function createNode(nodeId: string, children: JSX.Element): Node {
    return {
        id: nodeId,
        data: {
            children: children,
        },
        type: "handleNode",
        position: { x: 0, y: 0 },
    };
}

export function createEdge(targetId: string, sourceId: string): Edge {
    return {
        id: "edge" + targetId + sourceId,
        target: targetId,
        source: sourceId,
        style: { strokeWidth: 2, stroke: GRAY_DARK },
        type: "step",
        markerEnd: {
            type: MarkerType.ArrowClosed,
            color: GRAY_DARK,
            width: 15,
            height: 15,
        },
    };
}

export function createNodeId(id: number, nodeType: string, nodes: Node[]): string {
    let i = id;
    let nodeId = nodeType + i;
    // eslint-disable-next-line
    while (nodes.some((value) => value.id.includes(nodeId))) {
        i++;
        nodeId = nodeType + i;
    }
    return nodeId;
}

export function createDottedEdge(targetId: string, sourceId: string): Edge {
    return {
        id: "edge" + targetId + sourceId,
        target: targetId,
        source: sourceId,
        style: { strokeWidth: 2, stroke: GRAY_DARK, strokeDasharray: "5,5" },
        type: "step",
        markerEnd: {
            type: MarkerType.ArrowClosed,
            color: GRAY_DARK,
            width: 15,
            height: 15,
        },
    };
}
