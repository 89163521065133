import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import Modal from "../../Modal";
import FormFooter from "../../FormFooter";
import { useProduction, useProductionUpdate } from "../../../context/ProductionContext";
import { createEmptyStation, Station } from "../../../api/models/Station";
import { createEmptyLine, Line } from "../../../api/models/Line";
import { deleteStation, postStation, putStation } from "../../../api/calls/Station";
import { namePattern, textAreaPattern } from "../../../utils/FormUtils";
import { GeneralObjectType } from "../../../api/models/GeneralTypes";
import Tabs from "../../Tabs";
import StationInformationForm from "./StationInformationForm";
import { useText } from "../../../context/LanguageContext";
import TextIds from "../../../language/TextIds";
import StationOperationView from "./StationOperationView";
import OperationForm from "../OperationForm";
import { createEmptyOperation, Operation } from "../../../api/models/Operation";
import { SCHEDULE_BLUE_LIGHT } from "../../../colors";
import { getById } from "../../../utils/FilterUtils";

export interface StationFormProps {
    selectedStation: Station;
    selectedLine: Line;
    setOpen: Function;
    open: boolean;
}

export default function StationForm({ selectedStation, selectedLine, setOpen, open }: StationFormProps): JSX.Element {
    const getText = useText();

    const production = useProduction();
    const updateProduction = useProductionUpdate();

    const [selectedPredecessors, setSelectedPredecessors] = useState<Station[]>([]);

    const [station, setStation] = useState<Station>({ ...selectedStation });
    const [operations, setOperations] = useState<Operation[]>(selectedStation.operations);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [label, setLabel] = useState<string>("");

    const [selectedOperation, setSelectedOperation] = useState<Operation>(createEmptyOperation);
    const [openOperation, setOpenOperation] = useState<boolean>(false);

    const tabSelections = useMemo(() => {
        return [
            {
                id: 1,
                name: "Information",
            },
            {
                id: 2,
                name: "Operations",
                disabled: selectedStation.id === 0,
            },
        ];
    }, [selectedStation.id]);
    const [selectedTab, setSelectedTab] = useState<GeneralObjectType>(tabSelections[0]);

    useEffect(() => {
        if (station.id !== 0) {
            const line = getById(selectedLine.id, production.lines, createEmptyLine);
            const newStation = getById(station.id, line.stations, createEmptyStation);
            setOperations(newStation.operations);
            setStation(newStation);
        }
    }, [setOperations, setStation, production]);

    useEffect(() => {
        if (selectedStation.id > 0) {
            setIsEditMode(true);
            setLabel(getText(TextIds.Station.EDIT));
        } else {
            setIsEditMode(false);
            setLabel(getText(TextIds.Station.ADD));
        }
    }, [selectedStation, selectedLine, getText]);

    const createInnerTabView = useCallback(() => {
        if (selectedTab.id === 1)
            return (
                <StationInformationForm
                    selectedStation={selectedStation}
                    selectedLine={selectedLine}
                    setSelectedStation={setStation}
                    setSelectedPredecessors={setSelectedPredecessors}
                />
            );
        return (
            <StationOperationView
                operations={operations}
                setOpenOperation={setOpenOperation}
                setSelectedOperation={setSelectedOperation}
            />
        );
    }, [selectedTab, selectedLine, selectedStation]);

    const close = () => setOpen(false);
    const deleteFunction = () =>
        deleteStation(station.id, getText).then(() => {
            updateProduction(production.id);
            close();
        });

    function isDataValid(editStation: Station): boolean {
        if (isEditMode && JSON.stringify(editStation) === JSON.stringify(selectedStation)) {
            close();
            return false;
        }
        if (!(namePattern.test(editStation.name) && textAreaPattern.test(editStation.description))) {
            toast.error(getText(TextIds.Form.NAME_ALLOWED_CHARACTER));
            return false;
        }
        return true;
    }

    function handleSubmit(e: any) {
        if (e === undefined) return;
        e.preventDefault();

        let newPredecessors = [];
        for (let station of selectedPredecessors) newPredecessors.push(station.id);

        const updatedStation = {
            ...station,
            line_id: selectedLine.id,
            predecessors: newPredecessors,
        };
        if (!isDataValid(updatedStation)) return;

        let promise = isEditMode
            ? putStation(updatedStation, station.id, getText)
            : postStation(updatedStation, getText);
        promise.then(() => {
            updateProduction(production.id);
            close();
        });
    }

    return (
        <Modal title={label} description={getText(TextIds.Station.FORM_SUBTITLE)} open={open} setOpen={setOpen}>
            <form className="flex flex-col space-y-4 divide-y divide-solid" onSubmit={(e) => handleSubmit(e)}>
                <Tabs
                    elements={tabSelections}
                    defaultElement={selectedTab}
                    setSelectedElement={setSelectedTab}
                    selectedTabColor={SCHEDULE_BLUE_LIGHT}
                    className="h-full rounded-xl bg-white"
                >
                    {createInnerTabView()}
                </Tabs>
                <FormFooter
                    handleSubmitFunction={handleSubmit}
                    close={close}
                    isEditMode={isEditMode}
                    deleteFunction={deleteFunction}
                    objectToDelete={station}
                />
            </form>
            <OperationForm
                selectedOperation={selectedOperation}
                selectedStation={selectedStation}
                setOpen={setOpenOperation}
                open={openOperation}
            />
        </Modal>
    );
}
