import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useProduction, useProductionUpdate } from "../../context/ProductionContext";
import Modal from "../Modal";
import Input from "../form-inputs/Input";
import FormFooter from "../FormFooter";
import { deleteCustomer, postCustomer, putCustomer } from "../../api/calls/Customer";
import { createEmptyCustomer, Customer } from "../../api/models/Customer";
import { namePattern } from "../../utils/FormUtils";
import { TestIds } from "../../TestIds";
import { useText } from "../../context/LanguageContext";
import TextIds from "../../language/TextIds";
import { NAME_LENGTH } from "../../constants";

export interface CustomerProps {
    selectedCustomer: Customer;
    setOpen: Function;
    open: boolean;
}

export default function CustomerForm({ selectedCustomer, setOpen, open }: CustomerProps) {
    const getText = useText();
    const production = useProduction();
    const updateProduction = useProductionUpdate();

    const [customer, setCustomer] = useState<Customer>(createEmptyCustomer());
    const [modalTitle, setModalTitle] = useState("");

    const close = () => setOpen(false);
    const deleteFunction = () =>
        deleteCustomer(customer.id, getText).then(() => {
            updateProduction(production.id);
            close();
        });

    useEffect(() => {
        if (selectedCustomer.id > 0) {
            setModalTitle(getText(TextIds.Customer.EDIT));
        } else {
            setModalTitle(getText(TextIds.Customer.ADD));
        }
        setCustomer({
            ...selectedCustomer,
            production_id: production.id,
        });
        // eslint-disable-next-line
    }, [selectedCustomer, production.id]);

    function isDataValid(): boolean {
        if (!namePattern.test(customer.name)) {
            toast.error(getText(TextIds.Form.NAME_ALLOWED_CHARACTER));
            return false;
        }
        return true;
    }

    function handleSubmit(event: any): void {
        if (!isEventPresent(event)) return;
        event.preventDefault();
        if (!isDataValid()) return;

        const promise = () =>
            selectedCustomer.id > 0 ? putCustomer(customer, customer.id, getText) : postCustomer(customer, getText);
        promise()
            .then(() => {
                updateProduction(production.id);
                close();
            })
            .catch((error) => console.error(error));
    }

    function isEventPresent(event: any): boolean {
        return event !== undefined;
    }

    return (
        <Modal open={open} setOpen={setOpen} title={modalTitle} description={getText(TextIds.Customer.FORM_SUBTITLE)}>
            <form className="flex flex-col space-y-4" onSubmit={(event) => handleSubmit(event)}>
                <Input
                    labelText={getText(TextIds.Form.NAME)}
                    name="name"
                    value={customer.name}
                    type="text"
                    placeholder={getText(TextIds.Form.NAME)}
                    pattern={namePattern.source}
                    title={getText(TextIds.Form.NAME_ALLOWED_CHARACTER)}
                    maxLength={NAME_LENGTH}
                    onChange={(event: any) => setCustomer({ ...customer, name: event.target.value })}
                    required
                    data-testid={TestIds.INPUT_NAME}
                />
                <FormFooter
                    handleSubmitFunction={handleSubmit}
                    close={close}
                    deleteFunction={deleteFunction}
                    objectToDelete={selectedCustomer}
                    isEditMode={selectedCustomer.id > 0}
                />
            </form>
        </Modal>
    );
}
