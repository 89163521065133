import React, { useCallback, useEffect, useMemo, useState } from "react";
import logoImg from "../../assets/images/amexis.png";
import { useLocation, useNavigate } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import {
    ArrowRightOnRectangleIcon,
    Bars3Icon,
    BuildingOffice2Icon,
    CalendarIcon,
    EnvelopeIcon,
    ListBulletIcon,
    RectangleGroupIcon,
    SquaresPlusIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import Button, { ButtonSize, ButtonTypes } from "../../components/buttons/Button";
import FeedbackForm from "../../components/forms/FeedbackForm";
import NavigationLink from "./NavigationLink";
import { TestIds } from "../../TestIds";
import { NavigationItem, SelectBoxOption } from "../../api/models/GeneralTypes";
import Select from "../../components/form-inputs/Select";
import { Dictionary, useLanguage, useText } from "../../context/LanguageContext";
import TextIds from "../../language/TextIds";
import { useProductionsUpdate } from "../../context/ProductionsContext";

export interface NavigationProps {
    logout: Function;
}

export default function NavigationBar({ logout }: NavigationProps): JSX.Element {
    const getText = useText();
    const navigate = useNavigate();
    const location = useLocation();
    const { language, setLanguage } = useLanguage();
    const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState<boolean>(false);
    const updateProductions = useProductionsUpdate();

    const navigation: NavigationItem[] = [
        {
            id: 1,
            name: "",
            href: "/productions",
            icon: <BuildingOffice2Icon className="h-6 w-6" />,
        },
        {
            id: 2,
            name: getText(TextIds.Navigation.PROCESS),
            href: "/process",
            icon: <SquaresPlusIcon className="h-6 w-6" />,
        },
        {
            id: 3,
            name: getText(TextIds.Navigation.MATERIAL),
            href: "/material",
            icon: <RectangleGroupIcon className="h-6 w-6" />,
        },
        {
            id: 4,
            name: getText(TextIds.Navigation.ORDERS),
            href: "/orders",
            icon: <ListBulletIcon className="h-6 w-6" />,
        },
        {
            id: 5,
            name: getText(TextIds.Navigation.SCHEDULE),
            href: "/schedule",
            icon: <CalendarIcon className="h-6 w-6" />,
        },
    ];

    const languageOptions = [
        { id: 1, name: "DE", value: Dictionary.de },
        { id: 2, name: "EN", value: Dictionary.en },
    ];

    function getInitialSelectedLanguage(): SelectBoxOption {
        return languageOptions.filter((option) => option.value === language)[0];
    }

    let [selectedLanguage, setSelectedLanguage] = useState<SelectBoxOption>(getInitialSelectedLanguage());

    const openModal = useCallback(() => setIsFeedbackModalOpen(true), []);
    const handleClick = useCallback((item: NavigationItem) => navigate(item.href), []);

    const activeItem: NavigationItem = useMemo(() => {
        let current_navigation = navigation.find((value) => value.href === location.pathname);
        return current_navigation ? current_navigation : navigation[2];
    }, [location.pathname]);

    useEffect(() => {
        setLanguage(selectedLanguage.value);
    }, [selectedLanguage]);

    useEffect(() => {
        if (activeItem.href === "/productions") updateProductions();
    }, [activeItem]);

    const menuClassNames = "h-10 flex whitespace-nowrap focus:outline-none";
    const burgerMenuClassNames = "rounded-md w-fit focus:outline-none";

    const logo = useMemo(() => {
        return <img className="h-16" src={logoImg} alt="Amexis Logo" />;
    }, [openModal]);

    const logoutButton = useMemo(() => {
        return [
            <Button
                key="feedback"
                title="Feedback"
                buttonSize={ButtonSize.small}
                buttonType={ButtonTypes.Cancel}
                icon={<EnvelopeIcon className="text-white" />}
                onClick={openModal}
            />,
            <div key="divider" className="mx-2 h-8 border-2 border-white" />,
            <button
                key="logout"
                onClick={() => logout(navigate)}
                data-testid={TestIds.LOGOUT_BUTTON}
                className="flex items-center justify-center space-x-2 rounded-full p-2 hover:bg-green-dark focus:rounded-full focus:outline-none focus:ring focus:ring-gray-middle"
            >
                <ArrowRightOnRectangleIcon className="h-6 w-6" aria-hidden="true" />
                <div className="hidden lg:block">{getText(TextIds.Login.LOGOUT)}</div>
            </button>,
        ];
    }, [openModal, getText]);

    const languageSelect = useMemo(() => {
        return (
            <Select
                className="m-2 w-20 bg-gray-dark text-black"
                options={languageOptions}
                defaultValue={selectedLanguage}
                noSelectionOption={false}
                onChange={setSelectedLanguage}
            />
        );
    }, [languageOptions, selectedLanguage, setSelectedLanguage]);

    const linkItems = useCallback(
        (classes: string) => {
            return navigation.map((item) => (
                <NavigationLink
                    key={item.name}
                    handleClick={handleClick}
                    item={item}
                    linkClassNames={classes}
                    activeItem={activeItem}
                />
            ));
        },
        [handleClick, activeItem, getText]
    );

    return (
        <>
            <FeedbackForm open={isFeedbackModalOpen} setOpen={setIsFeedbackModalOpen}></FeedbackForm>
            <Disclosure as="nav" className="sticky top-0 z-50 bg-gray-dark text-white shadow md:h-16">
                {({ open }) => (
                    <div className="mx-auto flex md:w-5/6">
                        <div
                            className="hidden w-full items-center justify-between md:flex"
                            data-testid={TestIds.NAVIGATION}
                        >
                            <div className="mx-0 flex flex-shrink-0 items-center space-x-4">
                                {logo}
                                {linkItems(menuClassNames)}
                            </div>
                            <div className="-mr-24 flex items-center pl-2">
                                {logoutButton}
                                {languageSelect}
                            </div>
                        </div>
                        <div className="w-full md:hidden">
                            <div className="flex items-center justify-between pl-2">
                                <Disclosure.Button className="flex h-10 justify-center rounded-full p-2  hover:bg-green-dark">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                                {logo}
                                <div className="flex items-center">{logoutButton}</div>
                            </div>
                            <Disclosure.Panel className="flex flex-col space-y-2 pl-2">
                                {linkItems(burgerMenuClassNames)}
                                <div className="flex justify-end">{languageSelect}</div>
                            </Disclosure.Panel>
                        </div>
                    </div>
                )}
            </Disclosure>
        </>
    );
}
