import React from "react";
import Label from "../Label";

interface InputProps {
    labelText?: string;
    required?: boolean;
    className?: string;

    [optionalProperties: string]: any;
}

export default function Input({ labelText, required, className, ...optionalProperties }: InputProps) {
    const classes: string = [
        "w-full",
        "rounded-md",
        "text-gray-dark",
        "border-gray-light",
        "shadow-sm",
        "focus:border-green-middle",
        "focus:ring-green-middle",
        "disabled:opacity-50",
        "h-10",
        className,
    ].join(" ");

    if (labelText) {
        return (
            <div className="space-y-1">
                <Label>
                    {labelText}
                    {required && <span className="text-red-600">*</span>}
                </Label>
                <input {...optionalProperties} className={classes}></input>
            </div>
        );
    }
    return (
        <div className="flex items-end">
            <input {...optionalProperties} className={classes} />
        </div>
    );
}
