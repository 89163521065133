import axios from "axios";
import { BASE_URL, SCHEDULE_ITEMS_ENDPOINT } from "../ApiConfig";
import { getToken } from "../../hook/UseToken";
import { handleApiGetCall } from "../../utils/ApiUtils";

const scheduleItems = "ScheduleItems";
const endpoint = BASE_URL + SCHEDULE_ITEMS_ENDPOINT;

export async function getScheduleItems(schedule_id: number, getText: Function): Promise<any> {
    const response = axios.get(endpoint + "/" + schedule_id, getToken());
    return handleApiGetCall(response, scheduleItems, schedule_id, getText);
}
