import { Task } from "./Task";
import { OrderStatus } from "./GeneralTypes";
import { createEmptyProduct, Product } from "./Product";
import { CustomerOrderDetails } from "./CustomerOrderDetails";

export interface ProductionOrder {
    id: number;
    status: OrderStatus;
    tasks: Task[];
    quantity: number;
    product: Product;
    customer_oder_details: CustomerOrderDetails[];
    schedule_id: number;
    end_date: string;
    start_date: string;
}

export function createEmptyProductionOrder(): ProductionOrder {
    return {
        id: 0,
        status: OrderStatus.READY,
        tasks: [],
        quantity: 1,
        product: createEmptyProduct(),
        customer_oder_details: [],
        schedule_id: 0,
        end_date: "",
        start_date: "",
    };
}

export function filterProductionOrderList(filterInput: string, productionOrders: ProductionOrder[], getText: Function) {
    return productionOrders.filter(
        (order) =>
            order.id.toString().includes(filterInput) ||
            order.quantity.toString().includes(filterInput) ||
            getText(order.status).toLowerCase().includes(filterInput) ||
            order.start_date.toLowerCase().includes(filterInput) ||
            order.end_date?.toLowerCase().includes(filterInput) ||
            order.product.name.toLowerCase().includes(filterInput)
    );
}
