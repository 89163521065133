import React, { useCallback, useEffect, useRef, useState } from "react";
import { TimelineItem } from "./TimelineItem";
import { TimelineElement } from "../../../../api/models/GeneralTypes";
import { TestIds } from "../../../../TestIds";
import { GRAY_LIGHT } from "../../../../colors";
import { useText } from "../../../../context/LanguageContext";
import TextIds from "../../../../language/TextIds";

export interface TimelineContentProps {
    items: TimelineElement[];
    timelineHeight: number;
}

export function TimelineContent({ items, timelineHeight }: TimelineContentProps): JSX.Element {
    const getText = useText();
    const [selectedElementId, setSelectedElementId] = useState<number>(0);
    const selectedElementRef = useRef({});
    selectedElementRef.current = selectedElementId;

    const handleClick = useCallback(
        (element: TimelineElement) => {
            if (element.groupId === selectedElementRef.current) {
                setSelectedElementId(0);
                return;
            }
            setSelectedElementId(element.groupId);
        },
        [setSelectedElementId]
    );

    const onOuterItemClick = useCallback(() => {
        if (selectedElementId !== 0) setSelectedElementId(0);
    }, [selectedElementId]);

    function getColor(element: TimelineElement) {
        if (element.groupId === selectedElementId || selectedElementId === 0) return element.color;
        return GRAY_LIGHT;
    }

    useEffect(() => {
        setSelectedElementId(0);
    }, [items]);

    if (items.length === 0)
        return (
            <div className="flex justify-center" data-testid={TestIds.TIMELINE_CONTENT}>
                <div className="z-20 mt-64 flex h-10 w-80 cursor-pointer items-center justify-center rounded-lg bg-schedule-blue-dark font-medium font-semibold text-white">
                    {getText(TextIds.ProductionOrder.NONE)}
                </div>
            </div>
        );

    return (
        <div
            className="relative"
            style={{ height: timelineHeight }}
            data-testid={TestIds.TIMELINE_CONTENT}
            onClick={onOuterItemClick}
        >
            {items.map((element, key) => {
                return <TimelineItem item={element} color={getColor(element)} key={key} onClick={handleClick} />;
            })}
        </div>
    );
}
