import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useProduction, useProductionUpdate } from "../../context/ProductionContext";
import Modal from "../Modal";
import Input from "../form-inputs/Input";
import FormFooter from "../FormFooter";
import { deleteExternalMaterial, postExternalMaterial, putExternalMaterial } from "../../api/calls/ExternalMaterial";
import Select from "../form-inputs/Select";
import { createEmptyExtMaterialGroup } from "../../api/models/ExternalMaterialGroup";
import { namePattern } from "../../utils/FormUtils";
import { createEmptyExtMaterial, ExternalMaterial } from "../../api/models/ExternalMaterial";
import { TestIds } from "../../TestIds";
import { useText } from "../../context/LanguageContext";
import TextIds from "../../language/TextIds";
import { NAME_LENGTH } from "../../constants";

export interface ExtMaterialProps {
    selectedExtMaterial: ExternalMaterial;
    setOpen: Function;
    open: boolean;
}

export default function ExtMaterialForm({ selectedExtMaterial, setOpen, open }: ExtMaterialProps) {
    const getText = useText();
    const production = useProduction();
    const updateProduction = useProductionUpdate();

    const [extMaterial, setExtMaterial] = useState<ExternalMaterial>(createEmptyExtMaterial());
    const [modalTitle, setModalTitle] = useState("");

    const close = () => setOpen(false);
    const deleteFunction = () =>
        deleteExternalMaterial(extMaterial.id, getText).then(() => {
            updateProduction(production.id);
            close();
        });

    function isDataValid() {
        if (!namePattern.test(extMaterial.name)) {
            toast.error(getText(TextIds.Form.NAME_ALLOWED_CHARACTER));
            return;
        }
        if (extMaterial.external_material_group_id === 0) {
            toast.error(getText(TextIds.ExternalMaterialGroup.NO_SELECTION));
            return;
        }
        return true;
    }

    function handleSubmit(event: any) {
        if (event !== undefined) {
            event.preventDefault();
            if (!isDataValid()) return;

            const promise = () =>
                selectedExtMaterial.id > 0
                    ? putExternalMaterial(extMaterial, extMaterial.id, getText)
                    : postExternalMaterial(extMaterial, getText);
            promise().then(() => {
                updateProduction(production.id);
                close();
            });
        }
    }

    useEffect(() => {
        if (selectedExtMaterial.id > 0) {
            setModalTitle(getText(TextIds.ExternalMaterial.EDIT));
            setExtMaterial({
                ...selectedExtMaterial,
            });
        } else {
            setModalTitle(getText(TextIds.ExternalMaterial.ADD));
            setExtMaterial({
                ...selectedExtMaterial,
                name: "",
            });
        }
    }, [selectedExtMaterial, getText]);

    function getDefaultSelect() {
        return extMaterial.external_material_group_id === 0
            ? createEmptyExtMaterialGroup()
            : production.external_material_groups.filter(
                  (value) => extMaterial.external_material_group_id === value.id
              )[0];
    }

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title={modalTitle}
            description={getText(TextIds.ExternalMaterial.FORM_SUBTITLE)}
        >
            <form className="flex flex-col space-y-4 divide-y divide-solid" onSubmit={(event) => handleSubmit(event)}>
                <div className="space-y-4">
                    <Input
                        labelText={getText(TextIds.Form.NAME)}
                        name="name"
                        value={extMaterial.name}
                        type="text"
                        placeholder={getText(TextIds.Form.NAME)}
                        pattern={namePattern.source}
                        title={getText(TextIds.Form.NAME_ALLOWED_CHARACTER)}
                        maxLength={NAME_LENGTH}
                        onChange={(event: any) => setExtMaterial({ ...extMaterial, name: event.target.value })}
                        required
                        data-testid={TestIds.INPUT_NAME}
                    />
                    <Select
                        label={getText(TextIds.ExternalMaterialGroup.NAME)}
                        required={true}
                        options={production.external_material_groups}
                        defaultValue={getDefaultSelect()}
                        onChange={(selectedExtMaterial: ExternalMaterial) =>
                            setExtMaterial({
                                ...extMaterial,
                                external_material_group_id: selectedExtMaterial.id,
                            })
                        }
                        id={TestIds.EXT_MATERIAL_GROUP_SELECT}
                    />
                </div>
                <FormFooter
                    handleSubmitFunction={handleSubmit}
                    close={close}
                    deleteFunction={deleteFunction}
                    objectToDelete={selectedExtMaterial}
                    isEditMode={selectedExtMaterial.id > 0}
                />
            </form>
        </Modal>
    );
}
