import { Operation } from "./Operation";
import { createEmptyProductGroup, ProductGroup } from "./ProductGroup";
import { Production } from "./Production";

export interface Station {
    id: number;
    name: string;
    description: string;
    line_id: number;
    product_group: ProductGroup;
    operations: Operation[];
    predecessors: number[];
    successors: number[];
}

export function createEmptyStation(): Station {
    return {
        id: 0,
        name: "",
        description: "",
        line_id: 0,
        product_group: createEmptyProductGroup(),
        operations: [],
        predecessors: [],
        successors: [],
    };
}

export function getAllStations(production: Production): Station[] {
    let stations: Station[] = [];
    production.lines.forEach((line) => stations.push(...line.stations));
    return stations;
}
