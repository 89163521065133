import React, { useCallback, useState } from "react";
import ExtMaterialForm from "../forms/ExtMaterialForm";
import ExternalMaterialIcon from "../icons/ExternalMaterialIcon.svg";
import InnerNode from "./InnerNode";
import { ExternalMaterial } from "../../api/models/ExternalMaterial";
import { TestIds } from "../../TestIds";

export interface ExtMaterialNodeProps {
    extMaterial: ExternalMaterial;
    quantity?: number;
    addButton?: boolean;
}

export default function ExtMaterialNode({
    extMaterial,
    quantity = 0,
    addButton = false,
}: ExtMaterialNodeProps): JSX.Element {
    const [isExtMaterialModalOpen, setIsExtMaterialModalOpen] = useState<boolean>(false);
    const openForm = useCallback(() => setIsExtMaterialModalOpen(true), []);
    const testId = extMaterial.id !== 0 ? TestIds.EXT_MATERIAL_NODE + extMaterial.id : TestIds.ADD_EXT_MATERIAL_BUTTON;

    return (
        <>
            <InnerNode
                className="bg-gray-dark"
                name={extMaterial.name}
                icon={ExternalMaterialIcon}
                openForm={openForm}
                addButton={addButton}
                quantity={quantity}
                id={testId}
            />
            <ExtMaterialForm
                selectedExtMaterial={extMaterial}
                setOpen={setIsExtMaterialModalOpen}
                open={isExtMaterialModalOpen}
            />
        </>
    );
}
