import axios from "axios";
import { BASE_URL, LINE_ENDPOINT } from "../ApiConfig";
import { getToken } from "../../hook/UseToken";
import { Line } from "../models/Line";
import { generateToastText, handleApiCall, handleApiGetCall } from "../../utils/ApiUtils";
import { Workday } from "../models/Workday";

export interface LineJson extends Line {
    workdays: Workday[];
}

const line = "Line";
const endpoint = BASE_URL + LINE_ENDPOINT;

export async function getLine(lineId: number, getText: Function): Promise<Line> {
    const response = axios.get(endpoint + "/" + lineId, getToken());
    return handleApiGetCall(response, line, lineId, getText);
}

export async function putLine(lineJson: Line, lineId: number, getText: Function): Promise<Line> {
    const messages = generateToastText(line, "PUT", getText);
    const response = axios.put(endpoint + "/" + lineId, lineJson, getToken());
    return handleApiCall(response, messages);
}

export async function postLine(lineJson: Line, getText: Function): Promise<Line> {
    const messages = generateToastText(line, "POST", getText);
    const response = axios.post(endpoint, lineJson, getToken());
    return handleApiCall(response, messages);
}

export async function deleteLine(lineId: number, getText: Function): Promise<Line> {
    const messages = generateToastText(line, "DELETE", getText);
    const response = axios.delete(endpoint + "/" + lineId, getToken());
    return handleApiCall(response, messages);
}
