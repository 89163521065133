import { Product } from "./Product";

export interface Operation {
    id: number;
    name: string;
    description: string;
    duration: number;
    products: Product[];
    station_id: number;
}

export function createEmptyOperation(): Operation {
    return {
        id: 0,
        name: "",
        duration: 0,
        description: "",
        station_id: 0,
        products: [],
    };
}
