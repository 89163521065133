import React, { useCallback, useMemo } from "react";
import { ViewMode } from "../../Schedule";
import { createVerticalLines, createVerticalLinesForDay, VerticalLines } from "../utils/GridUtils";
import { ROW_HEIGHT } from "../utils/TimelineUtils";
import { TimelineDates } from "../../../../api/models/GeneralTypes";
import { DowntimeItem } from "../../../../api/models/Downtime";

export interface GridProps {
    rowCount: number;
    timelineDates: TimelineDates;
    bodyWidth: number;
    timelineFullHeight: number;
    downtimeItems: DowntimeItem[];
}

export function Grid({
    rowCount,
    timelineDates,
    bodyWidth,
    timelineFullHeight,
    downtimeItems,
}: GridProps): JSX.Element {
    const createHorizontalLine = useCallback(
        (index: number): JSX.Element => {
            return (
                <hr
                    style={{ width: bodyWidth, top: ROW_HEIGHT * (index + 1) }}
                    className="absolute z-10 border-l border-dashed border-schedule-stroke"
                    key={"RowLine" + index}
                />
            );
        },
        [bodyWidth]
    );

    const horizontalLines: JSX.Element[] = useMemo(() => {
        let lines = [];
        for (let i = 0; i < rowCount; i++) {
            lines.push(createHorizontalLine(i));
        }
        return lines;
        // eslint-disable-next-line
    }, [rowCount, bodyWidth]);

    const verticalLines: VerticalLines = useMemo(() => {
        switch (timelineDates.viewMode) {
            case ViewMode.Week:
                return createVerticalLines(timelineDates, timelineFullHeight, downtimeItems, 3);
            case ViewMode.Day:
                return createVerticalLinesForDay(timelineDates, timelineFullHeight, downtimeItems);
            default:
                return createVerticalLines(timelineDates, timelineFullHeight, downtimeItems);
        }
    }, [downtimeItems, timelineDates]);

    return (
        <div className="relative">
            {horizontalLines}
            {verticalLines.lines}
            {verticalLines.today}
            {verticalLines.downtimeItems}
            {verticalLines.weekend}
        </div>
    );
}
