import axios from "axios";
import { BASE_URL, OPERATION_ENDPOINT } from "../ApiConfig";
import { getToken } from "../../hook/UseToken";
import { generateToastText, handleApiCall, handleApiGetCall } from "../../utils/ApiUtils";
import { Operation } from "../models/Operation";

const operation = "Operation";
const endpoint = BASE_URL + OPERATION_ENDPOINT;

export async function getOperation(operationId: number, getText: Function): Promise<Operation> {
    const response = axios.get(endpoint + "/" + operationId, getToken());
    return handleApiGetCall(response, operation, operationId, getText);
}

export async function putOperation(
    operationJson: Operation,
    operationId: number,
    getText: Function
): Promise<Operation> {
    const messages = generateToastText(operation, "PUT", getText);
    const response = axios.put(endpoint + "/" + operationId, operationJson, getToken());
    return handleApiCall(response, messages);
}

export async function postOperation(operationJson: Operation, getText: Function): Promise<Operation> {
    const messages = generateToastText(operation, "POST", getText);
    const response = axios.post(endpoint, operationJson, getToken());
    return handleApiCall(response, messages);
}

export async function deleteOperation(operationId: number, getText: Function): Promise<Operation> {
    const messages = generateToastText(operation, "DELETE", getText);
    const response = axios.delete(endpoint + "/" + operationId, getToken());
    return handleApiCall(response, messages);
}
