import { SelectBoxOption } from "../api/models/GeneralTypes";
import { useText } from "../context/LanguageContext";

interface SwitchButtonProps {
    options: SelectBoxOption[];
    selectedElement: SelectBoxOption;
    setSelectedElement: Function;
    id?: string;
}

export default function SwitchButton({ options, selectedElement, setSelectedElement, id }: SwitchButtonProps) {
    const getText = useText();
    return (
        <div
            data-testid={id}
            className="flex h-11 cursor-pointer rounded-lg border-2 border-white font-semibold text-white drop-shadow-2xl"
        >
            {options.map((option, index) => {
                let active = option.id === selectedElement.id;
                let classes = ["flex", "items-center", "border-white", "px-2", "min-w-[4rem]", "justify-center"];
                if (index > 0) classes.push("border-l-2");
                if (index === 0) classes.push("rounded-l");
                if (index === options.length - 1) classes.push("rounded-r");
                if (!active) {
                    classes.push(
                        ...[
                            "text-gray-very-light",
                            "bg-gray-light",
                            "hover:text-gray-light",
                            "hover:bg-gray-very-light",
                        ]
                    );
                }
                return (
                    <div
                        key={option.id}
                        className={classes.join(" ")}
                        onClick={() => setSelectedElement(option)}
                        data-testid={option.id}
                        style={{ backgroundColor: active ? option.switchColor : "" }}
                    >
                        {getText(option.name)}
                    </div>
                );
            })}
        </div>
    );
}
