import ScheduleHeader from "./ScheduleHeader";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Timeline } from "./timeline/Timeline";
import { TestIds } from "../../TestIds";
import { getTimelineDateRange, getTimelineDates } from "./timeline/utils/DateUtils";
import { buildTimeLineElements } from "./timeline/utils/TimelineUtils";
import { TimelineDates } from "../../api/models/GeneralTypes";
import { Station } from "../../api/models/Station";
import { SCHEDULE_COLORS } from "../../colors";
import TextIds from "../../language/TextIds";
import { useText } from "../../context/LanguageContext";
import { ScheduleItem, ScheduleOrder } from "../../api/models/Schedule";
import { getScheduleItems } from "../../api/calls/ScheduleItem";
import { TimelineLeftHeaderProps } from "./timeline/components/TimelineLeftHeader";
import { createEmptyLine, Line } from "../../api/models/Line";
import { getDowntimeItems } from "../../api/calls/Downtimes";
import { DowntimeItem } from "../../api/models/Downtime";
import { sortByValue } from "../../utils/SortUtils";

export enum ViewMode {
    Day = TextIds.Time.DAY as any,
    Week = TextIds.Time.WEEK as any,
    Month = TextIds.Time.MONTH as any,
}

export enum ScheduleMode {
    Station = TextIds.Station.NAME as any,
    Order = TextIds.ORDER as any,
}

export function Schedule() {
    const getText = useText();
    const [downtimeItems, setDowntimeItems] = useState<DowntimeItem[]>([]);
    const [showDowntimes, setShowDowntimes] = useState(false);

    const [selectedLine, setSelectedLine] = useState<Line>(createEmptyLine);

    const [viewMode, setViewMode] = useState(ViewMode.Month);
    const [scheduleMode, setScheduleMode] = useState(ScheduleMode.Station);
    const [viewDate, setViewDate] = useState<Date>(new Date());

    const [scheduleItems, setScheduleItems] = useState<ScheduleItem[]>([]);
    const [stations, setStations] = useState<Station[]>([]);
    const [timelineDates, setTimelineDates] = useState<TimelineDates>({} as TimelineDates);

    const updateScheduleItems = useCallback(
        (line: Line) => {
            if (line.schedule_id === 0) return;
            getScheduleItems(line.schedule_id, getText).then((value) => {
                setScheduleItems(value["schedule_items"]);
                setStations(value["stations"]);
            });
        },
        // eslint-disable-next-line
        [setScheduleItems]
    );

    const orders: ScheduleOrder[] = useMemo((): ScheduleOrder[] => {
        if (scheduleItems.length === 0) return [];

        return scheduleItems
            .map((item: ScheduleItem) => item.production_order)
            .filter((order: ScheduleOrder, i: number, orders: ScheduleOrder[]): boolean => {
                return orders.findIndex((o: ScheduleOrder) => o.id === order.id) === i;
            });
    }, [scheduleItems]);

    const legendRows = useMemo(() => {
        if (scheduleMode === ScheduleMode.Order) {
            return stations.map((station: Station, index: number) => {
                return {
                    groupId: station.id,
                    text: station.name,
                    color: SCHEDULE_COLORS[index % SCHEDULE_COLORS.length],
                };
            });
        }

        return orders.map((order: any, index: number) => {
            return {
                groupId: order.id,
                text: order.id.toString(),
                color: SCHEDULE_COLORS[index % SCHEDULE_COLORS.length],
            };
        });
    }, [orders, scheduleMode, stations]);

    const leftHeaderRows = useMemo(() => {
        if (scheduleMode === ScheduleMode.Station) {
            return stations.map((station: Station) => {
                return {
                    id: station.id,
                    name: station.name,
                };
            });
        }

        return orders.map((order: any) => {
            return {
                id: order.id,
                name: order.id.toString(),
            };
        });
    }, [orders, scheduleMode, stations]);

    const [startDate, endDate] = useMemo(() => {
        return getTimelineDateRange(scheduleItems, viewMode, downtimeItems);
    }, [scheduleItems, viewMode, downtimeItems]);

    const timelineElements = useMemo(() => {
        let newDates = getTimelineDates(startDate, endDate, viewMode);
        setTimelineDates({ dates: newDates, viewMode });

        if (orders.length === 0) return [];
        return buildTimeLineElements(
            {
                dates: newDates,
                scheduleItems: scheduleItems,
            },
            leftHeaderRows,
            stations,
            legendRows,
            scheduleMode
        );
        // eslint-disable-next-line
    }, [scheduleMode, startDate, endDate]);

    const getDowntimes = useCallback(() => {
        if (selectedLine.id === 0) return;
        let date = new Date();
        getDowntimeItems(selectedLine.id, date.toISOString().substring(0, 10), getText)
            .then((value) => {
                const items = value["downtime_items"];
                items.sort((a: DowntimeItem, b: DowntimeItem) => sortByValue(a, b, ["start"], true));
                setDowntimeItems(items);
            })
            .catch((error) => console.error(error));
    }, [selectedLine, getText, startDate]);

    useEffect(() => {
        updateScheduleItems(selectedLine);
    }, [selectedLine, updateScheduleItems]);

    useEffect(() => {
        getDowntimes();
    }, [selectedLine]);

    const leftHeaderProps: TimelineLeftHeaderProps = useMemo(() => {
        return {
            title: getText(scheduleMode.toString()),
            rows: leftHeaderRows,
        };
    }, [leftHeaderRows, scheduleMode, getText]);

    return (
        <div className="space-y-4" data-testid={TestIds.SCHEDULE}>
            <ScheduleHeader
                setViewDate={setViewDate}
                viewMode={viewMode}
                setViewMode={setViewMode}
                setSelectedScheduleMode={setScheduleMode}
                setLine={setSelectedLine}
                reschedule={updateScheduleItems}
                showDowntimes={showDowntimes}
                setShowDowntimes={setShowDowntimes}
            />
            <Timeline
                elements={timelineElements}
                viewDate={viewDate}
                viewMode={viewMode}
                setViewMode={setViewMode}
                timelineDates={timelineDates}
                leftHeaderProps={leftHeaderProps}
                legendRows={legendRows}
                downtimeItems={showDowntimes ? downtimeItems : []}
            />
        </div>
    );
}
