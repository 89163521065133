import { Edge, Node } from "reactflow";
import { createEdge, createFlowTree, createNode, createNodeId, createSelect } from "./FlowUtils";
import ProductGroupNode from "../../components/flow/ProductGroupNode";
import { getById, getByProp, isElementInListById } from "../FilterUtils";
import React from "react";
import ProductNode from "../../components/flow/ProductNode";
import ExtMaterialNode from "../../components/flow/ExtMaterialNode";
import ExtMaterialGroupNode from "../../components/flow/ExtMaterialGroupNode";
import { GeneralObjectType, NodesAndEdges, SelectBoxOption } from "../../api/models/GeneralTypes";
import { Production } from "../../api/models/Production";
import { createEmptyProductGroup, ProductGroup } from "../../api/models/ProductGroup";
import { createEmptyExtMaterialGroup, getExtMaterialsOfGroups } from "../../api/models/ExternalMaterialGroup";
import { createEmptyProduct, getProductQuantityBySuccessorGroup, Product } from "../../api/models/Product";
import { createEmptyExtMaterial, ExternalMaterial } from "../../api/models/ExternalMaterial";

export type ProductFlowOption = {
    production: Production;
    element: GeneralObjectType;
    selectableElements: SelectBoxOption[];
    defaultSelectedElement: SelectBoxOption;
    setSelectedElement: Function;
    createNodesAndEdges: Function;
    getAll: Function;
    isFullscreen?: boolean;
    setIsFullscreen?: Function;
};

export function createFlowForProductModeling({
    production,
    element,
    selectableElements,
    defaultSelectedElement,
    setSelectedElement,
    createNodesAndEdges,
    getAll,
    isFullscreen,
    setIsFullscreen,
}: ProductFlowOption): JSX.Element {
    let productNodesAndEdges = createNodesAndEdges(element, { nodes: [], edges: [] }, production);
    let nodesAndEdges = getAll(element, productNodesAndEdges, production).nodesAndEdges;
    return createFlowTree(
        nodesAndEdges,
        element,
        isFullscreen,
        setIsFullscreen,
        createSelect(setSelectedElement, defaultSelectedElement, selectableElements)
    );
}

export function createAllPreProductGroups(
    baseProductGroup: ProductGroup,
    nodesAndEdges: { nodesAndEdges: NodesAndEdges; successorId: string },
    production: Production
): { nodesAndEdges: NodesAndEdges; successorId: string } {
    let newNodesAndEdges = nodesAndEdges;
    for (let preProductGroupId of baseProductGroup.pre_product_groups) {
        const preProductGroup = getById(
            preProductGroupId.pre_product_group_id,
            production.product_groups,
            createEmptyProductGroup
        );

        newNodesAndEdges = createNodesAndEdgesForProductGroupDeepSearch(
            preProductGroup,
            newNodesAndEdges.nodesAndEdges,
            production,
            preProductGroupId.quantity,
            nodesAndEdges.successorId
        );
    }
    return newNodesAndEdges;
}

function createNodesAndEdgesForProductGroupDeepSearch(
    preProductGroup: ProductGroup,
    nodesAndEdges: NodesAndEdges,
    production: Production,
    quantity: number,
    successorId: string
) {
    const newNodesAndEdges = createNodesAndEdgesForProductGroup(
        preProductGroup,
        nodesAndEdges,
        production,
        true,
        quantity,
        successorId
    );
    if (preProductGroup.pre_product_groups.length > 0) {
        return createAllPreProductGroups(preProductGroup, newNodesAndEdges, production);
    }
    return newNodesAndEdges;
}

export function createNodesAndEdgesForProductGroup(
    productGroup: ProductGroup,
    nodesAndEdges: NodesAndEdges,
    production: Production,
    hasEdge: boolean = false,
    quantity: number = 1,
    successorProductGroupNodeId?: string
): { nodesAndEdges: NodesAndEdges; successorId: string } {
    let nodes: Node[] = [...nodesAndEdges.nodes];
    let edges: Edge[] = [...nodesAndEdges.edges];
    let productNodeId = createNodeId(productGroup.id, "productGroupNode", nodes);

    if (productGroup.id !== 0) {
        const productGroupNode = <ProductGroupNode productGroup={productGroup} quantity={quantity} />;
        nodes.push(createNode(productNodeId, productGroupNode));
    }

    for (let extMaterialGroup of productGroup.external_material_groups) {
        let extMaterialNodeId = createNodeId(
            extMaterialGroup.external_material_group_id,
            "extMaterialGroupNode",
            nodes
        );
        const extMaterialGroupNode = createExtMaterialGroupNode(extMaterialGroup, production);

        nodes.push(createNode(extMaterialNodeId, extMaterialGroupNode));
        edges.push(createEdge(productNodeId, extMaterialNodeId));
    }
    if (hasEdge && successorProductGroupNodeId) edges.push(createEdge(successorProductGroupNodeId, productNodeId));

    return { nodesAndEdges: { nodes: nodes, edges: edges }, successorId: productNodeId };
}

function createExtMaterialGroupNode(
    extMaterialGroupInfo: { external_material_group_id: number; quantity: number },
    production: Production
) {
    const extMaterialGroup = getById(
        extMaterialGroupInfo.external_material_group_id,
        production.external_material_groups,
        createEmptyExtMaterialGroup
    );
    return <ExtMaterialGroupNode extMaterialGroup={extMaterialGroup} quantity={extMaterialGroupInfo.quantity} />;
}

export function getAllPreProducts(
    baseProduct: Product,
    nodesAndEdges: { nodesAndEdges: NodesAndEdges; successorId: string; baseProductGroup: ProductGroup },
    production: Production
): { nodesAndEdges: NodesAndEdges; successorId: string; baseProductGroup: ProductGroup } {
    let newNodesAndEdges = nodesAndEdges;

    for (let preProductId of baseProduct.pre_products) {
        let preProduct = getPreProduct(production, preProductId);

        const quantity = getProductQuantityBySuccessorGroup(nodesAndEdges.baseProductGroup, preProduct);

        newNodesAndEdges = createNodesAndEdgesForProductDeepSearch(
            preProduct,
            newNodesAndEdges.nodesAndEdges,
            production,
            quantity,
            newNodesAndEdges.successorId
        );
    }
    return newNodesAndEdges;
}

function createNodesAndEdgesForProductDeepSearch(
    preProduct: Product,
    nodesAndEdges: NodesAndEdges,
    production: Production,
    quantity: number,
    successorId: string
) {
    const newNodesAndEdges = createNodesAndEdgesForProduct(
        preProduct,
        nodesAndEdges,
        production,
        true,
        quantity,
        successorId
    );

    if (preProduct.pre_products.length > 0) {
        return getAllPreProducts(preProduct, newNodesAndEdges, production);
    }
    return newNodesAndEdges;
}

function getPreProduct(production: Production, preProductId: number): Product {
    for (let productGroup of production.product_groups) {
        if (isElementInListById(productGroup.products, preProductId)) {
            return getById(preProductId, productGroup.products, createEmptyProduct);
        }
    }
    return createEmptyProduct();
}

export function createNodesAndEdgesForProduct(
    product: Product,
    nodesAndEdges: NodesAndEdges,
    production: Production,
    hasEdge: boolean = false,
    quantity: number = 1,
    successorProductNodeId?: string
): { nodesAndEdges: NodesAndEdges; successorId: string; baseProductGroup: ProductGroup } {
    let nodes: Node[] = [...nodesAndEdges.nodes];
    let edges: Edge[] = [...nodesAndEdges.edges];

    const productGroup = getById(product.product_group_id, production.product_groups, createEmptyProductGroup);
    const productNodeId = createNodeId(product.id, "productNode", nodes);
    const productNode = <ProductNode product={product} quantity={quantity} productType={productGroup.product_type} />;
    nodes.push(createNode(productNodeId, productNode));

    for (let extMaterialId of product.external_materials) {
        createExtMaterialNodesAndEdges(
            extMaterialId,
            {
                nodes: nodes,
                edges: edges,
            },
            productGroup,
            productNodeId,
            production
        );
    }
    if (hasEdge && successorProductNodeId) {
        edges.push(createEdge(successorProductNodeId, productNodeId));
    }

    return {
        nodesAndEdges: { nodes: nodes, edges: edges },
        successorId: productNodeId,
        baseProductGroup: productGroup,
    };
}

export function createExtMaterialNodesAndEdges(
    extMaterialId: number,
    nodesAndEdges: NodesAndEdges,
    productGroup: ProductGroup,
    productNodeId: string,
    production: Production
): NodesAndEdges {
    let nodes = nodesAndEdges.nodes;
    let edges = nodesAndEdges.edges;

    const extMaterialNodeId = createNodeId(extMaterialId, "extMaterialNode", nodes);

    const materialList: ExternalMaterial[] = getExtMaterialsOfGroups(production.external_material_groups);
    let extMaterial = getById(extMaterialId, materialList, createEmptyExtMaterial);

    const material = getByProp(
        extMaterial.external_material_group_id,
        "external_material_group_id",
        productGroup.external_material_groups,
        createEmptyExtMaterial
    );
    const materialQuantity: number = material.quantity;

    const extMaterialNode = createExtMaterialNode(extMaterial, materialQuantity);
    const nodeId = createNodeId(extMaterialId, "extMaterialNode", nodes);

    nodes.push(createNode(nodeId, extMaterialNode));
    edges.push(createEdge(productNodeId, extMaterialNodeId));

    return { nodes: nodes, edges: edges };
}

function createExtMaterialNode(extMaterial: ExternalMaterial, materialQuantity: number) {
    return <ExtMaterialNode extMaterial={extMaterial} quantity={materialQuantity} />;
}
