import { DeliveryStatus } from "./GeneralTypes";
import { createEmptyProduct, Product } from "./Product";

export interface CustomerOrderDetails {
    id: number;
    quantity: number;
    production_order_id: number;
    product: Product;
    customer_order_id: number;
    product_id: number;
    delivery_status: DeliveryStatus;
}

export function createEmptyCustomerOrderDetail(): CustomerOrderDetails {
    return {
        id: 0,
        quantity: 0,
        production_order_id: 0,
        product: createEmptyProduct(),
        customer_order_id: 0,
        product_id: 0,
        delivery_status: DeliveryStatus.IN_TIME,
    };
}
