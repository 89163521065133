export enum Weekday {
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY,
    SUNDAY,
}

export interface Workday {
    id: number;
    enabled: boolean;
    day: Weekday;
    start: string;
    end: string;
    schedule_id: number;
}

export function createEmptyWorkday(): Workday {
    return {
        id: 0,
        enabled: true,
        day: Weekday.MONDAY,
        start: "",
        end: "",
        schedule_id: 0,
    };
}
