import { DeliveryStatus, OrderStatus } from "../api/models/GeneralTypes";
import React from "react";
import { ProductionOrder } from "../api/models/ProductionOrder";

export function getDeliveryMarble(status: DeliveryStatus): JSX.Element {
    if (!status) return <></>;
    let color = "";
    switch (status) {
        case DeliveryStatus.DELAYED:
            color = "bg-status-red";
            break;
        case DeliveryStatus.IN_TIME:
            color = "bg-status-green";
            break;
        case DeliveryStatus.TIGHT:
            color = "bg-status-yellow";
            break;
    }

    return <div className={`bg-status h-7 w-7 rounded-full border border-gray-light shadow-2xl ${color}`} />;
}

export function getOrderStatusMarble(order: ProductionOrder): JSX.Element {
    if (!order.status) return <></>;
    let color = "";
    switch (order.status) {
        case OrderStatus.BLOCKED:
            color = "bg-status-red";
            break;
        case OrderStatus.DONE:
            color = "bg-status-green";
            break;
        case OrderStatus.READY:
            color = "bg-status-green";
            break;
        case OrderStatus.RUNNING:
            color = "bg-status-yellow";
            break;
        case OrderStatus.SCHEDULED:
            color = "bg-status-yellow";
            break;
    }
    return <div className={`h-7 w-7 rounded-full border border-gray-light shadow-2xl ${color}`} />;
}
