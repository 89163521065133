import { HEADER_HEIGHT } from "../utils/TimelineUtils";
import { TestIds } from "../../../../TestIds";

interface TimelineLegendProps {
    rows: LegendRow[];
    timelineHeight: number;
}

export interface LegendRow {
    text: string;
    color: string;
    groupId: number;
}

export function TimelineLegend({ rows, timelineHeight }: TimelineLegendProps): JSX.Element {
    rows.sort((group1, group2) => group1.groupId - group2.groupId);
    return (
        <div
            className="min-w-32 overflow-y-auto"
            style={{ marginTop: HEADER_HEIGHT, height: timelineHeight + 7 }}
            data-testid={TestIds.TIMELINE_LEGEND}
        >
            {rows.map((legendRow: LegendRow) => {
                return (
                    <div
                        className="mb-2 ml-4 flex max-w-3xl items-center justify-start gap-2 break-words"
                        key={legendRow.groupId}
                        lang="de"
                        style={{
                            hyphens: "auto",
                        }}
                    >
                        <div
                            className="h-5 w-5 shrink-0 rounded-full shadow-2xl"
                            style={{
                                backgroundColor: legendRow.color,
                            }}
                        />
                        {legendRow.text}
                    </div>
                );
            })}
        </div>
    );
}
