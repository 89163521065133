import { GeneralObjectType } from "../../api/models/GeneralTypes";
import React, { useCallback } from "react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { TestIds } from "../../TestIds";

interface EditButtonProps {
    handleClick: Function;
    openForm: Function;
    item: GeneralObjectType;
}

export default function EditButton({ handleClick, openForm, item }: EditButtonProps) {
    const onClick = useCallback(() => {
        handleClick(item);
        openForm(true);
    }, [item, handleClick, openForm]);

    return (
        <PencilSquareIcon
            className="h-6 cursor-pointer text-gray-middle"
            data-testid={TestIds.EDIT_BUTTON}
            onClick={onClick}
        />
    );
}
