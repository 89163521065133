import { GeneralObjectType } from "../api/models/GeneralTypes";

export function sortByIdAsc(componentA: GeneralObjectType, componentB: GeneralObjectType): number {
    return componentA.id - componentB.id;
}

export function sortByValue(
    componentA: GeneralObjectType,
    componentB: GeneralObjectType,
    values: string[],
    asc = true
): number {
    let valueA = componentA;
    let valueB = componentB;

    values.forEach((value) => {
        if (!(valueA === undefined || valueB === undefined)) {
            valueA = valueA[value];
            valueB = valueB[value];
        }
        return 0;
    });
    if (isNumeric(valueA) && isNumeric(valueB)) return asc ? +valueA - +valueB : +valueB - +valueA;
    return asc ? String(valueA).localeCompare(String(valueB)) : String(valueB).localeCompare(String(valueA));
}

function isNumeric(val: any): boolean {
    return !isNaN(Number(val));
}
